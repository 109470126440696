import { useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { Select } from 'FrontRoyalMaterialUiForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { titleize } from 'String';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import './ExpelOrWithdraw.scss';
import { useGetUserInAdmin } from 'UserAdministrationTab/redux';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { IssueRefundSelect } from 'UserAdministrationTab/HelperComponents/IssueRefundSelect';
import { type RefundEntitlement } from 'RefundEntitlement';

interface WillNotGraduateReason {
    title: string;
    id: string;
    category: string;
}

interface ExpelOrWithdrawAttrs extends UserManagementActionAttrs {
    canBackOutOfRegistration: boolean;
    hasEnrolled: boolean;
    availableWillNotGraduateReasons: WillNotGraduateReason[] | null;
    refundEntitlement: RefundEntitlement | null;
    hasEnoughTransactionsToRefund: boolean;
}

type FormFields = {
    willNotGraduateCategoryId?: string;
    willNotGraduateReasonId?: string;
    issueRefund?: boolean;
};

type ExecuteActionParams = FormFields & {
    issueRefund: boolean;
    backOutOfRegistration: boolean;
};

class ExpelOrWithdraw extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ExpelOrWithdraw;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    canBackOutOfRegistration: boolean;
    hasEnrolled: boolean;
    willNotGraduateReasons: WillNotGraduateReason[] | null;
    refundEntitlement: RefundEntitlement | null;
    hasEnoughTransactionsToRefund: boolean;

    constructor(attrs: ExpelOrWithdrawAttrs) {
        super(attrs);
        this.canBackOutOfRegistration = attrs.canBackOutOfRegistration;
        this.hasEnrolled = attrs.hasEnrolled;
        this.willNotGraduateReasons = attrs.availableWillNotGraduateReasons;
        this.refundEntitlement = attrs.refundEntitlement;
        // See comment in server-side `prepare_withdrawal_refund`.  As long as all the user's payments
        // were made through stripe, hasEnoughTransactionsToRefund will be true.  We cannot refund non-stripe payments programmatically, so
        // if issuing a refund means that we would need to refund non-stripe payments, then hasEnoughTransactionsToRefund
        // will be false to indicate that we cannot do it here
        this.hasEnoughTransactionsToRefund = attrs.hasEnoughTransactionsToRefund;
    }

    get formValidationSchema() {
        const refundSelectEnabled = this.hasEnoughTransactionsToRefund && this.refundEntitlement;
        const willNotGraduateRequired = !this.canBackOutOfRegistration;

        return Yup.object().shape({
            issueRefund: refundSelectEnabled ? Yup.boolean().required() : Yup.boolean(),
            willNotGraduateReasonId: willNotGraduateRequired ? Yup.string().required() : Yup.string(),
        });
    }

    formatFormValues({ issueRefund, willNotGraduateReasonId }: FormFields): ExecuteActionParams {
        return {
            issueRefund: !!issueRefund,
            willNotGraduateReasonId,
            // Note: this might seem strange, but we are simply taking the value from the action and passing it back to the server.
            // this will require the action on the server to be explicit about expecting a user to be backed out of registration or not
            backOutOfRegistration: this.canBackOutOfRegistration,
        };
    }

    get description() {
        return (
            <>
                {this.canBackOutOfRegistration ? (
                    <p>
                        Since the student&apos;s offer cohort has not yet started, registration will be canceled. The
                        student will be marked as having declined the admission offer.
                    </p>
                ) : !this.hasEnrolled ? (
                    <p>
                        The student will be removed from the cohort and will lose access to content. Since the
                        enrollment deadline has not yet passed, the student will not be marked as enrolled. They will
                        not count against the graduation rate for the cohort.
                    </p>
                ) : (
                    <p>The student will be removed from the cohort and will lose access to content.</p>
                )}
            </>
        );
    }

    static FormFields = ({ action, userId }: FormFieldProps<never, ExpelOrWithdraw>): JSX.Element => {
        const { willNotGraduateReasons, refundEntitlement, hasEnoughTransactionsToRefund, canBackOutOfRegistration } =
            action;
        const { user, userLoading } = useGetUserInAdmin(userId);
        const { formState, watch, setFieldValue } = useFormContext<FormFields>();
        const willNotGraduateCategoryId = watch('willNotGraduateCategoryId');
        const willNotGraduateCategories = useMemo(
            () => [...new Set(willNotGraduateReasons?.map(({ category }) => category))],
            [willNotGraduateReasons],
        );

        const willNotGraduateOptions = useMemo(() => {
            if (willNotGraduateReasons) {
                const reasons = willNotGraduateReasons
                    .filter(({ category }) => category === willNotGraduateCategoryId)
                    .sort((a, b) => {
                        if (a.title.startsWith('Other')) {
                            return 1;
                        }

                        if (b.title.startsWith('Other')) {
                            return -1;
                        }

                        return a.title.localeCompare(b.title);
                    });

                if (reasons.length === 1) setFieldValue('willNotGraduateReasonId', reasons[0].id);

                return reasons;
            }
            return [];
        }, [setFieldValue, willNotGraduateCategoryId, willNotGraduateReasons]);

        const hasWillNotGraduateReasons = !canBackOutOfRegistration && (willNotGraduateReasons?.length || 0) > 0;

        return userLoading ? (
            <FrontRoyalSpinner />
        ) : (
            <div className="expel-or-withdraw-form">
                {hasWillNotGraduateReasons && (
                    <>
                        <Select
                            name="willNotGraduateCategoryId"
                            label="Will Not Graduate Category"
                            fullWidth
                            disabled={formState.isSubmitting}
                            options={willNotGraduateCategories}
                            optionLabel={option => titleize(option)}
                            optionValue={option => option}
                        />
                        <Select
                            name="willNotGraduateReasonId"
                            label="Will Not Graduate Reason"
                            fullWidth
                            disabled={formState.isSubmitting || willNotGraduateOptions.length <= 1}
                            options={willNotGraduateOptions}
                            optionLabel={option => option.title}
                            optionValue={option => option.id}
                        />
                    </>
                )}
                <IssueRefundSelect
                    user={user}
                    hasEnoughTransactions={hasEnoughTransactionsToRefund}
                    refundEntitlement={refundEntitlement}
                    needInput
                />
            </div>
        );
    };
}

export default ExpelOrWithdraw;
