import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import EditSectionOffers from 'UserAdministrationTab/HelperComponents/EditSectionOffers';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import { type SectionOfferFormValue } from 'UserAdministrationTab/HelperComponents/EditSectionOffers.types';
import AbstractUserManagementAction from './AbstractUserManagementAction';
import { type FormFieldProps, type UserManagementActionAttrs } from './UserManagementAction.types';

type FormFields = {
    cohortSectionOffers: SectionOfferFormValue[];
    programType: string | null;
    cohortId: string;
};

type ExecuteActionParams = Omit<FormFields, 'programType'>;

interface OfferAdmissionAfterDeclinedOfferAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
}

class OfferAdmissionAfterDeclinedOfferAction extends AbstractUserManagementAction {
    static description = (
        <p>
            Use to offer admission to a cohort after a declined offer was made and did not get a response. This will
            expire the current offer and create a program application for the new cohort before making a new offer.
        </p>
    );

    static formValidationSchema = Yup.object().shape({
        cohortSectionOffers: Yup.array().of(
            Yup.object().shape({
                cohortSectionId: Yup.string().required(),
                offeredScholarshipIds: Yup.array().of(Yup.string().required()).required(),
            }),
        ),
        cohortId: Yup.string().required(),
    });

    availableTargetCohortIds: string[];

    constructor(attrs: OfferAdmissionAfterDeclinedOfferAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    formatFormValues(values: FormFields): ExecuteActionParams {
        return {
            cohortSectionOffers: values.cohortSectionOffers?.map(offer => ({
                cohortSectionId: offer.cohortSectionId,
                offeredScholarshipIds: offer.offeredScholarshipIds,
            })),
            cohortId: values.cohortId,
        };
    }

    static FormFields = ({ action }: FormFieldProps<never, OfferAdmissionAfterDeclinedOfferAction>) => {
        const { watch } = useFormContext<FormFields>();
        const cohortId = watch('cohortId');

        return (
            <>
                <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
                {cohortId && <EditSectionOffers cohortId={cohortId} />}
            </>
        );
    };
}

export default OfferAdmissionAfterDeclinedOfferAction;
