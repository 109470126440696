import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    OfferActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

export default class ReactivateOffer extends AbstractUserManagementAction {
    static actionType = OfferActionType.ReactivateDeclinedOffer;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (
        <p>
            Reactivate an expired or declined offer. To change the section, scholarship, or cohort, use their respective
            actions after reactivating the offer.
        </p>
    );
}
