import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    OfferActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

export default class ExpireOffer extends AbstractUserManagementAction {
    static actionType = OfferActionType.ExpireOffer;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (
        <p>
            Expire and void this admission offer. To void a large number of offers, make the changes in Airtable and
            trigger a sync.
        </p>
    );
}
