import { useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Select } from 'FrontRoyalMaterialUiForm';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { CohortSelectOption } from 'CohortFormHelper';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import { type ChipProps } from '@mui/material';

type ChangeCohortSelectProps = {
    availableTargetCohortIds: string[];
    maxDeferrableGraduationDate?: number;
    includedCohortSectionId?: string;
};

const StatusTag = {
    PastMaxDeferrableGraduationDate: 'Past Graduation Deadline',
    InProgress: 'In Progress',
    AwaitingGraduation: 'Awaiting Graduation',
    GlobalSection: 'Global Section',
} as const;

type Tag = {
    label: typeof StatusTag[keyof typeof StatusTag];
    color: ChipProps['color'];
};

const buildTags = (
    { startDate, endDate, graduationDate, cohortSections }: CohortForAdminListsAttrs,
    maxDeferrableGraduationDate?: number,
    includedCohortSectionId?: string,
) => {
    const tags: Tag[] = [];

    const now = new Date().getTime() / 1000;
    const inProgress = startDate < now && endDate > now;
    const pastMaxDeferrableGraduationDate = maxDeferrableGraduationDate && maxDeferrableGraduationDate < graduationDate;
    const awaitingGraduation = endDate < now && graduationDate > now;
    const supportCurrentSection =
        !includedCohortSectionId || cohortSections.some(cs => cs.id === includedCohortSectionId);

    if (inProgress) tags.push({ label: StatusTag.InProgress, color: 'default' });
    if (awaitingGraduation) tags.push({ label: StatusTag.AwaitingGraduation, color: 'default' });
    if (pastMaxDeferrableGraduationDate) {
        tags.push({ label: StatusTag.PastMaxDeferrableGraduationDate, color: 'error' });
    }
    if (!supportCurrentSection) tags.push({ label: StatusTag.GlobalSection, color: 'primary' });

    return tags;
};

const labelRender =
    (maxDeferrableGraduationDate: number | undefined, includedCohortSectionId: string | undefined) =>
    (cohort: CohortForAdminListsAttrs) => {
        const tags = buildTags(cohort, maxDeferrableGraduationDate, includedCohortSectionId);

        return <CohortSelectOption cohort={cohort} tags={tags} />;
    };

function ChangeCohortSelect({
    availableTargetCohortIds,
    maxDeferrableGraduationDate,
    includedCohortSectionId,
}: ChangeCohortSelectProps) {
    const { availableCohorts } = useCohorts();
    const { formState, watch } = useFormContext<{ programType: string }>();
    const programType = watch('programType');

    const cohortsAvailableToChange = useMemo(
        () => availableCohorts?.filter(cohort => availableTargetCohortIds?.includes(cohort.id)) || [],

        [availableCohorts, availableTargetCohortIds],
    );

    const availableProgramTypes = useMemo(
        () =>
            cohortsAvailableToChange.length < 9
                ? []
                : [...new Set(cohortsAvailableToChange.map(cohort => cohort.programType))],
        [cohortsAvailableToChange],
    );

    const cohortOptions = useMemo(
        () =>
            availableProgramTypes.length > 1
                ? cohortsAvailableToChange.filter(cohort => cohort.programType === programType)
                : cohortsAvailableToChange,
        [availableProgramTypes, cohortsAvailableToChange, programType],
    );

    const optionLabel = useMemo(
        () => labelRender(maxDeferrableGraduationDate, includedCohortSectionId),
        [maxDeferrableGraduationDate, includedCohortSectionId],
    );

    return availableCohorts ? (
        <>
            {availableProgramTypes.length > 1 && (
                <Select
                    name="programType"
                    label="Program"
                    fullWidth
                    disabled={formState.isSubmitting}
                    options={availableProgramTypes}
                />
            )}
            <Select
                name="cohortId"
                label="Cohort"
                aria-label="Cohort"
                fullWidth
                disabled={formState.isSubmitting || cohortOptions.length < 1}
                options={cohortOptions}
                optionLabel={optionLabel}
                optionValue={cohort => cohort.id}
            />
        </>
    ) : null;
}

export default ChangeCohortSelect;
