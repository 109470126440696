import { type Moment } from 'moment';
import { type ReactNode } from 'react';
import { type UserManagementActionType } from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

export enum HandlePaymentOptionVal {
    FullAmountPayment = 'FAP',
    FullAmountInvoice = 'FAI',
    SubscriptionManual = 'SM',
    SubscriptionAuto = 'SA',
    PaymentGracePeriod = 'PGP',
    NoPaymentsRequired = 'NPR',
}

export type HandlePaymentOption = {
    label: string;
    value: HandlePaymentOptionVal;
    desc?: ReactNode;
};

export type FormFields = {
    handlePaymentOption: HandlePaymentOptionVal;
    paymentSelectDate: Moment;
    paymentGracePeriodEndAt: Moment | null | undefined;
    createSubscription: boolean;
};

export type PaymentSelectProps = {
    disabled: boolean;
    isFullScholarship: boolean;
    isOneTimePlan?: boolean;
    actionType?: UserManagementActionType;
};
