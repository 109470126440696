import angularModule from 'Users/angularModule/scripts/users_module';
// iguana service wrapper class
// currently only opening up /api/users.json which returns info about the current user
angularModule.factory('UserIdVerification', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('user_id_verifications');
            this.alias('UserIdVerification');
            this.setIdProperty('id');
            this.embeddedIn('user');

            return {
                forVerificationPeriod(verificationPeriod) {
                    return (
                        this.cohort_id === verificationPeriod.cohortId &&
                        this.id_verification_period_index === verificationPeriod.index
                    );
                },
            };
        });
    },
]);
