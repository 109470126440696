import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import ChangeSectionSelect from 'UserAdministrationTab/HelperComponents/ChangeSectionSelect';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserActionType,
    UserManagementActionNamespace,
    type UserManagementActionAttrs,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { Checkbox } from 'FrontRoyalMaterialUiForm';
import { getProgramConfigValue } from 'Program';

type FormFields = {
    auditing: boolean;
    cohortId: string;
    programType: string;
    cohortSectionId: string;
};

export const INCLUDE_AS_AUDITING_LABEL = 'Auditing';

interface IncludeInProgramAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
}

class IncludeInProgram extends AbstractUserManagementAction {
    static actionType = UserActionType.IncludeInProgram;
    static namespace = UserManagementActionNamespace.User;
    static description = (
        <p>
            Bypass the application and offer and directly admit a user to a program. Add users as auditing users in
            exceptional cases, such as journalists, investors, or similar, or admit non-auditing students to program
            types that do not accept applications.
        </p>
    );

    get title() {
        return 'Include in Program';
    }

    availableTargetCohortIds: string[];

    constructor(attrs: IncludeInProgramAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    static formValidationSchema = Yup.object().shape({
        auditing: Yup.boolean().required(),
        programType: Yup.string().required(),
        cohortId: Yup.string().required(),
        cohortSectionId: Yup.string().required(),
    });

    static FormFields = ({ action }: FormFieldProps<never, IncludeInProgram>) => {
        const { cohortsById } = useCohorts();
        const { watch, setFieldValue } = useFormContext<FormFields>();
        const [cohortId] = watch(['cohortId']);
        const [auditingDisabled, setAuditingDisabled] = useState(true);
        const sections = useMemo(() => (cohortId ? cohortsById[cohortId].cohortSections : []), [cohortId, cohortsById]);

        useEffect(() => {
            if (!cohortId) {
                setAuditingDisabled(true);
                setFieldValue('auditing', false);
                return;
            }
            const supportsProgramApplications = getProgramConfigValue(
                cohortsById[cohortId].programType,
                'supportsProgramApplications',
            ) as boolean;
            setFieldValue('auditing', supportsProgramApplications);
            setAuditingDisabled(supportsProgramApplications);
        }, [cohortId, setFieldValue, setAuditingDisabled, cohortsById]);

        return (
            <>
                <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
                <ChangeSectionSelect name="cohortSectionId" sections={sections} />
                <Checkbox name="auditing" label={INCLUDE_AS_AUDITING_LABEL} disabled={auditingDisabled} />
            </>
        );
    };
}

export default IncludeInProgram;
