import { type ImageInterface } from 'Image/types';
import { type Config } from 'FrontRoyalConfig';
import regionAwareImageCdnRewriter from './regionAwareImageCdnRewriter';

export default function regionAwareImageUrlForFormat(
    image: ImageInterface,
    format: string,
    config: Config,
): string | null {
    return (
        image &&
        image.formats &&
        image.formats[format] &&
        regionAwareImageCdnRewriter(image.formats[format].url, config)
    );
}
