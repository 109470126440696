import { useEffect } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    OfferActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { EditSectionOffers } from 'UserAdministrationTab/HelperComponents/EditSectionOffers';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import { type AdmissionOffer } from 'AdmissionOffer';
import { type SectionOfferFormValue } from 'UserAdministrationTab/HelperComponents/EditSectionOffers.types';

import './ChangeOfferDetails.scss';

type FormFields = {
    cohortId: string;
    cohortSectionOffers: SectionOfferFormValue[];
};

type ExecuteActionParams = Omit<FormFields, 'programType'>;

type ChangeOfferDetailsAttrs = UserManagementActionAttrs & {
    availableTargetCohortIds: string[];
};

class ChangeOfferDetails extends AbstractUserManagementAction {
    static actionType = OfferActionType.ChangeOfferDetails;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (<p>Use to adjust cohort, and/or which sections or scholarships are shown on their offer.</p>);

    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
        cohortSectionOffers: Yup.array().of(
            Yup.object().shape({
                cohortSectionId: Yup.string().required(),
                offeredScholarshipIds: Yup.array().of(Yup.string().required()).required(),
            }),
        ),
    });

    availableTargetCohortIds: string[];

    constructor(attrs: ChangeOfferDetailsAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    formatFormValues(values: FormFields): ExecuteActionParams {
        return {
            cohortId: values.cohortId,
            cohortSectionOffers: values.cohortSectionOffers?.map(section => ({
                cohortSectionId: section.cohortSectionId,
                offeredScholarshipIds: section.offeredScholarshipIds,
            })),
        };
    }

    static FormFields = ({
        record: { cohortSectionOffers, cohortId: originalCohortId },
        action,
    }: FormFieldProps<AdmissionOffer, ChangeOfferDetails>) => {
        const { watch, reset } = useFormContext<FormFields>();
        const cohortId = watch('cohortId');

        useEffect(() => {
            reset({
                cohortId: originalCohortId,
                cohortSectionOffers: [],
            });
        }, [cohortSectionOffers, originalCohortId, reset]);

        return (
            <>
                <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
                {cohortId !== originalCohortId && (
                    <>
                        You should change the cohort on an admission offer when a student is not able to commit to
                        registering yet, but we want to give them the opportunity to register in a subsequent cohort.
                        <br />
                        This should be used rarely. In most cases we require a student to register first before
                        deferring to a later cohort, or else re-apply later if they are not willing to register now
                    </>
                )}
                <EditSectionOffers cohortId={cohortId} sectionOffers={cohortSectionOffers} />
            </>
        );
    };
}

export default ChangeOfferDetails;
