import { DisconnectedError } from 'DisconnectedError';
import angularModule from './users_module';

angularModule.factory('CareerProfileInterceptor', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');

        return {
            response(response) {
                let updatedProperties;
                try {
                    updatedProperties = response.data.meta.push_messages.career_profile;
                    // eslint-disable-next-line no-empty
                } catch (e) {}

                // if we have a career profile to update...
                if ($rootScope.currentUser && $rootScope.currentUser.career_profile && updatedProperties) {
                    // save the original updated at value
                    const oldUpdatedAt = $rootScope.currentUser.career_profile.updated_at;
                    const newUpdatedAt = updatedProperties.updated_at;

                    // update the career profile fields that were pushed down
                    $rootScope.currentUser.career_profile.copyAttrs(updatedProperties);

                    // if updated_at changed to be newer, we want to fetch a new Career Profile unless
                    // the update was caused by us (i.e.: it came from a request to the career_profiles_controller)
                    const url = (response && response.config && response.config.url) || '';

                    if (!!oldUpdatedAt && newUpdatedAt > oldUpdatedAt && !url.includes('/api/career_profiles.json')) {
                        const CareerProfile = $injector.get('CareerProfile');

                        /*
                            The user is not waiting on this request, and does not
                            know if it is happening.   If it fails because the user
                            went offline, we do not expose that to the user. So,
                            we set `background` and just ignore DisconnectedError.
                            currentUser.career_profile will get updated once we're
                            back online and another request pushes it down.
                        */
                        CareerProfile.show(
                            $rootScope.currentUser.career_profile.id,
                            {
                                view: 'editable',
                            },
                            {
                                'FrontRoyal.ApiErrorHandler': { background: true },
                            },
                        )
                            .then(showResponse => {
                                $rootScope.currentUser.career_profile = showResponse.result;
                            })
                            .catch(err => {
                                // If the show call fails, then our career profile in the client
                                // does not actually have the changes that the updated_at would indicate
                                // it does.  In that case, we revert back to the old updated at, and then
                                // the next time we intercept here it will trigger a new show call.
                                $rootScope.currentUser.career_profile.updated_at = oldUpdatedAt;

                                if (err.constructor !== DisconnectedError) {
                                    throw err;
                                }
                            });
                    }
                }

                return response;
            },
        };
    },
]);

angularModule.config([
    '$httpProvider',
    $httpProvider => {
        $httpProvider.interceptors.push('CareerProfileInterceptor');
    },
]);
