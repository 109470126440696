import { useEffect } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Select, DatePicker } from 'FrontRoyalMaterialUiForm';
import moment from 'moment';
import { type FormFields, type PaymentSelectProps, HandlePaymentOptionVal } from './PaymentSelect.types';
import { showDatePicker, useHandlePaymentOptions } from './PaymentSelectHelper';

const PAYMENT_GRACE_PERIOD_END_AT = 'paymentGracePeriodEndAt';

export const PaymentSelect = ({ disabled, isFullScholarship, isOneTimePlan, actionType }: PaymentSelectProps) => {
    const { formState, watch, setFieldValue } = useFormContext<FormFields>();
    const [handlePaymentOption, paymentSelectDate] = watch(['handlePaymentOption', 'paymentSelectDate']);
    const handlePaymentOptions = useHandlePaymentOptions(isFullScholarship, isOneTimePlan, actionType);

    useEffect(() => {
        if (showDatePicker(handlePaymentOption)) {
            setFieldValue('paymentSelectDate', moment().add(7, 'd'));
        }
    }, [handlePaymentOption, setFieldValue]);

    useEffect(() => {
        switch (handlePaymentOption) {
            case HandlePaymentOptionVal.FullAmountPayment:
            case HandlePaymentOptionVal.SubscriptionManual:
                setFieldValue(PAYMENT_GRACE_PERIOD_END_AT, moment().add(20, 'm')); // 20 minute grace period
                break;
            case HandlePaymentOptionVal.SubscriptionAuto:
            case HandlePaymentOptionVal.FullAmountInvoice:
            case HandlePaymentOptionVal.PaymentGracePeriod:
                // The useEffect hook above will add 7 days to the current date
                setFieldValue(
                    PAYMENT_GRACE_PERIOD_END_AT,
                    moment(paymentSelectDate).tz('America/New_York').endOf('day'),
                );
                break;
            case HandlePaymentOptionVal.NoPaymentsRequired:
                setFieldValue(PAYMENT_GRACE_PERIOD_END_AT, null);
                break;
            default:
                // undefined is different from null here because we expect null sometimes
                // we set undefined here if the form is expecting a value, but no option was selected yet
                // to disable the form
                setFieldValue(PAYMENT_GRACE_PERIOD_END_AT, undefined);
                break;
        }
    }, [handlePaymentOption, paymentSelectDate, setFieldValue]);

    return (
        <>
            <>
                <Select
                    name="handlePaymentOption"
                    label="How do you want to handle payments?"
                    fullWidth
                    disabled={formState.isSubmitting || disabled}
                    options={handlePaymentOptions}
                />
                {handlePaymentOption && (
                    <div>{handlePaymentOptions.find(opt => opt.value === handlePaymentOption)?.desc || ''}</div>
                )}
            </>
            {showDatePicker(handlePaymentOption) && (
                <>
                    <div className="title" aria-label="paymentGracePeriodEndAt">
                        Payment Grace Period
                    </div>
                    <DatePicker
                        className="register-student-form-date-picker"
                        label="Payment Grace Period End"
                        name="paymentGracePeriodEndAt"
                        views={['year', 'month', 'day']}
                        disabled={formState.isSubmitting}
                        minDate={moment()}
                    />
                </>
            )}
        </>
    );
};

export default PaymentSelect;
