import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { formattedUserFacingMonthDayYearLong as formatDate } from 'DateHelpers';
import { DatePicker } from 'FrontRoyalMaterialUiForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    InclusionActionType,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import {
    type FormFields,
    type ExecuteActionParams,
    type UpdateBillingSituationAttrs,
} from './UpdateBillingSituation.types';

import './UpdateBillingSituation.scss';

class UpdateBillingSituation extends AbstractUserManagementAction {
    static namespace = UserManagementActionNamespace.ProgramInclusion;
    static actionType = InclusionActionType.UpdateBillingSituation;

    gracePeriodEndAt: number | null;
    recurringPaymentsCurrent?: boolean;
    gracePeriodInFuture?: boolean;
    nextPaymentAt: number | null;

    constructor(attrs: UpdateBillingSituationAttrs) {
        super(attrs);
        this.gracePeriodEndAt = attrs.paymentGracePeriodEndAt ? attrs.paymentGracePeriodEndAt * 1000 : null;
        this.recurringPaymentsCurrent = attrs.recurringPaymentsCurrent;
        this.gracePeriodInFuture = this.gracePeriodEndAt
            ? new Date(this.gracePeriodEndAt).getTime() > Date.now()
            : false;
        this.nextPaymentAt = attrs.nextPaymentAt ? attrs.nextPaymentAt * 1000 : null;
        this.addNextPaymentMessage();
    }

    static formValidationSchema = Yup.object().shape({
        gracePeriod: Yup.number().nullable().defined(),
    });

    addNextPaymentMessage() {
        // if the server indicated a nextPaymentAt date, we format that into a custom validation error message and append it:
        if (this.disabled && this.nextPaymentAt) {
            this.validationErrors = this.validationErrors.concat(
                `Their next payment is scheduled for ${formatDate(this.nextPaymentAt, false)}.`,
            );
        }
    }

    formatFormValues({ gracePeriod }: FormFields): ExecuteActionParams {
        return { gracePeriod: gracePeriod && gracePeriod.valueOf() / 1000 };
    }

    get description() {
        const gracePeriodEndDate = this.gracePeriodEndAt;
        const pastDueSubscription = this.recurringPaymentsCurrent === false;
        const gracePeriodInFuture = this.gracePeriodInFuture;

        const formattedGracePeriodEndAt = !!gracePeriodEndDate && formatDate(gracePeriodEndDate!, false);

        const pastDueMessage = gracePeriodInFuture ? `will be locked on ${formattedGracePeriodEndAt}` : 'is locked';

        const noSubscriptionMessage = gracePeriodInFuture
            ? `This user has been granted a grace period until ${formattedGracePeriodEndAt}. If they do not pay
            their tuition in full or set up a subscription by that date, their content will be
            locked.`
            : "The user's payment grace period has expired and their content is locked.";

        return (
            <div>
                {pastDueSubscription ? (
                    <p>
                        This user has missed payments on their subscription. Their content {pastDueMessage}. The user
                        can update their Stripe payment information on the Application Status page. If they do so and
                        make a successful payment, their content will be unlocked. If you need to update the user&apos;s
                        grace period, you can do so below.
                    </p>
                ) : (
                    <p>
                        {noSubscriptionMessage}
                        If you need to create a subscription for the user, you can do so through the stripe dashboard.
                        If you want the user to be able to create a subscription themselves on the Application Status
                        page, delete the grace period below. If the user has made a payment outside of stripe and you
                        need to record it, you can do so on the Transactions tab. If you need to update the user&apos;s
                        grace period, you can do so below.
                    </p>
                )}
            </div>
        );
    }

    static FormFields = ({ action: { gracePeriodEndAt } }: FormFieldProps<never, UpdateBillingSituation>) => {
        const { formState, watch, setFieldValue, reset } = useFormContext<FormFields>();

        const gracePeriod = watch('gracePeriod');

        useEffect(() => {
            if (gracePeriodEndAt) {
                reset({ gracePeriod: moment(gracePeriodEndAt) });
            }
        }, [gracePeriodEndAt, reset]);

        return (
            <div className="update-billing-situation-form">
                {gracePeriod ? (
                    <>
                        <DatePicker
                            minDate={moment(gracePeriodEndAt).subtract(1, 'month')}
                            name="gracePeriod"
                            label="Grace Period"
                            className="date-picker-input"
                            disabled={formState.isSubmitting}
                        />
                        <button
                            type="button"
                            className="btn outline delete-grace-period"
                            onClick={() => setFieldValue('gracePeriod', null)}
                        >
                            Delete grace period
                        </button>
                    </>
                ) : (
                    <button
                        type="button"
                        className="btn outline"
                        onClick={() => setFieldValue('gracePeriod', moment())}
                    >
                        Add Grace Period
                    </button>
                )}
            </div>
        );
    };
}

export default UpdateBillingSituation;
