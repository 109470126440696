import * as Yup from 'yup';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    OfferActionType,
    type FormFieldProps,
    type UserManagementActionAttrs,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

interface SwitchOfferedProgramAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
}

class SwitchOfferedProgram extends AbstractUserManagementAction {
    static actionType = OfferActionType.SwitchOfferedProgram;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (
        <p>
            Use to switch the program that is being offered to the student. Will expire the current offer and create a
            new application and offer for the given program. Use change offer details afterwards to edit details.
        </p>
    );

    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
    });

    availableTargetCohortIds: string[];

    constructor(attrs: SwitchOfferedProgramAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    static FormFields = ({ action }: FormFieldProps<never, SwitchOfferedProgram>) => (
        <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
    );
}

export default SwitchOfferedProgram;
