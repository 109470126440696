import { useEffect } from 'react';
import * as Yup from 'yup';
import { type Nullable } from '@Types';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { Checkbox, Select } from 'FrontRoyalMaterialUiForm';
import { type ProgramInclusion, HumanReadableAcademicProbationStatus } from 'ProgramInclusion';
import { useGetUserInAdmin } from 'UserAdministrationTab/redux';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { useFormContext } from 'FrontRoyalReactHookForm';

const NOT_ON_ACADEMIC_PROBATION_VALUE = '';
const NOT_ON_ACADEMIC_PROBATION_LABEL = 'Not on Academic Probation';

type AcademicProbationStatusFormFieldValue =
    | keyof typeof HumanReadableAcademicProbationStatus
    | typeof NOT_ON_ACADEMIC_PROBATION_VALUE;

type FormFields = {
    extendExamTime: boolean;
    disableExamLocking: boolean;
    honorsIneligible: boolean;
    graduatedWithHonors: boolean;
    requiresTilaDisclosure: boolean;
    academicHold: boolean;
    academicProbationStatus: AcademicProbationStatusFormFieldValue;
};

type ExecuteActionParams = Omit<FormFields, 'academicProbationStatus'> & {
    academicProbationStatus: Nullable<keyof typeof HumanReadableAcademicProbationStatus>;
};

type AcademicProbationStatusOption = {
    value: AcademicProbationStatusFormFieldValue;
    label: HumanReadableAcademicProbationStatus | typeof NOT_ON_ACADEMIC_PROBATION_LABEL;
};

class ChangeSettings extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeSettings;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    static description = (<p>Use to change the student&apos;s inclusion settings.</p>);

    static formValidationSchema = Yup.object().shape({
        extendExamTime: Yup.boolean().required(),
        disableExamLocking: Yup.boolean().required(),
        honorsIneligible: Yup.boolean().required(),
        requiresTilaDisclosure: Yup.boolean().required(),
        academicHold: Yup.boolean().required(),
        academicProbationStatus: Yup.string().nullable(),
    });

    formatFormValues(values: FormFields): ExecuteActionParams {
        return {
            ...values,
            academicProbationStatus: values.academicProbationStatus || null,
        };
    }

    static FormFields = ({ record, userId }: FormFieldProps<ProgramInclusion>) => {
        const { formState, reset } = useFormContext<FormFields>();
        const { user } = useGetUserInAdmin(userId);
        const {
            disableExamLocking,
            honorsIneligible,
            graduatedWithHonors,
            status,
            requiresTilaDisclosure,
            auditing,
            academicHold,
            academicProbationStatus,
        } = record;
        const notGraduated = status !== 'graduated';
        const academicProbationStatusOptions: AcademicProbationStatusOption[] = Object.entries(
            HumanReadableAcademicProbationStatus,
        ).map(entry => ({
            value: entry[0] as keyof typeof HumanReadableAcademicProbationStatus,
            label: entry[1],
        }));
        academicProbationStatusOptions.splice(0, 0, {
            value: NOT_ON_ACADEMIC_PROBATION_VALUE,
            label: NOT_ON_ACADEMIC_PROBATION_LABEL,
        });

        useEffect(() => {
            reset({
                extendExamTime: !!user?.extendExamTime,
                disableExamLocking,
                honorsIneligible,
                requiresTilaDisclosure,
                graduatedWithHonors,
                academicHold,
                academicProbationStatus: academicProbationStatus || NOT_ON_ACADEMIC_PROBATION_VALUE,
            });
        }, [
            reset,
            user?.extendExamTime,
            disableExamLocking,
            honorsIneligible,
            requiresTilaDisclosure,
            graduatedWithHonors,
            academicHold,
            academicProbationStatus,
        ]);

        return notGraduated ? (
            <>
                <Checkbox name="extendExamTime" label="Extend exam time" />
                <Checkbox name="disableExamLocking" label="Disable exam locking" />
                <Checkbox name="honorsIneligible" label="Ineligible for honors" />
                {!auditing && <Checkbox name="requiresTilaDisclosure" label="Require TILA disclosure" />}
                <Checkbox name="academicHold" label="On administrative hold" />
                <Select
                    name="academicProbationStatus"
                    label="Academic Probation Status"
                    fullWidth
                    disabled={formState.isSubmitting}
                    options={academicProbationStatusOptions}
                />
            </>
        ) : (
            <Checkbox name="graduatedWithHonors" label="Graduated with honors" />
        );
    };
}

export default ChangeSettings;
