import React from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { Select } from 'FrontRoyalMaterialUiForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserActionType,
    UserManagementActionNamespace,
    type UserManagementActionAttrs,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { type ProgramField } from 'Program';

type ProgramFieldOption = {
    programField: ProgramField;
    error: string | null;
};

type FormFields = {
    auditing: boolean;
    cohortId: string;
    programField: string;
    cohortSectionId: string;
};

export const INCLUDE_AS_AUDITING_LABEL = 'Auditing';

interface AllowReapplicationAttrs extends UserManagementActionAttrs {
    availableProgramFieldOptions: ProgramFieldOption[];
}

class AllowReapplication extends AbstractUserManagementAction {
    static actionType = UserActionType.AllowReapplication;
    static namespace = UserManagementActionNamespace.User;
    static description = (<p>Use to allow a user to bypass the reapplication limits and reapply immediately.</p>);

    availableProgramFieldOptions: ProgramFieldOption[];

    constructor(attrs: AllowReapplicationAttrs) {
        super(attrs);
        this.availableProgramFieldOptions = attrs.availableProgramFieldOptions;
    }

    get formValidationSchema() {
        const programFieldOptions = this.availableProgramFieldOptions;
        return Yup.object().shape({
            programField: Yup.string().required(),
            hiddenField: Yup.string().when('programField', {
                is: (val: ProgramField) => !!programFieldOptions.find(opt => opt.programField === val)?.error,
                then: Yup.string().required(),
                otherwise: Yup.string().nullable().optional(),
            }),
        });
    }

    static FormFields = ({ action }: FormFieldProps<never, AllowReapplication>) => {
        const { watch } = useFormContext<FormFields>();
        const [programField] = watch(['programField']);
        const selectedProgramFieldOption = action.availableProgramFieldOptions.find(
            opt => opt.programField === programField,
        );

        return (
            <>
                <Select
                    label="Program Field"
                    name="programField"
                    options={action.availableProgramFieldOptions}
                    optionValue={opt => opt.programField}
                    optionLabel={option => option.programField}
                />
                {selectedProgramFieldOption && selectedProgramFieldOption.error && (
                    <p>{selectedProgramFieldOption.error}</p>
                )}
                <Select name="hiddenField" options={[]} sx={{ display: 'none' }} />
            </>
        );
    };
}

export default AllowReapplication;
