import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type UserManagementActionAttrs,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

type MarkAsAuditingAttrs = UserManagementActionAttrs & {
    hasAdmissionOffer: boolean;
};

class MarkAsAuditing extends AbstractUserManagementAction {
    static actionType = InclusionActionType.MarkAsAuditing;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    hasAdmissionOffer: boolean;

    constructor({ hasAdmissionOffer, validationErrors }: MarkAsAuditingAttrs) {
        super({ validationErrors });
        this.hasAdmissionOffer = hasAdmissionOffer;
    }

    get description() {
        return (
            <>
                <p>
                    Use to mark users who should not be included as regular students in reports on this cohort. This
                    includes, for example, test users, demo users, and journalists.
                </p>
                {this.hasAdmissionOffer && (
                    <strong>
                        This user currently has a program application and admission offer. Those records will be deleted
                        if this user is converted to auditing.
                    </strong>
                )}
            </>
        );
    }
}

export default MarkAsAuditing;
