import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { MenuItem, TextField, type SelectChangeEvent, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { scholarshipsByLevel } from 'UserAdministrationTab/utils';
import {
    NO_SCHOLARSHIP,
    NO_SCHOLARSHIP_LEVEL,
    FULL_SCHOLARSHIP_LEVEL,
    FULL_SCHOLARSHIP,
    type Scholarship,
} from 'Scholarship';
import { useOfferableScholarships } from 'UserAdministrationTab/redux';
import FrontRoyalSpinner from 'FrontRoyalSpinner';

interface ChangeScholarshipSelectProps {
    name: string;
    availableIds?: string[];

    // By default, this component selects a scholarship id. If selectLevel is true,
    // then it will instead select a scholarship level. This only works when there is
    // only one scholarship available for each level (see UpdateExecEdEligibilityBundle)
    selectLevel?: boolean;

    handleRemoveScholarship: () => void;
}

const optionLabel = (translateFn: (val: string) => string, { label, amount }: Scholarship) => {
    let name = 'Base Scholarship';

    if (label) {
        name = translateFn(`scholarship_labels.${label}`);
    }

    return `${name}${amount ? ` - $${amount}` : ''}`;
};

export const ChangeScholarshipSelect = ({
    name,
    availableIds,
    handleRemoveScholarship,
    selectLevel = false,
}: ChangeScholarshipSelectProps) => {
    const { formState, watch, setFieldValue } = useFormContext();
    const fieldValue = watch(name); // fieldValue can be either an id or a level depending on whether selectLevel is true
    const [level, setLevel] = useState<number | string>('');
    const [scholarshipId, setScholarshipId] = useState<string>('');
    const [scholarshipsForSelectedLevel, setScholarshipsForSelectedLevel] = useState<Scholarship[]>([]);
    const { t } = useTranslation('back_royal');

    const { offerableScholarships, availableScholarships, scholarshipsLoading } =
        useOfferableScholarships(availableIds);
    const scholarshipsMap = useMemo(() => scholarshipsByLevel(availableScholarships), [availableScholarships]);
    const levelOptions = useMemo(
        () =>
            Object.keys(scholarshipsMap)
                .map(levelString => {
                    let optionText = levelString;
                    let sortKey = Number(levelString);
                    if (Number(levelString) === NO_SCHOLARSHIP_LEVEL) {
                        optionText = NO_SCHOLARSHIP;
                        sortKey = Number.MIN_VALUE;
                    } else if (Number(levelString) === FULL_SCHOLARSHIP_LEVEL) {
                        optionText = FULL_SCHOLARSHIP;
                        sortKey = Number.MAX_VALUE;
                    }

                    return {
                        value: levelString,
                        label: optionText,
                        sortKey,
                    };
                })
                .sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)),
        [scholarshipsMap],
    );

    // set the initial values for level and id
    useEffect(() => {
        if (!offerableScholarships) {
            return;
        }

        if (selectLevel && fieldValue !== level) {
            setLevel(fieldValue || '');
        }

        if (!selectLevel && fieldValue !== scholarshipId) {
            const selectedLevel = offerableScholarships.find(ss => ss.id === fieldValue)?.level;
            if (selectedLevel) {
                setLevel(selectedLevel);
                setScholarshipId(fieldValue || '');
            }
        }
    }, [offerableScholarships, scholarshipId, level, fieldValue, selectLevel]);

    // Respond to the level variable being changed
    useEffect(() => {
        const newOptions = (level && scholarshipsMap && scholarshipsMap[level]) || [];
        setScholarshipsForSelectedLevel(newOptions);

        if (newOptions.length === 1) {
            const scholarship = newOptions[0];
            const selectedScholarshipId = scholarship.id;
            const newValue = selectLevel ? scholarship.level : scholarship.id;
            setScholarshipId(selectedScholarshipId);
            if (fieldValue !== newValue) {
                setFieldValue(name, newValue);
            }
        }
    }, [level, name, scholarshipsMap, setFieldValue, fieldValue, selectLevel]);

    const handleLevelChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
        setLevel(value as number);
        setScholarshipId('');
        setFieldValue(name, undefined);
    };

    const handleScholarshipChange = (event: SelectChangeEvent<unknown>) => {
        const selectedVal = selectLevel ? (event.target.value as number) : (event.target.value as string);
        const scholarship = scholarshipsForSelectedLevel.find(s =>
            selectLevel ? s.level === selectedVal : s.id === selectedVal,
        );

        if (scholarship) {
            setScholarshipId(scholarship.id);
        }
        setFieldValue(name, selectLevel ? scholarship?.level : scholarship?.id);
    };

    const internalHandleRemoveScholarship = useCallback(() => {
        setLevel('');
        setScholarshipId('');
        handleRemoveScholarship();
    }, [setLevel, setScholarshipId, handleRemoveScholarship]);

    return scholarshipsLoading ? (
        <FrontRoyalSpinner />
    ) : (
        <>
            <TextField
                select
                variant="outlined"
                label="Level"
                SelectProps={{
                    value: level,
                    fullWidth: true,
                    disabled: formState.isSubmitting || levelOptions.length < 1,
                    onChange: handleLevelChange,
                }}
            >
                {levelOptions.map(({ value, label }) => (
                    <MenuItem value={value} key={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                variant="outlined"
                label="Scholarship"
                SelectProps={{
                    value: scholarshipId,
                    fullWidth: true,
                    disabled: formState.isSubmitting || !level || scholarshipsForSelectedLevel.length < 1,
                    onChange: handleScholarshipChange,
                }}
            >
                {scholarshipsForSelectedLevel.map(option => (
                    <MenuItem value={option.id} key={option.id}>
                        {optionLabel(t, option)}
                    </MenuItem>
                ))}
            </TextField>
            <IconButton
                color="error"
                className="remove-scholarship"
                data-testid="remove-scholarship"
                onClick={internalHandleRemoveScholarship}
            >
                <Delete />
            </IconButton>
        </>
    );
};
export default ChangeScholarshipSelect;
