import { sample } from 'lodash/fp';

type Hideable = { shouldHide?: boolean };

const selectItemAtRandom = <T extends Hideable>(items: T[]) => {
    // In cases where some of the items are hidden, we want to filter them out to ensure a hidden box doesn't take priority over a visible one. BUT, in cases where all of the items are hidden,
    // we still want to return an item from this function, otherwise it'll return undefined and cause errors in other places.
    const hiddenItems = items.filter(({ shouldHide }) => shouldHide);
    const visibleItems = items.filter(({ shouldHide }) => !shouldHide);
    return sample<T>(hiddenItems.length === items.length ? items : visibleItems)!;
};

export default selectItemAtRandom;
