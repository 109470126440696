import { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import { useCohorts, useGetUserInAdmin, useOfferableScholarships } from 'UserAdministrationTab/redux';
import {
    ChangeScholarshipSelect,
    ChangeSectionSelect,
    ProgramPaymentCard,
    PaymentReconciliationCard,
} from 'UserAdministrationTab/HelperComponents';
import { getTuitionContractForProgramInclusion, type BaseUser } from 'Users';
import { type ProgramInclusion } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import usePaymentSituation from 'UserAdministrationTab/utils/usePaymentSituation';
import TuitionPlanSelect from 'UserAdministrationTab/HelperComponents/TuitionPlanSelect';
import {
    useSetDefaultTuitionPlanId,
    useTuitionPlans,
} from 'UserAdministrationTab/UserManagementActions/AdmissionOffer/RegisterStudentHelpers';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { type PaymentSituation } from 'PaymentSituation';
import { type ChangeProgramAttrs, type FormFields, type ExecuteActionParams } from './ChangeProgram.types';

import './ChangeProgram.scss';

class ChangeProgram extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeProgram;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    canBackOutOfRegistration: boolean;
    availableTargetCohortIds: string[];
    selectablePaymentSituations: PaymentSituation[];

    constructor(attrs: ChangeProgramAttrs) {
        super(attrs);
        this.canBackOutOfRegistration = attrs.canBackOutOfRegistration;
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
        this.selectablePaymentSituations = attrs.selectablePaymentSituations;
    }

    get description() {
        return (
            <>
                <p>Use to change a student&apos;s program. Payments will be carried over.</p>
                {this.canBackOutOfRegistration ? (
                    <p>
                        The user will be backing out of registration in their existing program and will receive a new
                        program application, admission offer, and program inclusion for the new program.
                    </p>
                ) : (
                    <p>
                        Since the cohort the user registered for has already started, they cannot be backed out of
                        registration. They will be withdrawn from their existing program with the reason marked as
                        &quot;Transferred to another program.&quot; They will receive a new program application,
                        admission offer, and program inclusion for the new program.
                    </p>
                )}
            </>
        );
    }

    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
        cohortSectionId: Yup.string().required(),
        scholarshipIds: Yup.array().of(Yup.string().required()).required(),
        tuitionPlanId: Yup.string().required(),
    });

    formatFormValues({ cohortId, cohortSectionId, scholarshipIds, tuitionPlanId }: FormFields): ExecuteActionParams {
        return {
            cohortId,
            cohortSectionId,
            scholarshipIds,
            tuitionPlanId,
        };
    }

    static FormFields = ({
        userId,
        action: { availableTargetCohortIds, selectablePaymentSituations },
        record,
    }: FormFieldProps<ProgramInclusion, ChangeProgram>) => {
        const {
            includedCohortSectionId,
            cumulativeScholarship: oldCumulativeScholarship,
            tuitionPlanId: oldTuitionPlanId,
            admissionOfferId,
        } = record as ProgramInclusion;

        const { cohortsById } = useCohorts();
        const { user } = useGetUserInAdmin(userId);
        const { watch, setFieldValue, formState } = useFormContext<FormFields>();
        const [cohortId, cohortSectionId, tuitionPlanId] = watch(['cohortId', 'cohortSectionId', 'tuitionPlanId']);

        const scholarshipIds = watch('scholarshipIds');
        if (scholarshipIds == null) setFieldValue('scholarshipIds', []);

        const newCohort = useMemo(() => cohortsById[cohortId], [cohortsById, cohortId]);
        const sections = useMemo(() => newCohort?.cohortSections || [], [newCohort]);

        const isIncludedSectionInSelectedCohort = useMemo(
            () => sections.find(({ id }) => id === includedCohortSectionId),
            [sections, includedCohortSectionId],
        );

        const { availableScholarships } = useOfferableScholarships(newCohort?.availableScholarshipIds || []);

        const originalPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            oldTuitionPlanId,
            oldCumulativeScholarship,
        );

        const handleAddScholarship = useCallback(() => {
            setFieldValue('scholarshipIds', [...scholarshipIds, undefined]);
        }, [scholarshipIds, setFieldValue]);

        const handleRemoveScholarship = useCallback(
            (index: number) => {
                scholarshipIds.splice(index, 1);
                setFieldValue('scholarshipIds', scholarshipIds);
            },
            [scholarshipIds, setFieldValue],
        );

        const newCumulativeLevel = availableScholarships
            .filter(s => scholarshipIds.includes(s.id))
            .map(s => s.level)
            .reduce<number>((total, level) => total + (level || 0), 0);

        const newCumulativeScholarship = availableScholarships.find(s => s.level === newCumulativeLevel) || null;

        const newPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            tuitionPlanId,
            newCumulativeScholarship,
        );

        const admissionOffer = useMemo(
            () => user?.admissionOffers.find((ao: AdmissionOffer) => ao.id === admissionOfferId),
            [user, admissionOfferId],
        );

        const tuitionContract = getTuitionContractForProgramInclusion(user as BaseUser, record as ProgramInclusion);

        const oldFrequency = useMemo(() => tuitionContract?.tuitionPlan.frequency, [tuitionContract]);

        const tuitionPlans = useTuitionPlans(
            selectablePaymentSituations,
            newCumulativeScholarship,
            newCohort?.availableTuitionPlanIds,
        );

        useSetDefaultTuitionPlanId(tuitionPlans, tuitionPlanId);

        useEffect(() => {
            if (isIncludedSectionInSelectedCohort) {
                setFieldValue('cohortSectionId', isIncludedSectionInSelectedCohort.id);
            }
        }, [setFieldValue, isIncludedSectionInSelectedCohort]);

        useEffect(() => {
            if (!oldFrequency) {
                return;
            }

            const tuitionPlan = tuitionPlans.find(tp => tp.frequency === oldFrequency);
            if (tuitionPlan) {
                setFieldValue('tuitionPlanId', tuitionPlan.id);
            }
        }, [oldFrequency, setFieldValue, tuitionPlans]);

        const showWarning = !isIncludedSectionInSelectedCohort && cohortId;

        return (
            <>
                <ChangeCohortSelect availableTargetCohortIds={availableTargetCohortIds} />
                <ChangeSectionSelect name="cohortSectionId" sections={sections} />
                {showWarning && (
                    <div className="change-program-section-warning">
                        The section this user is currently included in is not available for this cohort. Pick a new
                        section.
                    </div>
                )}
                {newCohort && cohortSectionId && (
                    // FIXME: DRY with similar UI in EditSectionOffers, https://trello.com/c/VXtmE4yy
                    <>
                        <Button
                            className="add-scholarship"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddScholarship()}
                        >
                            Add Scholarship
                        </Button>
                        {scholarshipIds.map((_id, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className="scholarship-wrapper" key={index}>
                                <ChangeScholarshipSelect
                                    name={`scholarshipIds[${index}]`}
                                    availableIds={newCohort.availableScholarshipIds || []}
                                    handleRemoveScholarship={() => handleRemoveScholarship(index)}
                                />
                            </div>
                        ))}
                        <div className="tuition-plan-wrapper">
                            <TuitionPlanSelect
                                name="tuitionPlanId"
                                tuitionPlans={tuitionPlans}
                                disabled={
                                    formState.isSubmitting || scholarshipIds.some(s => !s) || tuitionPlans.length < 1
                                }
                            />
                        </div>
                    </>
                )}
                {originalPaymentSituation && admissionOffer && (
                    <div className="payment-information">
                        <ProgramPaymentCard
                            title="Original Program"
                            paymentSituation={originalPaymentSituation}
                            cohortId={admissionOffer.cohortId}
                            cohortLabel="Offer Cohort"
                        />
                        {newPaymentSituation && (
                            <ProgramPaymentCard
                                title="New Program"
                                paymentSituation={newPaymentSituation}
                                showDiffFromPaymentSituation={originalPaymentSituation}
                                cohortId={cohortId}
                            />
                        )}
                    </div>
                )}
                {newPaymentSituation && tuitionContract && (
                    <PaymentReconciliationCard
                        newPaymentSituation={newPaymentSituation}
                        tuitionContract={tuitionContract}
                    />
                )}
            </>
        );
    };
}

export default ChangeProgram;
