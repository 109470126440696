import angularModule from 'Uib-extensions/angularModule/scripts/uib_extensions_module';

angularModule.directive('onCarouselChange', [
    '$injector',
    $injector => ({
        require: 'uibCarousel',

        link(scope, element, attrs, carouselCtrl) {
            const $parse = $injector.get('$parse');
            const fn = $parse(attrs.onCarouselChange);
            const origSelect = carouselCtrl.select;
            carouselCtrl.select = function (nextSlide, direction) {
                if (nextSlide !== this.currentSlide) {
                    fn(scope, {
                        nextSlide,
                        direction,
                        nextSlideIndex: nextSlide.slide.index,
                        lastSlideIndex: this.currentSlide && this.currentSlide.slide.index,
                    });
                }
                return origSelect.apply(this, arguments);
            };
        },
    }),
]);
