import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type FormFieldProps,
    type UserManagementActionAttrs,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { useGetUserInAdmin, useOfferableScholarships } from 'UserAdministrationTab/redux';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import {
    ChangeScholarshipSelect,
    ProgramPaymentCard,
    PaymentReconciliationCard,
} from 'UserAdministrationTab/HelperComponents';
import { getTuitionContractForProgramInclusion, type BaseUser } from 'Users';
import { type ProgramInclusion } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import usePaymentSituation from 'UserAdministrationTab/utils/usePaymentSituation';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { type PaymentSituation } from 'PaymentSituation';

import './ChangeProgram.scss';

type FormFields = {
    scholarshipIds: (string | undefined)[];
};

interface ChangeScholarshipAfterRegistrationAttrs extends UserManagementActionAttrs {
    selectablePaymentSituations: PaymentSituation[];
}

class ChangeScholarshipAfterRegistration extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeScholarshipAfterRegistration;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    selectablePaymentSituations: PaymentSituation[];

    constructor(attrs: ChangeScholarshipAfterRegistrationAttrs) {
        super(attrs);
        this.selectablePaymentSituations = attrs.selectablePaymentSituations;
    }

    get description() {
        return (
            <p>
                Use in rare circumstances when a student has registered but is approved for a change in scholarship. Can
                only be done prior to 30 days after cohort start and if the student has made, at most, one payment.
            </p>
        );
    }

    static formValidationSchema = Yup.object().shape({
        scholarshipIds: Yup.array().of(Yup.string().required()).min(1),
    });

    formatFormValues({ scholarshipIds }: FormFields) {
        return {
            scholarshipIds,
        };
    }

    static FormFields = ({
        userId,
        action: { selectablePaymentSituations },
        record,
    }: FormFieldProps<ProgramInclusion, ChangeScholarshipAfterRegistration>) => {
        const {
            cumulativeScholarship: currentCumulativeScholarship,
            scholarshipIds: currentScholarshipIds,
            tuitionPlanId: currentTuitionPlanId,
            admissionOfferId,
        } = record as ProgramInclusion;

        const { user } = useGetUserInAdmin(userId);
        const { watch, setFieldValue } = useFormContext<FormFields>();

        const admissionOffer = useMemo(
            () => user?.admissionOffers.find((ao: AdmissionOffer) => ao.id === admissionOfferId),
            [user, admissionOfferId],
        );

        const tuitionContract = getTuitionContractForProgramInclusion(user as BaseUser, record as ProgramInclusion);

        const { cohortsById } = useCohorts();
        const offerCohort = cohortsById[admissionOffer?.cohortId || ''];

        const scholarshipIds = watch('scholarshipIds');
        if (scholarshipIds == null) setFieldValue('scholarshipIds', currentScholarshipIds);

        const { availableScholarships } = useOfferableScholarships(offerCohort?.availableScholarshipIds || []);

        const originalPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            currentTuitionPlanId,
            currentCumulativeScholarship,
        );

        const handleAddScholarship = useCallback(() => {
            setFieldValue('scholarshipIds', [...scholarshipIds, undefined]);
        }, [scholarshipIds, setFieldValue]);

        const handleRemoveScholarship = useCallback(
            (index: number) => {
                scholarshipIds.splice(index, 1);
                setFieldValue('scholarshipIds', scholarshipIds);
            },
            [scholarshipIds, setFieldValue],
        );

        const scholarshipSelected = scholarshipIds?.length > 0 && [...scholarshipIds].sort()[0] !== undefined;
        const newCumulativeLevel = availableScholarships
            .filter(s => scholarshipIds?.includes(s.id))
            .map(s => s.level)
            .reduce<number>((total, level) => total + (level || 0), 0);

        const newCumulativeScholarship = availableScholarships.find(s => s.level === newCumulativeLevel) || null;

        const newPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            currentTuitionPlanId,
            newCumulativeScholarship,
        );

        return (
            <>
                <Button className="add-scholarship" startIcon={<Add />} onClick={() => handleAddScholarship()}>
                    Add Scholarship
                </Button>
                {scholarshipIds?.map((_id, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="scholarship-wrapper" key={index}>
                        <ChangeScholarshipSelect
                            name={`scholarshipIds[${index}]`}
                            availableIds={availableScholarships.map(s => s.id)}
                            handleRemoveScholarship={() => handleRemoveScholarship(index)}
                        />
                    </div>
                ))}
                {originalPaymentSituation && admissionOffer && (
                    <div className="payment-information">
                        <ProgramPaymentCard
                            title="Original Payment"
                            paymentSituation={originalPaymentSituation}
                            cohortId={offerCohort?.id}
                            cohortLabel="Offer Cohort"
                        />
                        {newPaymentSituation && scholarshipSelected && (
                            <ProgramPaymentCard
                                title="New Payment"
                                paymentSituation={newPaymentSituation}
                                showDiffFromPaymentSituation={originalPaymentSituation}
                                cohortId={offerCohort?.id}
                                cohortLabel="Offer Cohort"
                            />
                        )}
                    </div>
                )}
                {newPaymentSituation && tuitionContract && scholarshipSelected && (
                    <PaymentReconciliationCard
                        newPaymentSituation={newPaymentSituation}
                        tuitionContract={tuitionContract}
                    />
                )}
            </>
        );
    };
}

export default ChangeScholarshipAfterRegistration;
