/*
    NOTE: We have this current user interceptor in the Users module as well as
    a current user interceptor in the StoredCurrentUser module. The important
    distinction between these two interceptors is that the interceptor in this
    file is responsible for handling responses to API requests while online,
    whereas the other is part of the front royal store interceptor logic
    responsible for persisting requested changes to the store prior to the
    API request getting triggered for offline mode parity.
*/

import angularModule from 'Users/angularModule/scripts/users_module';
import { isCurrentUserUpdateApiCall } from 'StoredCurrentUser';

angularModule.factory('CurrentUserInterceptor', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const $q = $injector.get('$q');
        const frontRoyalStore = $injector.get('frontRoyalStore');

        const CurrentUserInterceptor = {
            response(response) {
                return CurrentUserInterceptor.updateCurrentUserOnResponseSuccess(response).then(() => response);
            },

            responseError(response) {
                // We will hit this error interceptor when the FrontRoyalStore interceptor throws a BlockedByFrontRoyalStoreError
                // in order to cancel the request. It won't do anything in that case though, because the response is just the error,
                // so it won't have any metadata with current_user info in it.

                // We may send down updates to the user in the meta of an errored api call.
                return CurrentUserInterceptor.updateCurrentUserFromResponseMeta(response).then(() =>
                    $q.reject(response),
                );
            },

            updateCurrentUserOnResponseSuccess(response) {
                let promise = $q.resolve();

                if (isCurrentUserUpdateApiCall(response.config, $injector)) {
                    /*
                        When we save the current user, the client sends some data up to the server, the server
                        saves that information and sends back any data that might have been changed by the server.
                        All of those changes are going to end up in the currentUser, so we want all those changes to
                        get saved to the client database.
                        In practice, the stuff that the server is sending down is probably also in the meta as a push
                        message, in which case it would also be saved by updateUserFromResponseMeta, so we could maybe
                        get away with not having dataThatServerSentDown included here.  But at least theoretically
                        it is necessary.
                    */
                    const changesClientSentUp = response.config.data.record;
                    const changesServerSentDown = response.data.contents.users[0];
                    const record = { ...changesClientSentUp, ...changesServerSentDown };
                    promise = frontRoyalStore.updateCurrentUser($rootScope.currentUser.id, record);
                }

                // FIXME: It's theoretically possible for the properties in the response meta to disagree
                // with the properties included in the main response body. With the current logic, the
                // property values in the response meta will override the property values in the response
                // body if that happens. Currently not a practical issue, but something worth noting.
                promise.then(() => CurrentUserInterceptor.updateCurrentUserFromResponseMeta(response));

                // We wait on the promise created inside of isCurrentUserUpdateApiCall, because it seems like if we're making an
                // api call to update the current user, we should't resolve until the updates have been saved to IndexedDB. But,
                // the stuff that is saved from the response metadata feels like more of a side effect. Doesn't seem like we should
                // have to wait on that. All this is just theoretical. I'm trying to speed things up in https://trello.com/c/AXO3cLdd without
                // breaking things.
                return promise;
            },

            // public for testability
            updateCurrentUserFromResponseMeta(response) {
                let updatedProperties;
                try {
                    updatedProperties = response.data.meta.push_messages.current_user;
                    // eslint-disable-next-line no-empty
                } catch (e) {}

                if (
                    $rootScope.currentUser?.id &&
                    updatedProperties?.id &&
                    $rootScope.currentUser.id === updatedProperties.id
                ) {
                    return this.pushUpdatedPropertiesOntoCurrentUser(updatedProperties);
                }
                return $q.resolve();
            },

            // public for testability
            pushUpdatedPropertiesOntoCurrentUser(updatedProperties) {
                const oldProgramType = $rootScope.currentUser.programType;

                // copyAttrs will modify the object passed to it.  We need to make sure the
                // raw object goes to Dexie to prevent errors trying to save iguana objects to
                // the db
                const clonedUpdatedProperties = { ...updatedProperties };

                // We use copyAttrs so that iguana will instantiate embedded objects
                $rootScope.currentUser.copyAttrs(updatedProperties);

                $rootScope.currentUser.setContinueApplicationInMarketingFlag();

                // Ensure that a user who was logged in when they were deactivated
                // cannot retain access to the platform by signing them out. They
                // will not be able to sign back in until reactivated.
                if ($rootScope.currentUser.deactivated) {
                    // don't bother injecting unless we're signing out
                    $injector.get('SignOutHelper').signOut();
                }

                // if program type changed, we need to clear some caches
                if (!!oldProgramType && $rootScope.currentUser.programType !== oldProgramType) {
                    // I don't think it's possible for this codepath to run without these being available to inject
                    // because we're checking for the existence of a previously defined programType.
                    // But, just in case, wrap in a try-catch, since if they're not available we clearly don't
                    // yet have a cache to reset anyway, and can therefore ignore it and move on.
                    try {
                        const Playlist = $injector.get('Playlist');

                        // FIXME: learner_content_cache.js takes care of pushing new playlists into the cache,
                        // so we should in theory get rid of the Playlist cache.
                        // We used to clear the LearnerContentCache here, but it's unnecessary because the cache
                        // is watching currentUser.relevantCohort.id, which is what drives the change in the programType
                        Playlist.resetCache();
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }

                // setStreamBookmarks is also run in a watch on currentUser in
                // the initialization of the frontRoyalStore module.
                if (updatedProperties.favorite_lesson_stream_locale_packs) {
                    frontRoyalStore.setStreamBookmarks($rootScope.currentUser);
                }

                // NOTE: `pushUpdatedPropertiesOntoCurrentUser` gets called on response failure (see `responseError`
                // interceptor above), so we don't want to do `frontRoyalStore.storeCurrentUser(currentUser.asJson())`
                // here because doing that will persist the updated properties in the client db that the server failed
                // to persist, resulting in the current user stored on the client being out-of-sync with the user on
                // the server. So we intentionally use `updateCurrentUser` here.
                return frontRoyalStore.updateCurrentUser($rootScope.currentUser.id, clonedUpdatedProperties);
            },
        };

        return CurrentUserInterceptor;
    },
]);

angularModule.config([
    '$httpProvider',
    $httpProvider => {
        $httpProvider.interceptors.push('CurrentUserInterceptor');
    },
]);
