import { useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { formattedUserFacingDateTime } from 'DateHelpers';
import {
    type RefundEntitlement,
    REFUND_POLICY_BASED_ON_ASSESSMENT_COMPLETION,
    REFUND_POLICY_BASED_ON_CURRICULUM_COMPLETION,
    REFUND_POLICY_REFUND_DEADLINE,
} from 'RefundEntitlement';
import { type UserInAdmin } from 'Users';
import { Select } from 'FrontRoyalMaterialUiForm';
import { type TuitionContract } from 'TuitionContract/TuitionContract.types';
import { formatMoney } from 'FormatMoney';
import { isAssessmentPolicyWithCurriculumStarted } from 'RefundEntitlement/utils';
import { useErrorLogService } from 'ErrorLogging';

interface RefundDetailProps {
    refundEntitlement: RefundEntitlement;
    hasEnoughTransactions: boolean;
    tuitionContract: TuitionContract;
}

const contactEngineering = 'Something is wrong. Contact Engineering';

const RefundDetails = ({ refundEntitlement, hasEnoughTransactions, tuitionContract }: RefundDetailProps) => {
    const { tuitionRetainedAfterRefund, refundAmount, tuitionRefundAmount, paymentFeeRefundAmount, refundPercent } =
        refundEntitlement;
    const refundPercentage = refundPercent * 100;
    // This links to a description of the newer assessment-based refund policy, even though the older curriculum
    // completion policy might apply to this student's refund entitlement.  Since this is only used by admins,
    // I decided to ignore that.
    const refundResource = (
        <a
            href="https://support.quantic.edu/article/1274-tuition-refund-policy-class-of-january-2026-and-beyond"
            target="_blank"
            rel="noreferrer"
        >
            refund policy
        </a>
    );
    const refundPercentMessage = `They are entitled to a ${refundPercentage}% refund off of their original tuition amount according to the `;
    const automaticRefundMessage =
        'The refund cannot be issued automatically now because there are not enough refundable Stripe payments. ' +
        'Note that ACH and Klarna payments older than 180 days, or Affirm payments older than 120 days, are not considered refundable.';

    return (
        <>
            <p>
                {refundPercentMessage}
                {refundResource}.
            </p>
            {!hasEnoughTransactions && <p>{automaticRefundMessage}</p>}
            <p>Original tuition due: {formatMoney(tuitionContract.netRequiredPayment)}</p>
            <p>Tuition after refund: {formatMoney(Math.ceil(tuitionRetainedAfterRefund))}</p>
            <p>Tuition available to refund: {formatMoney(tuitionContract.refundableTuitionAmount)}</p>
            {paymentFeeRefundAmount > 0 && (
                <>
                    <p>Tuition refund amount: {formatMoney(tuitionRefundAmount)}</p>
                    <p>Payment fee refund amount: {formatMoney(paymentFeeRefundAmount)}</p>
                </>
            )}
            <p>Total refund amount: {formatMoney(refundAmount)}</p>
        </>
    );
};

interface IssueRefundSelectProps {
    user: UserInAdmin | undefined;
    refundEntitlement: RefundEntitlement | null;
    hasEnoughTransactions: boolean;
    needInput: boolean;
}

const refundOptions = [
    { value: true, label: 'yes' },
    { value: false, label: 'no' },
];

export const IssueRefundSelect = ({
    user,
    refundEntitlement,
    hasEnoughTransactions,
    needInput,
}: IssueRefundSelectProps) => {
    const { formState } = useFormContext();
    const ErrorLogService = useErrorLogService();
    const tuitionContract = useMemo(() => {
        if (user && refundEntitlement) {
            return user.tuitionContracts.find(contract => contract.id === refundEntitlement.tuitionContractId);
        }
        return undefined;
    }, [user, refundEntitlement]);

    const refundText = useMemo(() => {
        if (refundEntitlement && tuitionContract) {
            const { refundPolicy, metadata } = refundEntitlement;

            if (refundPolicy === REFUND_POLICY_BASED_ON_CURRICULUM_COMPLETION) {
                const { hasStartedCurriculum, numCurriculumElements, numCurriculumElementsCompleted } = metadata;
                const progressMessage =
                    numCurriculumElementsCompleted > 0
                        ? `The student has completed ${numCurriculumElementsCompleted} of ${numCurriculumElements} elements in the curriculum.`
                        : hasStartedCurriculum
                        ? 'The student has not completed any elements in the curriculum, but has started one.'
                        : 'The student has not started the curriculum.';

                return (
                    <>
                        <p>{progressMessage}</p>
                        <RefundDetails {...{ refundEntitlement, hasEnoughTransactions, tuitionContract }} />
                    </>
                );
            }

            if (refundPolicy === REFUND_POLICY_BASED_ON_ASSESSMENT_COMPLETION) {
                let progressMessage = '';
                let refundLevelMessage = '';
                if (isAssessmentPolicyWithCurriculumStarted(refundEntitlement)) {
                    const {
                        numCompletedAssessmentsForPolicy,
                        numAssessmentsForPolicy,
                        minNumCompletedAssessmentsForNextRefundLevel,
                    } = refundEntitlement.metadata;

                    progressMessage =
                        numCompletedAssessmentsForPolicy > 0
                            ? `The student has completed ${numCompletedAssessmentsForPolicy} of ${numAssessmentsForPolicy} assessments in the curriculum.`
                            : 'The student has started the curriculum, but has not completed any assessments.';

                    refundLevelMessage =
                        minNumCompletedAssessmentsForNextRefundLevel > 0
                            ? `The next threshold is at ${minNumCompletedAssessmentsForNextRefundLevel} ${
                                  minNumCompletedAssessmentsForNextRefundLevel === 1 ? 'assessment' : 'assessments'
                              }.`
                            : '';
                } else if (metadata.hasStartedCurriculum === false) {
                    progressMessage = 'The student is entitled to a full refund.';
                } else {
                    // We don't expect to be here so log an error if we do
                    ErrorLogService.notifyInProd(
                        new Error(
                            `Refund Entitlement with id: ${refundEntitlement.id} is assessment based with curriculum started but no other appropriate metadata`,
                        ),
                        null,
                    );
                    return contactEngineering;
                }

                return (
                    <>
                        <p>{progressMessage}</p>
                        {refundLevelMessage && <p>{refundLevelMessage}</p>}
                        <RefundDetails {...{ refundEntitlement, hasEnoughTransactions, tuitionContract }} />
                    </>
                );
            }

            if (refundPolicy === REFUND_POLICY_REFUND_DEADLINE) {
                const date = formattedUserFacingDateTime(1000 * refundEntitlement.metadata.refundDeadline);

                return <p>{`This student is entitled to a full refund until ${date}`}</p>;
            }
        }

        return null;
    }, [ErrorLogService, hasEnoughTransactions, refundEntitlement, tuitionContract]);

    return (
        <div className="issue-refund">
            {refundEntitlement ? (
                <>
                    {needInput && (
                        <Select
                            name="issueRefund"
                            label="Issue Refund"
                            fullWidth
                            disabled={
                                formState.isSubmitting || !hasEnoughTransactions || refundText === contactEngineering
                            }
                            options={refundOptions}
                        />
                    )}
                    {refundText}
                </>
            ) : (
                <p>The student is not entitled to a refund.</p>
            )}
        </div>
    );
};

export default IssueRefundSelect;
