import ClientStorage from 'ClientStorage';

export const PERFORMANCE_TEST_MODE = 'performanceTestMode';

const AUTOMATION_MODE_KEY = 'automationMode';

export function storeAutomationModeInLocalStorage() {
    const params = new URL(document.location).searchParams;
    const modeFromSearchParam = params.get(AUTOMATION_MODE_KEY);

    if (window.localStorage && modeFromSearchParam) {
        window.localStorage.setItem(AUTOMATION_MODE_KEY, modeFromSearchParam);
    }
}

export default function automationMode() {
    const mode = (ClientStorage && ClientStorage.getItem(AUTOMATION_MODE_KEY)) || null;

    const obj = { performanceTest: false };

    if (mode === 'performanceTestMode') {
        obj.performanceTest = true;
    }
    return obj;
}
