import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import type ReadmitExpelled from 'UserAdministrationTab/UserManagementActions/ProgramInclusion/ReadmitExpelled';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import EditDeferralReason from 'UserAdministrationTab/HelperComponents/EditDeferralReason';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { type ProgramInclusion } from 'ProgramInclusion';
import { type DeferralReason, OTHER_DEFERRAL_REASON_ID } from 'DeferralReason';
import { type ChangeCohortAttrs } from './ChangeCohort.types';
import './ChangeCohort.scss';

class ChangeCohort extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeCohort;
    static namespace = UserManagementActionNamespace.ProgramInclusion;
    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
        deferralReasonCategory: Yup.string().required(),
        deferralReasonId: Yup.string().required(),
        otherDeferralReasonTitle: Yup.string().when('deferralReasonId', {
            is: OTHER_DEFERRAL_REASON_ID,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
    });

    availableTargetCohortIds: string[];
    canSwitchToGlobalSection: boolean;
    deferralReasons: DeferralReason[];

    constructor(attrs: ChangeCohortAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
        this.canSwitchToGlobalSection = attrs.canSwitchToGlobalSection;
        this.deferralReasons = attrs.deferralReasons;
    }

    static FormFields = ({ action, record }: FormFieldProps<ProgramInclusion, ChangeCohort | ReadmitExpelled>) => (
        <div className="change-cohort-form">
            <ChangeCohortSelect
                availableTargetCohortIds={action.availableTargetCohortIds}
                maxDeferrableGraduationDate={record.maxDeferrableGraduationDate}
                includedCohortSectionId={record.includedCohortSectionId}
            />
            <EditDeferralReason
                deferralReason={record.pendingDeferralReason}
                deferralReasons={action.deferralReasons}
            />
        </div>
    );

    get description() {
        return (
            <>
                <p>
                    Use to change the student&apos;s cohort, supplying a reason for the change of cohort that best suits
                    their situation.
                </p>
                <p>
                    Only active (have not graduated) and future cohorts (starting within the next year) that offer the
                    student&apos;s included cohort section will be shown.
                </p>
                <p>Program changes are not supported in this action.</p>
                {this.canSwitchToGlobalSection && (
                    <p>
                        The student has 3 or fewer cohorts that match their section and is eligible to defer to global
                        sections.
                    </p>
                )}
            </>
        );
    }
}

export default ChangeCohort;
