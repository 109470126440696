export enum ApplicationActionType {
    RejectApplication = 'RejectApplication',
    UnrejectApplication = 'UnrejectApplication',
    DeleteApplication = 'DeleteApplication',
    ChangeProgram = 'ChangeProgram',
    ChangeSettings = 'ChangeSettings',
    ConsiderInDifferentCohort = 'ConsiderInDifferentCohort',
    OfferAdmission = 'OfferAdmission',
    OfferAdmissionInNewProgram = 'OfferAdmissionInNewProgram',
    OfferAdmissionAfterDeclinedOffer = 'OfferAdmissionAfterDeclinedOffer',
}

export enum InclusionActionType {
    ChangeCohort = 'ChangeCohort',
    Graduate = 'Graduate',
    ExpelOrWithdraw = 'ExpelOrWithdraw',
    ChangeProgram = 'ChangeProgram',
    ChangeRegistration = 'ChangeRegistration',
    ChangeScholarshipAfterRegistration = 'ChangeScholarshipAfterRegistration',
    ReadmitExpelled = 'ReadmitExpelled',
    ChangeSettings = 'ChangeSettings',
    MarkAsAuditing = 'MarkAsAuditing',
    UpdateBillingSituation = 'UpdateBillingSituation',
    GenerateDeferralLink = 'GenerateDeferralLink',
    IssueRefund = 'IssueRefund',
}

export enum OfferActionType {
    ChangeOfferDetails = 'ChangeOfferDetails',
    RegisterStudent = 'RegisterStudent',
    ReactivateDeclinedOffer = 'ReactivateDeclinedOffer',
    OfferAdmissionAfterDeclinedOffer = 'OfferAdmissionAfterDeclinedOffer',
    ExpireOffer = 'ExpireOffer',
    DeleteOffer = 'DeleteOffer',
    SwitchOfferedProgram = 'SwitchOfferedProgram',
}

export enum UserActionType {
    CreateUser = 'CreateUser',
    DeleteUser = 'DeleteUser',
    CancelDeletion = 'CancelDeletion',
    EditUserDetails = 'EditUserDetails',
    IncludeInProgram = 'IncludeInProgram',
    UpdateExecEdEligibilityBundle = 'UpdateExecEdEligibilityBundle',
    AllowReapplication = 'AllowReapplication',
    RegisterWithCorporateSponsor = 'RegisterWithCorporateSponsor',
}

export type UserManagementActionType = ApplicationActionType | InclusionActionType | OfferActionType | UserActionType;

export enum UserManagementActionNamespace {
    ProgramApplication = 'ProgramApplication',
    AdmissionOffer = 'AdmissionOffer',
    ProgramInclusion = 'ProgramInclusion',
    User = 'User',
}

export interface UserManagementActionAttrs {
    validationErrors: string[];
}

// In instantiateAction, in order for us to determine which class to use for each object sent down,
// we include the namespace and the actionType in each object.
export type ActionAttrsWithServerData = UserManagementActionAttrs & {
    namespace: UserManagementActionNamespace;
    actionType: UserManagementActionType;
};

export interface FormFieldProps<Record = never, Action = never> {
    record: [Record] extends [never] ? undefined : Record;
    action: [Action] extends [never] ? undefined : Action;
    userId: string;
}
