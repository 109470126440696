// I probably would have just put this in FrontRoyalStore, but doing so led to circular dependencies
export default async function storageSpaceAvailable() {
    try {
        if (navigator.storage?.estimate) {
            const quota = await navigator.storage.estimate();
            // quota.quota -> Maximum number of bytes available.
            // quota.usage -> Number of bytes used.
            const megabytesRemaining = Math.floor((quota.quota - quota.usage) / (1024 * 1024));
            return megabytesRemaining;
        }
        return undefined; // signifying we couldn't determine how much space is available
    } catch (err) {
        return undefined; // signifying we couldn't determine how much space is available
    }
}
