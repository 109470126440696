import { useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { type TuitionPlan } from 'TuitionPlan';
import { sortTuitionPlans } from 'UserAdministrationTab/utils';
import { Select } from 'FrontRoyalMaterialUiForm';

interface TuitionPlanSelectProps {
    name: string;
    tuitionPlans: TuitionPlan[];
    disabled: boolean;
}

const TuitionPlanSelect = ({ name, tuitionPlans, disabled }: TuitionPlanSelectProps) => {
    const { formState } = useFormContext();

    const orderedTuitionPlans = useMemo(() => tuitionPlans.sort(sortTuitionPlans), [tuitionPlans]);

    return (
        <Select
            name={name}
            label="Tuition Plan"
            fullWidth
            disabled={disabled || formState.isSubmitting}
            options={orderedTuitionPlans}
            optionLabel={tp => tp.name}
            optionValue={tp => tp.id}
        />
    );
};
export default TuitionPlanSelect;
