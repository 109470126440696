import UAParser from 'ua-parser-js';

const parser = new UAParser();

// Returns an object containing to parsed User-Agent info
export function getUserAgentInfo() {
    return parser.getResult();
}

// Returns an object containing to parsed User-Agent info
export function getSnakeCasedObject() {
    // parse various user agent properties
    const agentInfo = getUserAgentInfo();

    // agentInfo is guaranteed to have the top-level properties
    // and could theoretically change out from under a loaded version of the app
    if (agentInfo) {
        return {
            browser_name: agentInfo.browser.name,
            browser_major: agentInfo.browser.major,
            browser_version: agentInfo.browser.version,
            device_model: agentInfo.device.model,
            device_type: agentInfo.device.type,
            device_vendor: agentInfo.device.vendor,
            os_name: agentInfo.os.name,
            os_version: agentInfo.os.version,
            user_agent: agentInfo.ua,
        };
    }

    return null;
}

// Determines if the User-Agent device OS is Android
export function isAndroidDevice() {
    return parser.getOS().name === 'Android';
}

// Determines if the User-Agent device OS is iOS
export function isiOSDevice() {
    return parser.getOS().name === 'iOS';
}

// HACK: newer iPads run iPadOS vs. iOS. There doesn't seem to be
// a great way to detect a device running iPadOS, since the userAgent
// actually returns the following:
// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148"
// See also: https://github.com/faisalman/ua-parser-js/issues/387
// and https://developer.apple.com/forums/thread/119186
// and https://stackoverflow.com/a/58065241/4121912
// and https://stackoverflow.com/a/62979491/4121912
export function isiPadOSDevice() {
    return parser.getOS().name === 'Mac OS' && window?.navigator?.maxTouchPoints > 0;
}

export function isiOSoriPadOSDevice() {
    return isiOSDevice() || isiPadOSDevice();
}

export function isMobileDevice() {
    return parser.getDevice().type === 'mobile';
}

export function isMobileOrTabletDevice() {
    return isMobileDevice() || parser.getDevice().type === 'tablet';
}

export function isMobileSafari() {
    return parser.getBrowser().name === 'Mobile Safari';
}

export function isSafari() {
    return isMobileSafari() || parser.getBrowser().name === 'Safari';
}

export function isChrome() {
    return parser.getBrowser().name === 'Chrome';
}

export function isFacebookBrowser() {
    return parser.getBrowser().name === 'Facebook';
}

export function isInstagramBrowser() {
    return parser.getBrowser().name === 'Instagram';
}

export function isWeChatBrowser() {
    return parser.getBrowser().name === 'WeChat';
}

export function isThirdPartyWebView() {
    // See https://trello.com/c/GgQ9JisY
    // NOTE: It's not straightforward to generically detect WKWebView in iOS
    const webViewBrowserNames = ['Chrome WebView', 'Facebook', 'Instagram', 'LinkedIn', 'TikTok'];
    return (
        !window.CORDOVA &&
        ['iOS', 'Android'].includes(parser.getOS().name || '') &&
        webViewBrowserNames.includes(parser.getBrowser().name || '')
    );
}
