import { useMemo } from 'react';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Select } from 'FrontRoyalMaterialUiForm';
import FrontRoyalSpinner from 'FrontRoyalSpinner';

type ProgramSelectProps = {
    availableCohortIds: string[];
    name: string;
    multiple?: boolean;
    wrapSelectedOptions?: boolean;
};

function ProgramTypeSelect({
    availableCohortIds = [],
    name,
    wrapSelectedOptions,
    multiple = false,
}: ProgramSelectProps) {
    const { cohortsById } = useCohorts();
    const { formState } = useFormContext<{ programType: string }>();
    const availableProgramTypes = useMemo(
        // The filter is needed because cohortsById will be empty initially. Once it loads
        // we expect all of the cohorts ids to map to an entry in cohortsById
        () =>
            availableCohortIds
                .map(id => cohortsById[id])
                .filter(c => c)
                .map(c => c.programType),
        [cohortsById, availableCohortIds],
    );

    return (
        <>
            {availableProgramTypes.length === 0 && <FrontRoyalSpinner />}
            {availableProgramTypes.length > 0 && (
                <Select
                    name={name}
                    label="Programs"
                    fullWidth
                    disabled={formState.isSubmitting}
                    options={availableProgramTypes}
                    multiple={multiple}
                    wrapSelectedOptions={wrapSelectedOptions}
                />
            )}
        </>
    );
}

export default ProgramTypeSelect;
