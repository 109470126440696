import { AdminActionForm } from 'UserAdministrationTab/HelperComponents/AdminActionForm';
import { AdminInformation } from 'UserAdministrationTab/HelperComponents/AdminInformation';
import { useGetUserInAdmin } from 'UserAdministrationTab/redux';
import { type UserAdminItemCardProps } from './UserAdminItemCard.types';
import './UserAdminItemCard.styles.scss';

function UserAdminItemCard({ record, onUserUpdate, userId, showUserInfo = false }: UserAdminItemCardProps) {
    const { user } = useGetUserInAdmin(userId);

    return (
        <div className="aoi-record-card">
            <AdminActionForm record={record} userId={userId} onUserUpdate={onUserUpdate} />
            <AdminInformation user={user} showUserInfo={showUserInfo} record={record} />
        </div>
    );
}

export default UserAdminItemCard;
