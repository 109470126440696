import 'oclazyload';

import 'DialogModal/angularModule';
import 'ClientStorage/angularModule';
import 'EventLogger/angularModule';
import 'FrontRoyalUpload/angularModule';
import 'Institutions/angularModule';
import 'Lessons/angularModule';
import 'Navigation/angularModule';
import 'Translation/angularModule';

export default angular.module('FrontRoyal.Users', [
    'Iguana',
    'Translation',
    'Iguana.Adapters.RestfulIdStyle',
    'ClientStorage',
    'EventLogger',
    'FrontRoyal.Lessons', // Lessons module needed to change pref_locale
    'FrontRoyal.Navigation',
    'FrontRoyal.Upload',
    'oc.lazyLoad',
    'DialogModal',
    'FrontRoyalStore',
    'FrontRoyal.Institutions',
]);
