import { type OfflineModeManager } from 'OfflineMode';
import {
    SMARTLY_UPLOADS_DOMAIN,
    QUANTIC_CHINA_UPLOADS_DOMAIN,
    QUANTIC_APP_DOMAIN,
    QUANTIC_CHINA_APP_DOMAIN,
} from 'PedagoDomainConstants';
import { type TranslationHelperClass } from 'Translation';
import { type DialogModal } from 'DialogModal';
import { type AnyObject } from '@Types';
import { type SafeApply } from 'SafeApply';
import { type ConfigFactory } from 'FrontRoyalConfig';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import moize from 'moize';
import { ArticleSiteId } from 'Resources/Resources.types';
import { type InAppBrowser } from './navigationHelper.types';

export function navigationHelper($injector: ng.auto.IInjectorService) {
    const $location = $injector.get('$location');
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const safeApply = $injector.get<SafeApply>('safeApply');
    const $window = $injector.get('$window');
    const $route = $injector.get('$route') as { current: { pathParams: AnyObject & { url_prefix: string } } };
    const offlineModeManager = $injector.get<OfflineModeManager>('offlineModeManager');
    const DialogModal = $injector.get<DialogModal>('DialogModal');
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const ConfigFactory = $injector.get<ConfigFactory>('ConfigFactory');

    // Lazy instantiate so a spec/story can pass if it
    // 1. mocks out the injector
    // 2. doesn't actually need TranslationHelper
    const getTranslationHelper = moize(() => new TranslationHelper('navigation.navigation_helper'));

    function getUrlPrefix() {
        let urlPrefix;
        if ($window.CORDOVA && $window.CORDOVA.forcedUrlPrefix) {
            urlPrefix = $window.CORDOVA.forcedUrlPrefix;
        } else if ($route && $route.current && $route.current.pathParams) {
            urlPrefix = $route.current.pathParams.url_prefix;
        }

        urlPrefix = urlPrefix ? `${urlPrefix}/` : '';
        return urlPrefix;
    }

    function loadRoute(path: string, preserveParams?: boolean) {
        if (preserveParams) {
            $location.path(path);
        } else {
            $location.url(path);
        }

        safeApply($rootScope);
    }

    function loadUrl(url?: string | null, target = '_self', features: string | undefined = undefined) {
        if (!url) return undefined;

        if (url.startsWith('blob:') && $window.CORDOVA) {
            target = '_blank';
        } else if (target === '_blank' && $window.CORDOVA) {
            target = '_system';
        } else if (target === '_self' && $window.CORDOVA) {
            target = '_system';
        }

        if (url.slice(0, 1) === '/') {
            if (getUrlPrefix().startsWith('miyamiya')) {
                url = `https://miyamiya.org${url}`;
            } else {
                url = $window.ENDPOINT_ROOT + url;
            }
        }

        const chinaRegionMode = ConfigFactory.getSync(true)?.chinaRegionMode();
        if (chinaRegionMode) {
            // Replace uploads.smart.ly --> uploads.quantic.cn, app.quantic.edu --> app.quantic.cn in Quantic.cn context.
            url = url.replace(`//${SMARTLY_UPLOADS_DOMAIN}`, `//${QUANTIC_CHINA_UPLOADS_DOMAIN}`);
            url = url.replace(`//${QUANTIC_APP_DOMAIN}`, `//${QUANTIC_CHINA_APP_DOMAIN}`);
        }

        const navigate = () => {
            $window.open(url as string, target, features);
            safeApply($rootScope);
        };

        if (offlineModeManager.inOfflineMode) {
            const translationHelper = getTranslationHelper();
            DialogModal.confirm({
                confirmCallback() {
                    navigate();
                },
                title: translationHelper.get('you_are_offline'),
                text: translationHelper.get('still_want_to_navigate'),
                buttonContainerClass: 'center',
                confirmButtonClass: 'no-float',
                confirmButtonText: translationHelper.get('continue'),
                size: 'medium',
            });
        } else {
            navigate();
        }

        return undefined;
    }

    // Right now, this function is only used in the context of BioSig.
    // On web, it will load BioSig's UI in the current window.
    // On Cordova, it will load BioSig's UI in the inAppBrowser.
    // As such, I didn't feel the need to implement any special handling wrt.
    // offline mode, chian region mode, miya miya, etc. like the loadUrl function does.
    function loadUrlInAppBrowserIfAvailable(
        url: string,
        features: string,
        attachInAppBrowserEventListenerCallback?: (inAppBrowserReference: InAppBrowser) => void,
    ) {
        // Use the InAppBrowser if we have it
        if ($window.cordova?.InAppBrowser) {
            const inAppBrowserReference = $window.cordova.InAppBrowser.open(
                url as string,
                '_blank',
                features,
            ) as InAppBrowser;

            // Consumers of loadUrlInAppBrowserIfAvailable can supply a callback that only gets
            // run when using the InAppBrowser. This is useful for attaching event listeners to
            // the InAppBrowser reference.
            // See also BioSigMixin.
            if (attachInAppBrowserEventListenerCallback) {
                attachInAppBrowserEventListenerCallback(inAppBrowserReference);
            }

            safeApply($rootScope);
        }
        // Fallback to the default behavior
        else {
            loadUrl(url, '_self', features);
        }
    }

    function loadFaqUrl(relativeUrl: string, target = '_self', opts: { siteId?: string } & AnyObject = {}) {
        relativeUrl = relativeUrl.replace(/\/$/, '');

        // We use the site_id in the URL params on the server to figure out
        // what support site to redirect to. There may in instances, however, where
        // you want to link to a specific FAQ article that belongs to a particular
        // support site that isn't associated with the current user's relevant cohort.
        // In that case, you should use the article's unique path as the url rather
        // than the generalized alias version. Note, however, that the server will default
        // to the Quantic support site for unique FAQ article paths.
        //
        // NOTE: We expect there to be a currentUser here, but we're being defensive
        // just in case.

        const helpScoutArticleSiteId =
            opts.siteId ?? $rootScope.currentUser?.relevantCohort?.helpScoutArticleSiteId ?? ArticleSiteId.quantic;

        let url;
        try {
            const faqUrl = new URL(relativeUrl, $window.ENDPOINT_ROOT);
            faqUrl.searchParams.set('site_id', helpScoutArticleSiteId);
            url = faqUrl.pathname + faqUrl.search + faqUrl.hash;
        } catch (err) {
            // If the URL constructed above isn't a valid URL, we do our best
            // effort to replicate that logic here.
            const search = `?site_id=${helpScoutArticleSiteId}`;
            const fragmentIndex = relativeUrl.indexOf('#');
            if (fragmentIndex === -1) {
                url = relativeUrl + search;
            } else {
                const pathname = relativeUrl.slice(0, fragmentIndex);
                const hash = relativeUrl.slice(fragmentIndex);
                url = pathname + search + hash;
            }
        }

        loadUrl(url, target);
    }

    function goHome() {
        $rootScope.goHome();
        $rootScope.$apply();
    }

    return {
        getUrlPrefix,
        loadRoute,
        loadUrl,
        loadUrlInAppBrowserIfAvailable,
        loadFaqUrl,
        goHome,
    };
}
