import { formattedUserFacingDateTime } from 'DateHelpers';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Checkbox } from 'FrontRoyalMaterialUiForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserActionType,
    type UserManagementActionAttrs,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

import './DeleteUser.scss';

type DeleteUserAttrs = UserManagementActionAttrs & { deletionQueuedFor: number | null };

type FormFields = {
    deleteImmediately: boolean;
    modalVisible: boolean;
};

type ExecuteActionParams = Pick<FormFields, 'deleteImmediately'>;

export const DELETE_IMMEDIATELY_LABEL = 'Delete user immediately. Skip 4 day waiting period';

type WarningModalProps = {
    show: boolean;
    dismiss: () => void;
};

const WarningModal = ({ show, dismiss }: WarningModalProps) =>
    show ? (
        <>
            <div className="delete-user-modal-overlay" />
            <div className="delete-user-warning-modal">
                This action is irreversible and immediate. Be sure you wish to bypass the waiting period and delete this
                user immediately.
                <div>
                    <button type="button" onClick={dismiss}>
                        Dismiss
                    </button>
                </div>
            </div>
        </>
    ) : null;

class DeleteUser extends AbstractUserManagementAction {
    static actionType = UserActionType.DeleteUser;
    static namespace = UserManagementActionNamespace.User;
    deletionQueuedFor: number | null;

    get formValidationSchema() {
        return Yup.object().shape({
            deleteImmediately: Yup.boolean()
                .oneOf(this.deletionQueuedFor ? [true] : [true, false])
                .required(),
        });
    }

    get description() {
        return this.deletionQueuedFor ? (
            <>
                This user is already queued to be deleted at{' '}
                {formattedUserFacingDateTime(this.deletionQueuedFor * 1000)}.
            </>
        ) : (
            <>This will delete a user and all of their data after a 4 day waiting period.</>
        );
    }

    formatFormValues({ deleteImmediately }: FormFields): ExecuteActionParams {
        return { deleteImmediately };
    }

    constructor(attrs: DeleteUserAttrs) {
        super(attrs);
        this.deletionQueuedFor = attrs.deletionQueuedFor;
    }

    static FormFields = () => {
        const [showWarning, setShowWarning] = useState(false);
        const { watch, reset } = useFormContext<FormFields>();
        const deleteImmediately = watch('deleteImmediately');

        const onFieldClick = useCallback(() => {
            setShowWarning(true);
        }, []);

        const onModalClick = useCallback(() => {
            setShowWarning(false);
        }, []);

        useEffect(() => {
            reset({ deleteImmediately: false });
        }, [reset]);

        return (
            <>
                <WarningModal show={showWarning && deleteImmediately} dismiss={onModalClick} />
                <Checkbox name="deleteImmediately" label={DELETE_IMMEDIATELY_LABEL} onClick={onFieldClick} />
            </>
        );
    };
}

export default DeleteUser;
