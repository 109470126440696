import { useState, useCallback } from 'react';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
    id: string;
};

export function IdTableRow({ id }: Props) {
    const [tooltipText, setTooltipText] = useState('Copy to clipboard');

    const copyToClipboard = useCallback(
        async (text: string) => {
            await navigator.clipboard.writeText(text);
            setTooltipText('Copied!');
            setTimeout(() => {
                setTooltipText('Copy to clipboard');
            }, 2000);
        },
        [setTooltipText],
    );

    return (
        <div className="table-row">
            <div className="row-key">ID</div>
            <div className="row-val id-container">
                <span>{id}</span>
                <Tooltip title={tooltipText} arrow>
                    <IconButton onClick={() => copyToClipboard(id)}>
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}
