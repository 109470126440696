import { type ConfigFactory as ConfigFactoryType, AppEnvType } from 'FrontRoyalConfig';

function inDevMode(injector: angular.auto.IInjectorService) {
    let ConfigFactory;
    // Be resilient to tests that haven't mocked out ConfigFactory
    try {
        ConfigFactory = injector.get('ConfigFactory') as ConfigFactoryType;
    } catch (err) {
        if (err instanceof Error && err?.message?.match(/No entry/)) {
            return false;
        }
        throw err;
    }

    // In specs, we don't want to always mock out a ConfigFactory. If there is none,
    // assume we're not in devMode
    if (!ConfigFactory?.getConfig) {
        return false;
    }
    return ConfigFactory.getConfig().then(config => config.appEnvType() === AppEnvType.development);
}

export default async function logInDevMode(injector: angular.auto.IInjectorService, message: string) {
    if (await inDevMode(injector)) {
        // eslint-disable-next-line no-console
        console.log(message);
    }
}
