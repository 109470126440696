import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import {
    ApplicationActionType,
    type UserManagementActionAttrs,
    type FormFieldProps,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

interface ConsiderInDifferentCohortAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
}

class ConsiderInDifferentCohort extends AbstractUserManagementAction {
    static actionType = ApplicationActionType.ConsiderInDifferentCohort;
    static namespace = UserManagementActionNamespace.ProgramApplication;

    static description = (
        <p>
            Moves the interview and evaluation process to a different cohort. If the interview has already been
            conducted, the application should either be rejected or offered admission to a different cohort instead of
            using this action.
        </p>
    );

    availableTargetCohortIds: string[];

    static formValidationSchema = Yup.object().shape({ cohortId: Yup.string().required() });

    constructor(attrs: ConsiderInDifferentCohortAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    static FormFields = ({ action }: FormFieldProps<never, ConsiderInDifferentCohort>) => (
        <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
    );
}

export default ConsiderInDifferentCohort;
