import { type ProgramInclusion } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import { type ProgramApplication } from 'ProgramApplication';
import { recordType, AoiRecordType, type AoiRecord } from 'ProgramAoi';
import { type UserInAdmin } from 'Users';
import { UserInformation } from './UserInformation';
import { ProgramApplicationInformation } from './ProgramApplicationInformation';
import { ProgramInclusionInformation } from './ProgramInclusionInformation';
import { AdmissionOfferInformation } from './AdmissionOfferInformation';
import { type AdminInformationProps } from './AdminInformation.types';

const showUser = (show: boolean, user: UserInAdmin | undefined): user is UserInAdmin => show && !!user;

const getRecordType = (record: AoiRecord<true> | undefined): AoiRecordType | false =>
    record ? recordType(record) : false;

const isProgramApplication = (record: AoiRecord<true> | undefined): record is ProgramApplication<true> =>
    getRecordType(record) === AoiRecordType.ProgramApplication;

const isProgramInclusionInformation = (record: AoiRecord<true> | undefined): record is ProgramInclusion =>
    getRecordType(record) === AoiRecordType.ProgramInclusion;

const isAdmissionOffer = (record: AoiRecord<true> | undefined): record is AdmissionOffer =>
    getRecordType(record) === AoiRecordType.AdmissionOffer;

export const AdminInformation = ({ user, record, showUserInfo }: AdminInformationProps) => (
    <>
        {showUser(showUserInfo, user) && <UserInformation user={user} />}
        {isProgramApplication(record) && (
            <ProgramApplicationInformation
                programApplication={record}
                user={user}
                country={user?.country || undefined}
            />
        )}
        {isProgramInclusionInformation(record) && user && (
            <ProgramInclusionInformation programInclusion={record} user={user} />
        )}
        {isAdmissionOffer(record) && user && (
            <AdmissionOfferInformation admissionOffer={record} country={user.country || undefined} />
        )}
    </>
);

export default AdminInformation;
