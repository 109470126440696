import { type PaymentSituation } from 'PaymentSituation';
import { type TuitionContract } from 'TuitionContract';
import './PaymentReconciliationCard.scss';
import { formatMoney } from 'FormatMoney';

type PaymentReconciliationCardProps = {
    tuitionContract: TuitionContract;
    newPaymentSituation: PaymentSituation;
    cohortLabel?: string;
};

const renderMoney = (val: string | number) => (typeof val === 'number' ? formatMoney(val) : val);

export const PaymentReconciliationCard = ({
    tuitionContract,
    newPaymentSituation: { paymentInformation, canReconcilePaymentsAutomatically },
}: PaymentReconciliationCardProps) => {
    const initialBalance = paymentInformation.initialBalance;
    const resolutionLabel = initialBalance > 0 ? 'Refund' : 'Invoice';

    return (
        <div className="payment-reconciliation-card">
            <ul>
                <li>
                    <span className="label">Payment Made:</span>
                    <span className="value">{renderMoney(tuitionContract.netTuitionAmount)}</span>
                </li>
                {initialBalance !== 0 && (
                    <li>
                        <span className="label">{resolutionLabel}:</span>
                        <span className="value">{renderMoney(Math.abs(initialBalance))}</span>
                    </li>
                )}
                <li>
                    <span className="label">Next Payment:</span>
                    <span className="value">{renderMoney(paymentInformation.paymentAmountPerInterval)}</span>
                </li>
            </ul>
            {!canReconcilePaymentsAutomatically && initialBalance !== 0 && (
                <p>
                    The student&apos;s payment amount is changing, but we&apos;re unable to reconcile the difference
                    automatically. Make sure to {resolutionLabel.toLowerCase()} the student for the amount listed above
                    after saving this action.
                </p>
            )}
            {canReconcilePaymentsAutomatically && initialBalance !== 0 && (
                <p>
                    We are able to process this {resolutionLabel.toLowerCase()} automatically. When you save this
                    action, we will immediately {resolutionLabel.toLowerCase()} the student&apos;s payment method on
                    file the amount of {renderMoney(Math.abs(initialBalance))}.
                </p>
            )}
            {canReconcilePaymentsAutomatically && initialBalance > 0 && tuitionContract.netPaymentFeeAmount > 0 && (
                <p>Applicable payment fees will also be refunded.</p>
            )}
        </div>
    );
};

export default PaymentReconciliationCard;
