import angularModule from 'Users/angularModule/scripts/users_module';
import { formattedBrandName } from 'AppBranding';
import { targetBrand } from 'AppBranding';

angularModule.factory('StudentEmailAddress', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function StudentEmail() {
            this.setCollection('student_email_addresses');
            this.alias('StudentEmailAddress');
            this.setIdProperty('id');
            this.embeddedIn('user');

            Object.defineProperty(this.prototype, 'hasMailgunRoute', {
                get() {
                    return !!this.mailgun_route_id;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'brand', {
                get() {
                    return targetBrand(this.domain);
                },
                configurable: true,
            });

            return {
                brandName(format = 'short') {
                    return formattedBrandName(format, this.brand);
                },
            };
        });
    },
]);
