import 'FrontRoyalStore/angularModule';
import 'SafeApply/angularModule';

const angularModule = angular.module('ngStorableImage', ['safeApply', 'FrontRoyalStore']);

angularModule.directive('storableImage', [
    '$injector',
    $injector => {
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'A',
            link(scope, _elem, attrs) {
                attrs.$observe('storableImage', src => {
                    // FIXME: probably need some load error handling, possibly success

                    if (!src) {
                        attrs.$set('src', null);
                        return;
                    }

                    // See comment near frontRoyalStoreApi/getStorableImage for an explanation of which
                    // images get cached forever and which do not. The default has to be false because we want
                    // it to be false for lesson images, and those are hard to change because they are included in
                    // the lesson content.
                    const cacheForever = attrs.cacheForever === 'true';

                    frontRoyalStore.getDataUrlIfStored(src, cacheForever).then(url => {
                        attrs.$set('src', url);
                        safeApply(scope);
                    });
                });
            },
        };
    },
]);

export default angularModule;
