/**
 * All of our uploaded content images, avatars, etc. live in the `uploads.smart.ly` S3 bucket,
 * which lives in us-east-1.
 *
 * We have a Cloudflare worker running on `uploads.quantic.cn` that proxies all requests to
 * `uploads.smart.ly`. Nothing actually lives in `uploads.quantic.cn`, but we want browsers
 * in China to request assets from it anyway.
 *
 * The advantage of this approach is that browsers in China requesting assets from
 * `uploads.quantic.cn` get the benefit of reduced network hops between China and
 * us-east-1, as well as Cloudflare edge caching in China which makes subsequent
 * requests much faster.
 *
 * This feature can be turned off by removing ENABLE_QUANTIC_CN_IMAGE_REWRITING or setting it to false.
 *
 * See also: https://trello.com/c/6Bsw1RKn/88-feat-use-quanticcn-to-proxy-static-assets-in-uploadssmartly
 *           https://github.com/quanticedu/cloudflare-workers/tree/main/cn-uploads-proxy
 */
import { type Config } from 'FrontRoyalConfig';

const uploadsSmartlyCdn = 'uploads.smart.ly';
const uploadsQuanticCnCdn = 'uploads.quantic.cn';

export default function regionAwareImageCdnRewriter(url: string, config?: Config): string {
    // Being defensive against `config` being undefined solely for specs.
    return config?.enableQuanticCnImageRewriting() && config.chinaRegionMode()
        ? url.replace(uploadsSmartlyCdn, uploadsQuanticCnCdn)
        : url;
}
