import { useEffect, useMemo, useCallback } from 'react';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    UserActionType,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { Radio, Checkbox } from 'FrontRoyalMaterialUiForm';
import { useFormContext } from 'FrontRoyalReactHookForm';
import ProgramTypeSelect from 'UserAdministrationTab/HelperComponents/ProgramTypeSelect';
import * as Yup from 'yup';
import { useGetUserInAdmin } from 'UserAdministrationTab/redux';
import { ChangeScholarshipSelect } from 'UserAdministrationTab/HelperComponents';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import { type ProgramType } from 'Program';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { Chip } from '@mui/material';
import { type EligibleProgram } from 'ExecEdEligibility';
import { formattedUserFacingDateTime } from 'DateHelpers';
import {
    type UpdateExecEdEligibilityBundleAttrs,
    type FormFields,
    type ExecuteActionParams,
} from './UpdateExecEdEligibilityBundle.types';

class UpdateExecEdEligibilityBundle extends AbstractUserManagementAction {
    static actionType = UserActionType.UpdateExecEdEligibilityBundle;
    static namespace = UserManagementActionNamespace.User;

    availableCohortIds: string[];

    constructor(attrs: UpdateExecEdEligibilityBundleAttrs) {
        super(attrs);
        this.availableCohortIds = attrs.availableCohortIds;
    }

    static description = (<p>Use to update an existing, or create a new, Exec Ed Eligibility Bundle for a user.</p>);

    static formValidationSchema = Yup.object().shape({
        eligiblePrograms: Yup.array()
            .of(
                Yup.object().shape({
                    programType: Yup.string().required(),
                    scholarshipLevel: Yup.number().nullable(),
                }),
            )
            .min(1),
        recommendedProgramType: Yup.string().required(),
        activate: Yup.boolean(),
        deactivate: Yup.boolean(),
    });

    formatFormValues({
        selectedProgramTypes = [],
        scholarshipLevelsByProgramType = {},
        recommendedProgramType = null,
        activate = false,
        deactivate = false,
    }: FormFields): ExecuteActionParams {
        const eligiblePrograms = selectedProgramTypes.map(programType => {
            const level = scholarshipLevelsByProgramType[programType];
            return {
                programType,
                scholarshipLevel: level ? parseInt(level, 10) : null,
            } as EligibleProgram;
        });

        return {
            eligiblePrograms,
            recommendedProgramType,
            isActive: activate && !deactivate,
        };
    }

    static FormFields = ({
        userId,
        action: { availableCohortIds },
    }: FormFieldProps<never, UpdateExecEdEligibilityBundle>) => {
        const { user } = useGetUserInAdmin(userId);
        const activatedAt = user?.execEdEligibilityBundle?.activatedAt;
        const { cohortsById, cohortsLoading } = useCohorts();
        const availableCohorts = useMemo(
            // The filter is needed because cohortsById will be empty initially. Once it loads
            // we expect all of the cohorts ids to map to an entry in cohortsById
            () => availableCohortIds.map(id => cohortsById[id]).filter(c => c),
            [cohortsById, availableCohortIds],
        );
        const cohortForProgramType = useCallback(
            (programType: ProgramType) => availableCohorts.find(c => c.programType === programType),
            [availableCohorts],
        );

        const { reset, watch, setFieldValue } = useFormContext<FormFields>();
        const programTypeFormName = 'selectedProgramTypes';
        const scholarshipSelectFormName = 'scholarshipLevelsByProgramType';
        const recommendedProgramTypeFormName = 'recommendedProgramType';
        const selectedProgramTypes = watch(programTypeFormName);
        const scholarshipLevelsByProgramType = watch(scholarshipSelectFormName);
        const recommendedProgramType = watch(recommendedProgramTypeFormName);

        useEffect(() => {
            reset({
                selectedProgramTypes: user?.execEdEligibilityBundle?.eligiblePrograms.map(p => p.programType),
                scholarshipLevelsByProgramType: user?.execEdEligibilityBundle?.eligiblePrograms.reduce(
                    (acc, p) => ({ ...acc, [p.programType]: p.scholarshipLevel }),
                    {},
                ),
                recommendedProgramType: user?.execEdEligibilityBundle?.recommendedProgramType,
                activate: true,
                deactivate: false,
            });
        }, [user, reset]);

        useEffect(() => {
            if (selectedProgramTypes?.length > 0) {
                // When the first program is selected, mark it as recommended
                if (selectedProgramTypes.length === 1) {
                    setFieldValue(recommendedProgramTypeFormName, selectedProgramTypes[0]);
                }

                // If the recommended program is removed from the bundle, set the recommended program to the first
                // program in the list.
                if (recommendedProgramType && !selectedProgramTypes.includes(recommendedProgramType)) {
                    setFieldValue(recommendedProgramTypeFormName, selectedProgramTypes[0]);
                }
            }
        }, [selectedProgramTypes, setFieldValue, user, recommendedProgramType, scholarshipLevelsByProgramType]);

        return cohortsLoading ? (
            <FrontRoyalSpinner />
        ) : (
            <>
                <ProgramTypeSelect
                    availableCohortIds={availableCohortIds}
                    name={programTypeFormName}
                    multiple
                    wrapSelectedOptions
                />
                {selectedProgramTypes?.map(programType => (
                    <div key={`${programType}+container`}>
                        <div
                            className="tw-flex tw-items-center tw-justify-between tw-text-xs"
                            key={`${programType}+label`}
                        >
                            <Chip label={programType} size="small" variant="outlined" className="tw-mr-1" />
                            <Radio
                                value={programType}
                                name={recommendedProgramTypeFormName}
                                style={{
                                    marginBottom: 0,
                                    marginRight: 2,
                                    textTransform: 'uppercase',
                                }}
                                classes={{
                                    label: 'tw-text-xs tw-text-uppercase tw-font-semibold tw-tracking-wider tw-opacity-50',
                                }}
                                label={recommendedProgramType === programType ? 'Recommended' : 'Set as Recommended'}
                                size="small"
                                labelPlacement="start"
                            />
                        </div>
                        <div className="scholarship-wrapper" key={`${programType}+scholarship-field`}>
                            <ChangeScholarshipSelect
                                name={`${scholarshipSelectFormName}.${programType}`}
                                availableIds={cohortForProgramType(programType)?.availableScholarshipIds || []}
                                selectLevel
                                handleRemoveScholarship={() => {
                                    setFieldValue(`${scholarshipSelectFormName}.${programType}`, undefined);
                                }}
                            />
                        </div>
                    </div>
                ))}
                {activatedAt ? (
                    <Checkbox
                        name="deactivate"
                        label={`Deactivate (Previously activated on ${formattedUserFacingDateTime(
                            activatedAt * 1000,
                        )})`}
                    />
                ) : (
                    <Checkbox name="activate" label="Activate Now" />
                )}
            </>
        );
    };
}

export default UpdateExecEdEligibilityBundle;
