import {
    type ProgramInclusion,
    hasBeenExpelled,
    hasFailed,
    isCurrentOrHasCompletedProgram,
    isIncludedButNotCurrent,
    hasGraduatedWithHonors,
    hasGraduated,
    ProgramInclusionStatus,
    type CurriculumStatus,
    getCurriculumStatus as getProgramInclusionCurriculumStatus,
    isIncluded,
    blacklistedWillNotGraduateReasonIds,
} from 'ProgramInclusion';
import {
    type AoiRecord,
    AoiRecordType,
    recordType,
    isProgramApplication,
    isProgramInclusion,
    isAdmissionOffer,
    type UserProgramState,
    type UserProgramFieldState,
    SUCCESS_STATUSES,
    PENDING_STATUSES,
} from 'ProgramAoi';
import { type AdmissionOffer, offeredAdmission, declinedAdmissionOffer, AdmissionOfferStatus } from 'AdmissionOffer';
import { type TuitionContract, tuitionContractIsForOnetimePurchase } from 'TuitionContract';
import { type ProgramApplication, isPendingProgramApplication, isRejectedProgramApplication } from 'ProgramApplication';
import { type AnyObject, type Nullable } from '@Types';
import { isFullScholarship } from 'Scholarship';
import { getProgramConfigValue, type ProgramType } from 'Program';
import { forVerificationPeriod } from 'UserIdVerification';
import {
    type Cohort,
    activeIdVerificationPeriodIsPastDue,
    getActiveIdVerificationPeriodIndex,
    getActiveIdVerificationPeriod as getActiveIdVerificationPeriodForCohort,
    pastEnrollmentDeadline,
} from 'Cohorts';
import { type SignableDocument } from 'SignableDocument';
import transformKeyCase from 'Utils/transformKeyCase';
import { isIguanaObject } from 'IguanaHelpers';
import { type ExecEdEligibilityBundle, type EligibleProgram, ProgramPriorityWeights } from 'ExecEdEligibility';
import { type CurrentUser, type CurrentUserIguanaObject, type BaseUser } from './types';
import { GraduationStatus } from './Users.types';

type RecordFromRecordType<T extends string> = T extends AoiRecordType.ProgramApplication
    ? ProgramApplication
    : T extends AoiRecordType.AdmissionOffer
    ? AdmissionOffer
    : T extends AoiRecordType.ProgramInclusion
    ? ProgramInclusion
    : T extends null
    ? AoiRecord
    : never;

// See comment above parseFilters
type StandardAoiFilters = {
    userProgramState?: UserProgramState;
    programType?: ProgramType;
};

export const USER_ATTRS_FOR_CASE_TRANSFORM = [
    'admission_offers',
    'pref_user_program_field_state_id',
    'program_applications',
    'program_family_applications',
    'program_family_form_data',
    'program_inclusions',
    'tuition_contracts',
    'refund_entitlements',
    'user_program_field_states',
    'user_program_states',
    'user_program_states',
];

// Many of the functions in this file take a StandardAoiFilters argument.
// * When the filters are not provided, the item that is relevant to the pref user program state should be returned
// * When a userProgramState is included in the filters, the item that is relevant to that user program state should be returned
// * When a programType is included in the filters, the item that is relevant to the user program state for that program type should be returned
//
// parseFilters takes the object with an optional programType and an optional userProgramState and returns an object with a userProgramState that
// can be used to find the correct item to return.
function parseFilters(
    user: BaseUser | null,
    filters: StandardAoiFilters,
): { userProgramState?: UserProgramState; programType?: ProgramType; cohortId?: string };
function parseFilters(
    user: BaseUser | null,
    filters: StandardAoiFilters,
): { userProgramState?: UserProgramState; programType?: ProgramType };

function parseFilters(
    user: BaseUser | null,
    { userProgramState, programType }: StandardAoiFilters,
): { userProgramState?: UserProgramState } {
    if (!user) return {};

    let returnUserProgramState: UserProgramState | null = userProgramState || null;
    if (programType && !returnUserProgramState)
        returnUserProgramState = user.userProgramStates.find(ups => ups.programType === programType) || null;

    if (!returnUserProgramState) {
        const userProgramFieldState =
            user.userProgramFieldStates.find(upfs => upfs.id === user.prefUserProgramFieldStateId) || null;
        returnUserProgramState =
            (userProgramFieldState &&
                user.userProgramStates.find(ups => ups.id === userProgramFieldState.userProgramStateId)) ||
            null;
    }

    if (returnUserProgramState && programType && returnUserProgramState.programType !== programType) {
        returnUserProgramState = null;
    }

    if (returnUserProgramState) return { userProgramState: returnUserProgramState };

    return {};
}

// user.relevantCohort is the preferred way to access the user's relevant cohort
// as it returns an instance of the Iguana cohort class. This is the lower-level
// function to get the camelCased cohort attrs from the user's currently active UPS.
export function getCohort(user: BaseUser | null, filters: StandardAoiFilters = {}): Cohort | null {
    return getUserProgramState(user, filters)?.cohort || null;
}

function getRelevantUserProgramFieldStates(user: BaseUser | null) {
    return user?.userProgramFieldStates?.filter(upfs => upfs.relevant) ?? [];
}

// See also `relevant_user_program_states` on the server.
export function getRelevantUserProgramStates(user: BaseUser | null) {
    const upsIds = getRelevantUserProgramFieldStates(user).map(upfs => upfs.userProgramStateId);
    return user?.userProgramStates?.filter(ups => upsIds.includes(ups.id)) ?? [];
}

export function getRelevantCohorts(user: BaseUser | null) {
    return getRelevantUserProgramStates(user).map(ups => ups.cohort);
}

export function getUserProgramFieldState(
    user: Nullable<BaseUser>,
    { userProgramState, programType }: StandardAoiFilters = {},
): UserProgramFieldState | null {
    if (!user) {
        return null;
    }

    if (userProgramState) {
        return user.userProgramFieldStates.find(upfs => upfs.userProgramStateId === userProgramState.id) || null;
    }

    if (programType) {
        const ups = getUserProgramState(user, { programType });
        if (!ups) {
            return null;
        }

        return user.userProgramFieldStates.find(upfs => upfs.userProgramStateId === ups.id) || null;
    }

    return user.userProgramFieldStates.find(upfs => upfs.id === user.prefUserProgramFieldStateId) || null;
}

export function getUserProgramState(user: BaseUser | null, filters: StandardAoiFilters = {}): UserProgramState | null {
    return parseFilters(user, filters).userProgramState || null;
}

export function getAllAoiRecords<T extends boolean>(user: BaseUser<T>) {
    return [...user.programApplications, ...user.admissionOffers, ...user.programInclusions];
}

/**
 * @param {object} filters - An optional object containing filters. When `cohortId` is a property on `filters`, `StandardAoiFilters` are ignored.
 */
export function getProgramInclusion(
    user: BaseUser | null,
    filters: StandardAoiFilters & { cohortId?: string } = {},
): ProgramInclusion | null {
    if (filters.cohortId) return user?.programInclusions?.find(pi => pi.cohortId === filters.cohortId) || null;

    return getAoiRecord(user, AoiRecordType.ProgramInclusion, filters);
}

export function getProgramApplication(
    user: BaseUser | null,
    filters: StandardAoiFilters = {},
): ProgramApplication | null {
    return getAoiRecord(user, AoiRecordType.ProgramApplication, filters);
}

export function getAdmissionOffer(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    return getAoiRecord(user, AoiRecordType.AdmissionOffer, filters);
}

function getAoiRecordForUserProgramState(user: BaseUser | null, userProgramState: UserProgramState) {
    if (!userProgramState) return null;

    if (userProgramState?.programApplicationId) {
        return user!.programApplications?.find(pa => pa?.id === userProgramState.programApplicationId) ?? null;
    }

    if (userProgramState?.admissionOfferId) {
        return user!.admissionOffers?.find(ao => ao?.id === userProgramState.admissionOfferId) ?? null;
    }

    if (userProgramState?.programInclusionId) {
        return user!.programInclusions?.find(pi => pi?.id === userProgramState.programInclusionId) ?? null;
    }

    return null;
}

export function getAoiRecordFromUserProgramState(user: BaseUser, userProgramState: UserProgramState) {
    if (userProgramState?.programApplicationId) {
        return user.programApplications?.find(pa => pa?.id === userProgramState.programApplicationId) ?? null;
    }

    if (userProgramState?.admissionOfferId) {
        return user.admissionOffers?.find(ao => ao?.id === userProgramState.admissionOfferId) ?? null;
    }

    if (userProgramState?.programInclusionId) {
        return user.programInclusions?.find(pi => pi?.id === userProgramState.programInclusionId) ?? null;
    }

    return null;
}

// equivalent to the pre_accepted cohort application state
export function getHasPendingAdmissionOfferOrIsNotYetCurrent(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    const record = getAoiRecord(user, null, filters);
    if (!record || isProgramApplication(record)) return false;

    if (isAdmissionOffer(record)) return offeredAdmission(record);

    // If we get this far, we should be dealing with a program inclusion.
    return isIncludedButNotCurrent(record);
}

// equivalent to the 'accepted' cohort application state
export function getIsCurrentOrHasCompletedActiveProgram(
    user: BaseUser | null,
    filters: StandardAoiFilters = {},
): boolean {
    return isCurrentOrHasCompletedProgram(getProgramInclusion(user, filters));
}

// Equivalent to "either pre_accepted or accepted" cohort application state
export function getHasOfferOrIncludedOrCompleted(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    return (
        getHasPendingAdmissionOfferOrIsNotYetCurrent(user, filters) ||
        getIsCurrentOrHasCompletedActiveProgram(user, filters)
    );
}

// This equates to the Cohort Application 'rejected' state
export const getNotJoiningProgram = (user: BaseUser | null, filters: StandardAoiFilters = {}): boolean => {
    const programApplication = getAoiRecord(user, AoiRecordType.ProgramApplication, filters);
    const admissionOffer = getAoiRecord(user, AoiRecordType.AdmissionOffer, filters);
    return isRejectedProgramApplication(programApplication) || declinedAdmissionOffer(admissionOffer);
};

export function getIsNotJoiningProgramOrHasBeenExpelled(
    user: BaseUser | null,
    filters: StandardAoiFilters = {},
): boolean {
    return getNotJoiningProgram(user, filters) || hasBeenExpelled(getProgramInclusion(user, filters));
}

// A TuitionContract is active in the sense that it belongs to an active ProgramInclusion.
export function getTuitionContract(user: BaseUser | null, filters?: StandardAoiFilters): TuitionContract | null {
    // Get the user's active ProgramInclusion
    const programInclusion = getProgramInclusion(user, filters);
    return getTuitionContractForProgramInclusion(user, programInclusion);
}

export function getRefundEntitlement(user: BaseUser | null, filters?: StandardAoiFilters) {
    const tuitionContract = getTuitionContract(user, filters);

    if (!tuitionContract) return null;

    return user?.refundEntitlements.find(re => re.tuitionContractId === tuitionContract.id) || null;
}

export function getTuitionContractForProgramInclusion(
    user: BaseUser | null,
    programInclusion: ProgramInclusion | null,
): TuitionContract | null {
    if (!user || !programInclusion) return null;
    return user?.tuitionContracts.find(tc => tc.id === programInclusion.tuitionContractId) || null;
}

function getAoiRecordsForType<T extends AoiRecordType>(user: BaseUser, aoiRecordType: T) {
    switch (aoiRecordType) {
        case AoiRecordType.ProgramApplication:
            return user.programApplications as RecordFromRecordType<T>[];
        case AoiRecordType.AdmissionOffer:
            return user.admissionOffers as RecordFromRecordType<T>[];
        case AoiRecordType.ProgramInclusion:
            return user.programInclusions as RecordFromRecordType<T>[];
        default:
            throw new Error(`Invalid type: ${aoiRecordType}`);
    }
}

/**
 *
 * @param {BaseUser} user - A user with AOI records
 * @param {UserProgramState} userProgramState - the user program state where we should find the record
 * @param {AoiRecordType} targetAoiRecordType - The AOI record type of the desired AOI record
 * @param {string} [nextAoiRecordId] - The id of the next AOI join record that will be
 *      used to drill down to the desired AOI record (only used internally for recursive search)
 * @param {AoiRecordType} [nextAoiRecordType] - The AOI record type of the next AOI join record
 *      that will be used to drill down to the desired AOI record (only used internally for recursive search)
 */
function internalGetAoiRecord<T extends AoiRecordType>(
    user: BaseUser,
    userProgramState: UserProgramState,
    targetAoiRecordType?: T | null,
    nextAoiRecordId?: string,
    nextAoiRecordType?: AoiRecordType,
): RecordFromRecordType<T> | null {
    let record;

    // When internalGetAoiRecord is called initially, we grab the record from the userProgramState
    if (!nextAoiRecordId && !nextAoiRecordType) record = getAoiRecordForUserProgramState(user, userProgramState);

    // When internalGetAoiRecord is called recursively, an nextAoiRecordType will be passed in
    if (nextAoiRecordId && nextAoiRecordType) {
        record = getAoiRecordsForType(user, nextAoiRecordType).find(r => r.id === nextAoiRecordId);
    }

    if (!record) return null;

    // If we've found the desired record, we can stop the recursive search.
    if (recordType(record) === targetAoiRecordType) return record as RecordFromRecordType<T>;
    if (record && !targetAoiRecordType) return record as RecordFromRecordType<T>;

    // If we're starting with a ProgramInclusion, we then
    // drill down to find the associated AdmissionOffer...
    if (isProgramInclusion(record)) {
        return internalGetAoiRecord(
            user,
            userProgramState,
            targetAoiRecordType,
            record.admissionOfferId,
            AoiRecordType.AdmissionOffer,
        );
    }

    // If we're working with an AdmissionOffer, then we
    // drill down to find the associated ProgramApplication...
    if (isAdmissionOffer(record)) {
        return internalGetAoiRecord(
            user,
            userProgramState,
            targetAoiRecordType,
            record.programApplicationId,
            AoiRecordType.ProgramApplication,
        );
    }

    return null;
}

/**
 * Finds the desired AOI record for the given user's active program. The returned record is not necessarily
 * itself in an active state. For example, if a user has an active program inclusion and you call
 * `aoiRecordForActiveProgram(user, AoiRecordType.AdmissionOffer)`, you will get the admission offer for the
 * active program inclusion, even though that admission offer is not itself active.
 *
 * @param {BaseUser} user - A user with AOI records
 * @param {AoiRecordType} aoiRecordType - The AOI record type of the desired AOI record
 * @param {string} [aoiJoinRecordId] - The id of the next AOI join record that will be
 *      used to drill down to the desired AOI record (only used internally for recursive search)
 * @param {AoiRecordType} [aoiJoinRecordType] - The AOI record type of the next AOI join record
 *      that will be used to drill down to the desired AOI record (only used internally for recursive search)
 */
export function getAoiRecord<T extends AoiRecordType>(
    user: BaseUser | null,
    aoiRecordType?: T | null,
    filters: StandardAoiFilters = {},
): RecordFromRecordType<T> | null {
    const { userProgramState } = parseFilters(user, filters);
    if (!userProgramState || !user) return null;

    // We delegate to `internalGetAoiRecordForActiveProgram` just to make it clear that the
    // aoiJoinRecordId and aoiJoinRecordType are never expected to be passed in from outside
    return internalGetAoiRecord(user, userProgramState, aoiRecordType);
}

// This is true for people who have previously applied and either can re-apply now or will be able to
// reapply at a later date.
// logic is duplicated on server in UserProgramState#can_eventually_reapply? (although in that case
// it is defined on the UserProgramState rather than the user, like it is here. FIXME: We should refactor it
// here to match. see https://trello.com/c/ubQkDaXZ)
// This function does not accept StandardFilters because the business rules around who can reapply are relevant
// to a user, not a program (at least today)
export function getCanEventuallyReapply(user: BaseUser, filters: StandardAoiFilters = {}) {
    // If you already have a record in a pending or success state, you can't re-apply
    if (getPendingOrSuccessAoiRecord(user, filters)) return false;

    // If you have never applied, then it is impossible to RE-apply
    if (!getAoiRecord(user, null, filters)) return false;

    const programInclusion = getProgramInclusion(user, filters);
    if (!programInclusion?.willNotGraduateReason) return true;

    // If you have failed a program, you may not re-apply,
    // although if you are marked will_not_graduate for some other reason, you may still re-apply...
    if (hasFailed(programInclusion)) return false;

    // ...unless it's one of the blacklisted reasons
    if (blacklistedWillNotGraduateReasonIds.includes(programInclusion.willNotGraduateReason.id)) return false;

    return true;
}

// See note above getCanEventuallyReapply about why this function does not accept StandardFilters
export function getReapplicationDate(user: BaseUser | null, filters?: StandardAoiFilters) {
    const currentReapplicationTimestamp = getUserProgramFieldState(user, filters)?.reapplicationDate;
    return currentReapplicationTimestamp ? new Date(1000 * currentReapplicationTimestamp) : null;
}

export function getHasPendingProgramApplication(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    return isPendingProgramApplication(getProgramApplication(user, filters));
}

export function getHasFailedProgram(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    return hasFailed(getProgramInclusion(user, filters));
}

export function getPendingOrSuccessAoiRecord(
    user: BaseUser | null,
    filters: StandardAoiFilters = {},
): AoiRecord | null {
    const record = getAoiRecord(user, null, filters);
    if (!record) return null;
    if ((SUCCESS_STATUSES as unknown as string[]).includes(record.status)) return record;
    if ((PENDING_STATUSES as unknown as string[]).includes(record.status)) return record;
    return null;
}
// We have currentlyPreventedFromSubmittingApplication instead of canCurrentlySubmitProgramApplication because
// UserWithAoiRecords does not have enough information to find out if a user is the kind of user who is allowed
// to submit applications in the first place (are they an institutional user, or a miyamiya user, for example?).
// See how this is used in the User Iguana model's canSubmitProgramApplications and canCurrentlySubmitProgramApplication
// Eventually, once we've moved some logic out of schedulable and stuff, we might decide to set this up in a different way.
// See note above getCanEventuallyReapply about why this function does not accept StandardFilters
export function getCurrentlyPreventedFromSubmittingApplication(user: BaseUser | null, filters?: StandardAoiFilters) {
    // If you already have an active record, you can't submit a new application
    if (getPendingOrSuccessAoiRecord(user, filters)) return true;

    // If you've failed a program, you can't submit a new application
    if (getHasFailedProgram(user, filters)) return true;

    const programInclusion = getProgramInclusion(user, filters);
    if (
        programInclusion?.willNotGraduateReason &&
        blacklistedWillNotGraduateReasonIds.includes(programInclusion.willNotGraduateReason.id)
    ) {
        return true;
    }

    // If you've been rejected or declined an admission offer, you need to wait until
    // the reapplication date to submit a new application
    const reapplicationDate = getReapplicationDate(user, filters);
    if (reapplicationDate && reapplicationDate > new Date()) return true;

    return false;
}

export function getGraduationStatus(
    user: BaseUser | null,
    filters: StandardAoiFilters & { cohortId?: string } = {},
): Nullable<GraduationStatus> {
    if (!user) return null;

    const programInclusion = getProgramInclusion(user, filters);

    if (!programInclusion) return null;

    if (hasGraduatedWithHonors(programInclusion)) return GraduationStatus.honors;

    if (hasGraduated(programInclusion)) return GraduationStatus.graduated;

    if (hasFailed(programInclusion)) return GraduationStatus.failed;

    return GraduationStatus.pending;
}

export function getCurriculumStatus(user: BaseUser | null, filters: StandardAoiFilters = {}): CurriculumStatus | null {
    const programInclusion = getProgramInclusion(user, filters);
    const cohort = getCohort(user);
    if (!programInclusion || !cohort) return null;
    return getProgramInclusionCurriculumStatus(programInclusion, cohort);
}

export function getEnrollmentAgreement(
    user: BaseUser | null,
    filters: StandardAoiFilters = {},
): SignableDocument | null {
    if (!user) return null;
    const tuitionContract = getTuitionContract(user, filters);
    if (!tuitionContract?.enrollmentAgreementId) return null;

    return user.signableDocuments.find(document => document.id === tuitionContract.enrollmentAgreementId) || null;
}

export function getActiveEnrollmentAgreementSigningLink(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    const document = getEnrollmentAgreement(user, filters);
    return document && !document.signedAt ? document.signingLink : null;
}

export function getHasActiveEnrollmentAgreementSigningLink(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    return !!getActiveEnrollmentAgreementSigningLink(user, filters);
}

export function hasSignedEnrollmentAgreement(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    return !!getEnrollmentAgreement(user, filters)?.signedAt;
}

export function getHasActivatedExecEdEligibilityBundle(user: BaseUser | null): boolean {
    return !!user?.execEdEligibilityBundle?.activatedAt;
}

export function getFilteredExecEdEligibilityBundle(
    bundle: ExecEdEligibilityBundle,
    admissionOffers: AdmissionOffer[] | [],
    relevantCohorts: Cohort[] | [],
): ExecEdEligibilityBundle | null {
    if (!admissionOffers.length) return bundle;

    // Filter out any programs for which the user has already registered or has an offer that's
    // expired. Limit to offers with cohorts that are currently promoted, i.e. expired (declined) and accepted
    // offers for past cycles shouldn't affect this filter.
    const eligiblePrograms = bundle.eligiblePrograms.filter(
        program =>
            !admissionOffers
                .filter(
                    ao =>
                        [
                            AdmissionOfferStatus.AcceptedAdmissionOffer,
                            AdmissionOfferStatus.DeclinedAdmissionOffer,
                        ].includes(ao.status) && relevantCohorts.map(cohort => cohort.id).includes(ao.cohortId),
                )
                .map(ao => ao.programType)
                .includes(program.programType),
    );

    if (!eligiblePrograms.length) return null;

    // If the program with the recommendedProgramType is not in the filtered eligiblePrograms,
    // we need to pick a new one using the program priority weights.
    const recommendedProgramType =
        eligiblePrograms.find(program => program.programType === bundle.recommendedProgramType)?.programType ||
        getHighestPriorityEligibleExecEdProgramType(eligiblePrograms);

    return {
        ...bundle,
        recommendedProgramType,
        eligiblePrograms,
    };
}

function getHighestPriorityEligibleExecEdProgramType(
    eligiblePrograms: ExecEdEligibilityBundle['eligiblePrograms'],
): ProgramType {
    const eligibleProgramsWithPriority = eligiblePrograms.map((program: EligibleProgram) => ({
        ...program,
        priority: ProgramPriorityWeights[program.programType as keyof typeof ProgramPriorityWeights],
    }));
    return eligibleProgramsWithPriority.reduce((highestPriority, program) =>
        program.priority > highestPriority.priority ? program : highestPriority,
    ).programType;
}

export function getTilaDisclosure(user: BaseUser | null, filters: StandardAoiFilters = {}): SignableDocument | null {
    if (!user) return null;
    const tuitionContract = getTuitionContract(user, filters);
    if (!tuitionContract?.tilaDisclosureId) return null;

    return user.signableDocuments.find(document => document.id === tuitionContract.tilaDisclosureId) || null;
}

export function getHasUnsignedTilaDisclosure(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    const disclosure = getTilaDisclosure(user, filters);

    // HACK: Students in cycles 52 & 53 were required to sign a TILA disclosure,
    // but we only have records in our db of signed TILA disclosures i.e. users
    // that haven't signed their TILA disclosure do not have a record in our db.
    // See also the requiresTilaDisclosure method.
    // FIXME: This hack can be removed once all cohorts in cycles 52 and 53 have graduated
    // or when we're confident that no students will be deferring into these cohorts anymore.
    if (!disclosure && getRequiresTilaDisclosure(user, filters)) return true;

    return !!disclosure && !disclosure.signedAt;
}

export function getHasSignedTilaDisclosure(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    return !!getTilaDisclosure(user, filters)?.signedAt;
}

export function getRequiresTilaDisclosure(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    const tuitionContract = getTuitionContract(user, filters);
    if (
        isFullScholarship(tuitionContract?.cumulativeScholarship) ||
        tuitionContractIsForOnetimePurchase(tuitionContract)
    ) {
        return false;
    }

    const cohort = getCohort(user, filters);
    if (!cohort) return false;

    return !!(
        getProgramConfigValue(cohort.programType, 'requiresTILADisclosure') &&
        getProgramInclusion(user)?.requiresTilaDisclosure
    );
}

export function getTilaDisclosureSigningLink(user: BaseUser | null, filters: StandardAoiFilters = {}): string | null {
    return getTilaDisclosure(user, filters)?.signingLink || null;
}

export function getHasTilaDisclosureSigningLink(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    return !!getTilaDisclosureSigningLink(user, filters);
}

export function getHasPastDueTilaDisclosure(user: BaseUser | null, filters: StandardAoiFilters = {}): boolean {
    const cohort = getCohort(user, filters);
    if (!cohort) return false;
    return (
        getHasUnsignedTilaDisclosure(user, filters) &&
        pastEnrollmentDeadline(cohort) &&
        getRequiresTilaDisclosure(user, filters)
    );
}

export function getPastDueForIdVerification(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    const cohort = getCohort(user, filters);
    if (!cohort) return false;
    return activeIdVerificationPeriodIsPastDue(cohort) && getUnverifiedForActiveIdVerificationPeriod(user, filters);
}

export function getUnverifiedForActiveIdVerificationPeriod(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    if (!user) return false;
    const cohort = getCohort(user, filters);

    if (!cohort) return false;
    const idVerificationPeriodIndex = getActiveIdVerificationPeriodIndex(cohort);

    if (idVerificationPeriodIndex == null) return false;

    if (getProgramInclusion(user, filters)?.status !== ProgramInclusionStatus.Included) return false;

    // In uncommon situations, a user can end up with multiple userIdVerifications
    // records. This can happen if they defer into a cohort and then again back into
    // the original cohort. We should just check that the user has a verification for
    // the current period in `userIdVerifications`.
    // See also: https://trello.com/c/vaRO3Dv9
    return !user.userIdVerifications.find(userIdVerification =>
        forVerificationPeriod(userIdVerification, cohort, idVerificationPeriodIndex),
    );
}

export function getActiveIdVerificationPeriod(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    const cohort = getCohort(user, filters);
    return cohort ? getActiveIdVerificationPeriodForCohort(cohort) : null;
}

export function getIncludedOrGraduatedProgramInclusion(user: BaseUser | null, filters: StandardAoiFilters = {}) {
    const programInclusion = getProgramInclusion(user, filters);
    if (isIncluded(programInclusion) || hasGraduated(programInclusion)) return programInclusion;
    return null;
}

export const getActiveInstitution = (user: BaseUser | null) => {
    if (!user) return null;
    if ('active_institution' in user) return user.active_institution;
    return user.activeInstitution;
};

export const convertCurrentUserFromIguana = (user: CurrentUserIguanaObject | null): CurrentUser | null => {
    if (!user) return null;

    if (isIguanaObject(user)) {
        const keysToTransform = [
            // these fields are required by the CamelCasedUser type
            'active_institution',
            'enable_front_royal_store',
            'extend_exam_time',
            'fallback_program_type',
            'groups',
            'ineligible_for_exec_ed_bundle',
            'institutions',
            'mba_content_lockable',
            'pref_locale',
            'roles',

            // These fields are required by the CurrentUser type
            'has_seen_ai_welcome_message',
            'has_seen_resources_tab',
            'prefer_strict_smartcase_score',
            'messaging_enabled',
            'sendbird_access_token',
        ];
        const mixedCaseUser = transformKeyCase(user.asJson(), {
            to: 'camelCase',
            keys: keysToTransform,
        }) as typeof user;

        const camelCaseUser = Object.entries(mixedCaseUser).reduce((userObj, [key, val]) => {
            // mixedCaseUser currently includes the camel case keys we want but still includes all of the extra properties from the Iguana object
            // This check makes sure those don't end up on our final object.
            if (key.includes('_')) return userObj;

            (userObj as AnyObject)[key] = val;

            return userObj;
        }, {} as CurrentUser);

        return camelCaseUser;
    }

    return user;
};
