import { useMemo } from 'react';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { type AnyObject } from '@Types';
import cohortsApi from 'Cohorts/api';
import editAoiRecordsApi from './api';

const { useExecuteActionMutation, useGetUserInAdminQuery, useGetOfferableScholarshipsQuery } = editAoiRecordsApi;

const { useGetCohortsForAdminQuery } = cohortsApi;

export const useGetUserInAdmin = (userId: string) => {
    const {
        data: user,
        error: userError,
        isLoading: userLoading,
        refetch: refetchUser,
    } = useGetUserInAdminQuery(userId);

    return { user, userError, userLoading, refetchUser };
};

export const useCohorts = () => {
    const {
        data: availableCohorts,
        isLoading: cohortsLoading,
        error: cohortsError,
        refetch: refetchCohorts,
    } = useGetCohortsForAdminQuery();

    const cohortsById = useMemo(
        () =>
            (availableCohorts || []).reduce<AnyObject<CohortForAdminListsAttrs>>(
                (curr, next) => ({ ...curr, [next.id]: next }),
                {},
            ),
        [availableCohorts],
    );

    return {
        availableCohorts,
        cohortsLoading,
        cohortsError,
        cohortsById,
        refetchCohorts,
    };
};

export const useOfferableScholarships = (availableScholarshipIds?: string[]) => {
    const {
        data: offerableScholarships,
        isLoading: scholarshipsLoading,
        error: scholarshipsError,
    } = useGetOfferableScholarshipsQuery();

    const availableScholarships = useMemo(() => {
        if (availableScholarshipIds === undefined) {
            return offerableScholarships || [];
        }

        return offerableScholarships && availableScholarshipIds.length > 0
            ? offerableScholarships.filter(os => availableScholarshipIds.includes(os.id))
            : [];
    }, [availableScholarshipIds, offerableScholarships]);

    return {
        offerableScholarships,
        availableScholarships,
        scholarshipsLoading,
        scholarshipsError,
    };
};

export const useExecuteAction = () => {
    const [submitAction, { data: updatedUser, isLoading: isExecutingAction, error: executeActionError }] =
        useExecuteActionMutation();

    return { submitAction, updatedUser, isExecutingAction, executeActionError };
};
