/*
    This file is tested in AdminActionForm.spec.tsx.  See comments at top of SelectActionForm.tsx
    about the positives and negatives of this approach
*/
import { useCallback, useEffect, type ReactElement } from 'react';
import { useForm, FormProvider, yupResolver, type Resolver } from 'FrontRoyalReactHookForm';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type AnyObject } from '@Types';
import { type FormFieldProps } from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { type AoiRecord } from 'ProgramAoi';
import type AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { type ExecuteActionFormProps } from './ExecuteActionForm.types';

type FormProps = FormFieldProps<AoiRecord | undefined, AbstractUserManagementAction>;
type FormFields = (props: FormProps) => ReactElement | null;

export default function ExecuteActionForm({ action, onSubmit, record, onClickCancel, userId }: ExecuteActionFormProps) {
    const FormFields = action.FormFields as FormFields;
    const resolver: Resolver<AnyObject<unknown>, unknown> = useCallback(
        (values, ctx, opts) => yupResolver(action.formValidationSchema)(action.formatFormValues(values), ctx, opts),
        [action],
    );
    const formFunctions = useForm<AnyObject>({
        mode: 'onChange',
        defaultValues: {},
        resolver,
        reValidateMode: 'onChange',
    });

    const {
        formState: { isSubmitting, isValid, errors },
        handleSubmit,
        trigger,
    } = formFunctions;

    useEffect(() => {
        trigger();
    }, [trigger, action]);

    useEffect(() => {
        if (errors && Object.keys(errors).length) console.info('Form errors', errors); // eslint-disable-line no-console
    }, [errors]);

    return (
        <FormProvider {...formFunctions}>
            <form onSubmit={handleSubmit(onSubmit)} logLabel={`executeActionForm:${action.actionType}`}>
                <div className="action-form" data-testid="executeActionForm">
                    <div className="title">{action.title}</div>
                    <div className="description">{action.disabledReason || action.description}</div>
                    {!action.disabled && (
                        <>
                            {FormFields ? (
                                <div className="form-fields">
                                    <FormFields record={record} action={action} userId={userId} />
                                </div>
                            ) : (
                                <p>No fields need to be set for this action.</p>
                            )}
                            <button
                                className="btn flat white-and-beige outline pull-left"
                                type="button"
                                name="cancel"
                                disabled={isSubmitting}
                                onClick={onClickCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn flat blue pull-right"
                                type="submit"
                                disabled={!isValid || isSubmitting}
                            >
                                {isSubmitting ? <FrontRoyalSpinner color="white" /> : 'Save'}
                            </button>
                            <div className="clear" />
                        </>
                    )}
                </div>
            </form>
        </FormProvider>
    );
}
