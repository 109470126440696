export default function hideSplashScreen(injector) {
    if (!navigator || !navigator.splashscreen || window.splashHidden) {
        return;
    }

    window.splashHidden = true;
    navigator.splashscreen.hide();
    const EventLogger = injector.get('EventLogger');
    EventLogger.log(
        'splashscreen_hidden',
        {
            value: window.performance.now(),
        },
        {
            segmentio: false,
        },
    );
}
