import { useEffect, useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { type PaymentSituation } from 'PaymentSituation';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import TuitionPlanSelect from 'UserAdministrationTab/HelperComponents/TuitionPlanSelect';
import TuitionBenefitAdministratorSelect from 'UserAdministrationTab/HelperComponents/TuitionBenefitAdministratorSelect';
import { type ProgramInclusion } from 'ProgramInclusion';
import { isFullScholarship } from 'Scholarship';
import { isOneTimePlan } from 'TuitionPlan';
import { type CohortSectionOffer } from 'CohortSectionOffer';
import { Select } from 'FrontRoyalMaterialUiForm';
import { ProgramPaymentCard } from 'UserAdministrationTab/HelperComponents';
import usePaymentSituation from 'UserAdministrationTab/utils/usePaymentSituation';
import { useGetUserInAdmin } from 'UserAdministrationTab/redux';
import PaymentSelect from 'UserAdministrationTab/HelperComponents/PaymentSelect';
import { HandlePaymentOptionVal } from 'UserAdministrationTab/HelperComponents/PaymentSelect.types';
import { showDatePicker } from 'UserAdministrationTab/HelperComponents/PaymentSelectHelper';
import {
    useCohortSectionOffer,
    useSectionOfferOptions,
    useSetDefaultTuitionPlanId,
    useTuitionPlans,
} from 'UserAdministrationTab/UserManagementActions/AdmissionOffer/RegisterStudentHelpers';
import './ChangeProgram.scss';

type FormFields = {
    cohortSectionId: string;
    handlePaymentOption: HandlePaymentOptionVal | null;
    tuitionPlanId: string | null;
    tuitionBenefitAdministrator: string | null;
    isPaymentSituationChanged: boolean;
    paymentGracePeriodEndAt: number | null;
    createSubscription: boolean;
};

type ExecuteActionParams = {
    tuitionPlanId: string | null;
    cohortSectionId: string;
    paymentGracePeriodEndAt: number | null | undefined;
    tuitionBenefitAdministrator: string | null;
    createSubscription: boolean;
};
interface ChangeRegistrationAttrs extends UserManagementActionAttrs {
    selectablePaymentSituations: PaymentSituation[];
    isInFirst30DaysAfterCohortStart: boolean;
}

class ChangeRegistration extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeRegistration;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    get description() {
        return (
            <>
                <p>Use when a student would like to change their selection on the registration screen.</p>
                {!this.isInFirst30DaysAfterCohortStart && (
                    <p style={{ color: 'red' }}>
                        This student is past the 30 day limit to change their plan, use only if this exception has been
                        approved.
                    </p>
                )}
            </>
        );
    }

    static formValidationSchema = Yup.object().shape({
        cohortSectionId: Yup.string().required(),
        tuitionPlanId: Yup.string().required(),
        tuitionBenefitAdministrator: Yup.string().nullable(),
        handlePaymentOption: Yup.string().when('isPaymentSituationChanged', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
        paymentGracePeriodEndAt: Yup.number().when('handlePaymentOption', {
            is: (val: HandlePaymentOptionVal) => showDatePicker(val),
            then: Yup.number().required(),
            otherwise: Yup.number().nullable().defined(),
        }),
        createSubscription: Yup.boolean().required(),
    });

    selectablePaymentSituations: PaymentSituation[];
    isInFirst30DaysAfterCohortStart: boolean;

    constructor(attrs: ChangeRegistrationAttrs) {
        super(attrs);
        this.selectablePaymentSituations = attrs.selectablePaymentSituations;
        this.isInFirst30DaysAfterCohortStart = attrs.isInFirst30DaysAfterCohortStart;
    }

    formatFormValues({
        cohortSectionId,
        tuitionPlanId,
        tuitionBenefitAdministrator,
        paymentGracePeriodEndAt,
        createSubscription,
    }: FormFields): ExecuteActionParams {
        return {
            cohortSectionId,
            paymentGracePeriodEndAt: paymentGracePeriodEndAt
                ? moment(paymentGracePeriodEndAt).valueOf() / 1000
                : paymentGracePeriodEndAt === null
                ? null
                : undefined,
            tuitionPlanId,
            tuitionBenefitAdministrator: tuitionBenefitAdministrator || null,
            createSubscription,
        };
    }

    static FormFields = ({
        userId,
        record,
        action: { selectablePaymentSituations },
    }: FormFieldProps<ProgramInclusion, ChangeRegistration>) => {
        const {
            cohortId,
            includedCohortSectionId,
            cumulativeScholarship: oldCumulativeScholarship,
            tuitionPlanId: oldTuitionPlanId,
            admissionOfferId,
            tuitionContractId,
        } = record;
        const { user } = useGetUserInAdmin(userId);
        const { watch, formState, reset, setFieldValue } = useFormContext<FormFields>();
        const [cohortSectionId, tuitionPlanId, isPaymentSituationChanged, handlePaymentOption, createSubscription] =
            watch([
                'cohortSectionId',
                'tuitionPlanId',
                'isPaymentSituationChanged',
                'handlePaymentOption',
                'createSubscription',
            ]);

        const admissionOffer = useMemo(
            () => user?.admissionOffers.find(ao => ao.id === admissionOfferId),
            [user, admissionOfferId],
        );

        const tuitionContract = useMemo(
            () => user?.tuitionContracts.find(tc => tc.id === tuitionContractId),
            [user, tuitionContractId],
        );

        const cohortSectionOffers: CohortSectionOffer[] = admissionOffer?.cohortSectionOffers || [];
        const sectionOfferOptions = useSectionOfferOptions(cohortSectionOffers);
        const cohortSectionOffer = useCohortSectionOffer(cohortSectionId, cohortSectionOffers);
        const tuitionPlans = useTuitionPlans(
            selectablePaymentSituations,
            cohortSectionOffer?.cumulativeOfferedScholarship,
        );

        useSetDefaultTuitionPlanId(tuitionPlans, tuitionPlanId);

        const originalPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            oldTuitionPlanId,
            oldCumulativeScholarship,
        );

        const newPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            tuitionPlanId,
            cohortSectionOffer?.cumulativeOfferedScholarship,
        );

        const isFullScholarshipSelected = isFullScholarship(cohortSectionOffer?.cumulativeOfferedScholarship);

        const isOneTimePlanSelected = isOneTimePlan(tuitionPlanId, tuitionPlans);

        useEffect(() => {
            reset({
                cohortSectionId: includedCohortSectionId,
                tuitionPlanId: oldTuitionPlanId,
            });
        }, [includedCohortSectionId, oldTuitionPlanId, reset]);

        useEffect(() => {
            setFieldValue('tuitionBenefitAdministrator', tuitionContract?.tuitionBenefitAdministrator || '');
        }, [tuitionContract, setFieldValue]);

        useEffect(() => {
            setFieldValue(
                'isPaymentSituationChanged',
                tuitionPlanId !== oldTuitionPlanId ||
                    (cohortSectionOffer?.cumulativeOfferedScholarship?.level || null) !==
                        (oldCumulativeScholarship?.level || null),
            );
        }, [tuitionPlanId, cohortSectionOffer, oldTuitionPlanId, oldCumulativeScholarship, setFieldValue]);

        useEffect(() => {
            setFieldValue('createSubscription', handlePaymentOption === HandlePaymentOptionVal.SubscriptionAuto);
        }, [handlePaymentOption, setFieldValue]);

        return (
            <>
                <Select
                    name="cohortSectionId"
                    label="Section Offer"
                    fullWidth
                    disabled={formState.isSubmitting || sectionOfferOptions.length === 0}
                    options={sectionOfferOptions}
                    optionLabel={so => `${so.sectionLabel} - ${so.scholarshipLabel}`}
                    optionValue={so => so.cohortSectionId}
                />

                <TuitionPlanSelect
                    name="tuitionPlanId"
                    tuitionPlans={tuitionPlans}
                    disabled={formState.isSubmitting || !cohortSectionId || tuitionPlans.length === 0}
                />

                {originalPaymentSituation && record && (
                    <div className="payment-information">
                        <ProgramPaymentCard
                            title="Original Payment Information"
                            paymentSituation={originalPaymentSituation}
                            cohortId={cohortId}
                        />
                        {isPaymentSituationChanged && newPaymentSituation && (
                            <ProgramPaymentCard
                                title="New Payment Information"
                                paymentSituation={newPaymentSituation}
                                showDiffFromPaymentSituation={originalPaymentSituation}
                                cohortId={cohortId}
                            />
                        )}
                    </div>
                )}

                <TuitionBenefitAdministratorSelect
                    name="tuitionBenefitAdministrator"
                    disabled={formState.isSubmitting}
                />

                {isPaymentSituationChanged && newPaymentSituation && (
                    <PaymentSelect
                        disabled={!newPaymentSituation || isFullScholarshipSelected}
                        isFullScholarship={isFullScholarshipSelected}
                        isOneTimePlan={isOneTimePlanSelected}
                        actionType={ChangeRegistration.actionType}
                    />
                )}

                <p>
                    Any changes to the user&apos;s payment situation will need to be done manually. This includes
                    {!createSubscription && ' creating a new subscription /'} invoicing the user through Stripe or
                    recording wire transfers.
                </p>
            </>
        );
    };
}

export default ChangeRegistration;
