import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Select } from 'FrontRoyalMaterialUiForm';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import { ApplicationActionType, UserManagementActionNamespace } from '../UserManagementAction.types';

enum AdmissionsDecisions {
    REJECT = 'Reject',
    REJECT_FOR_WITHDRAWAL_OF_APP = 'Reject for Withdrawal of App',
}

const admissionsDecisionOptions = [
    { label: AdmissionsDecisions.REJECT, value: AdmissionsDecisions.REJECT },
    {
        label: AdmissionsDecisions.REJECT_FOR_WITHDRAWAL_OF_APP,
        value: AdmissionsDecisions.REJECT_FOR_WITHDRAWAL_OF_APP,
    },
];

type FormFields = {
    admissionsDecision: AdmissionsDecisions;
};

type ExecuteActionParams = FormFields;

export default class RejectApplication extends AbstractUserManagementAction {
    static actionType = ApplicationActionType.RejectApplication;
    static namespace = UserManagementActionNamespace.ProgramApplication;

    static description = (
        <>
            <p>Rejects an application. The user can apply again for a later cohort or a different program.</p>
            <p>Most admissions decisions should be made in Airtable, use this when decisions need immediate effect.</p>
        </>
    );

    static formValidationSchema = Yup.object().shape({
        admissionsDecision: Yup.string().required(),
    });

    formatFormValues({ admissionsDecision }: FormFields): ExecuteActionParams {
        return {
            admissionsDecision: admissionsDecision || AdmissionsDecisions.REJECT,
        };
    }

    static FormFields = () => {
        const { watch } = useFormContext<FormFields>();
        const admissionsDecision = watch('admissionsDecision');
        return (
            <>
                <Select
                    label="Admissions Decision (optional)"
                    name="admissionsDecision"
                    options={admissionsDecisionOptions}
                    optionValue={option => option.value}
                    optionLabel={option => option.label}
                />
                {admissionsDecision === AdmissionsDecisions.REJECT_FOR_WITHDRAWAL_OF_APP && (
                    <>
                        This will immediately withdraw the application without going through the typical rejection
                        pipeline.
                        <br />
                        The admissions decision will be set to: &quot;{admissionsDecision}&quot;.
                    </>
                )}
            </>
        );
    };
}
