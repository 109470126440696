import { generateGuid } from 'guid';

export default function cacheAngularTemplate(module, name, template) {
    // You do not have to pass a name in.  If you don't, then
    // one will be generated and returned
    if (arguments.length === 2) {
        template = name;

        // NOTE: We are currently appending a snippet of the template to help trace down
        // any "Failed to load template" errors that are happening
        // see also: https://trello.com/c/6LwOI8Ll
        name = `${generateGuid()}-${template.substr(0, 100)}`;
    }

    if (typeof module === 'string') {
        throw new Error('string provided as module where Angular module expected!');
    }

    module.run([
        '$templateCache',
        function setupTemplateCache($templateCache) {
            $templateCache.put(name, template);
        },
    ]);

    return name;
}
