async function checkIfTruthy(falsyResults, promise) {
    return promise.then(r => {
        if (!r) {
            falsyResults.push(true);
        }
    });
}

/*
    This function takes an array of promises and
    resolves with true if all of the promises resolve
    with truthy values. Resolves with false if any of
    them resolve with falsy values.  Rejects if any
    if the promises reject.
*/
export default async function allTruthy(promises) {
    const falsyResults = [];

    await Promise.all(promises.map(promise => checkIfTruthy(falsyResults, promise)));

    return falsyResults.length === 0;
}
