import { useEffect } from 'react';
import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { type ProgramInclusion } from 'ProgramInclusion';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { Checkbox } from 'FrontRoyalMaterialUiForm';
import { useFormContext } from 'FrontRoyalReactHookForm';

type ExecuteActionParams = { honors: boolean; honorsIneligible: boolean };

class Graduate extends AbstractUserManagementAction {
    static actionType = InclusionActionType.Graduate;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    get description() {
        return (
            <p>
                Use to graduate a student that is progressing outside of the usual schedule. Most of the cohort should
                be graduated using the bulk editor.
            </p>
        );
    }

    static formValidationSchema = Yup.object().shape({
        honors: Yup.boolean().when('honorsIneligible', {
            is: true,
            then: Yup.boolean().oneOf([false]),
            otherwise: Yup.boolean().required(),
        }),
    });

    static FormFields = ({ record }: FormFieldProps<ProgramInclusion>) => {
        const { reset } = useFormContext<ExecuteActionParams>();
        const { graduatedWithHonors, honorsIneligible } = record;

        useEffect(() => {
            // `honorsIneligible` is part of form values so that Yup has access to it
            // for the `honors` field validations; however, `honorsIneligible` is a
            // static value and not an actual field in the form that can be editted.
            reset({ honors: !!graduatedWithHonors, honorsIneligible });
        }, [reset, graduatedWithHonors, honorsIneligible]);

        return <Checkbox name="honors" label="Graduate with honors" disabled={honorsIneligible} />;
    };
}

export default Graduate;
