import { useEffect } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import moment from 'moment';
import {
    type FormFieldProps,
    OfferActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import TuitionPlanSelect from 'UserAdministrationTab/HelperComponents/TuitionPlanSelect';
import TuitionBenefitAdministratorSelect from 'UserAdministrationTab/HelperComponents/TuitionBenefitAdministratorSelect';
import { isFullScholarship } from 'Scholarship';
import { isOneTimePlan } from 'TuitionPlan';
import { type AdmissionOffer } from 'AdmissionOffer';
import { Select } from 'FrontRoyalMaterialUiForm';
import { ProgramPaymentCard } from 'UserAdministrationTab/HelperComponents';
import usePaymentSituation from 'UserAdministrationTab/utils/usePaymentSituation';
import PaymentSelect from 'UserAdministrationTab/HelperComponents/PaymentSelect';
import { type HandlePaymentOptionVal } from 'UserAdministrationTab/HelperComponents/PaymentSelect.types';
import { showDatePicker } from 'UserAdministrationTab/HelperComponents/PaymentSelectHelper';
import { type FormFields, type ExecuteActionParams } from './RegisterStudent.types';
import {
    useCohortSectionOffer,
    usePaymentOptions,
    useSectionOfferOptions,
    useSetDefaultPaymentOption,
    useSetDefaultTuitionPlanId,
    useTuitionPlans,
} from './RegisterStudentHelpers';

const formValueKeys = ['cohortSectionId', 'handlePaymentOption', 'tuitionPlanId'] as const;

class RegisterStudent extends AbstractUserManagementAction {
    static actionType = OfferActionType.RegisterStudent;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (
        <p>
            Use to register a student who has an admission offer. This is generally used when a student plans to make a
            payment outside of the normal flow in the app.
        </p>
    );

    static formValidationSchema = Yup.object().shape({
        tuitionPlanId: Yup.string().required(),
        cohortSectionId: Yup.string().required(),
        tuitionBenefitAdministrator: Yup.string().nullable(),
        paymentGracePeriodEndAt: Yup.number().when('handlePaymentOption', {
            is: (val: HandlePaymentOptionVal) => showDatePicker(val) === true,
            then: Yup.number().required(),
            otherwise: Yup.number().nullable().defined(),
        }),
    });

    formatFormValues(values: FormFields): ExecuteActionParams {
        const { tuitionPlanId, cohortSectionId, paymentGracePeriodEndAt, tuitionBenefitAdministrator } = values;

        return {
            cohortSectionId,
            paymentGracePeriodEndAt: paymentGracePeriodEndAt
                ? moment(paymentGracePeriodEndAt).valueOf() / 1000
                : paymentGracePeriodEndAt === null
                ? null
                : undefined,
            tuitionPlanId,
            tuitionBenefitAdministrator: tuitionBenefitAdministrator || null,
        };
    }

    static FormFields = ({
        record: { cohortSectionOffers, selectablePaymentSituations, cohortId },
    }: FormFieldProps<AdmissionOffer>) => {
        const {
            formState: { isSubmitting },
            watch,
            setFieldValue,
        } = useFormContext<FormFields>();
        const [cohortSectionId, handlePaymentOption, tuitionPlanId] = watch(formValueKeys);
        const sectionOfferOptions = useSectionOfferOptions(cohortSectionOffers);
        const cohortSectionOffer = useCohortSectionOffer(cohortSectionId, cohortSectionOffers);
        const tuitionPlans = useTuitionPlans(
            selectablePaymentSituations,
            cohortSectionOffer?.cumulativeOfferedScholarship,
        );
        const handlePaymentOptions = usePaymentOptions(cohortSectionOffers, cohortSectionId);
        const isFullScholarshipSelected = isFullScholarship(cohortSectionOffer?.cumulativeOfferedScholarship);
        const isOneTimePlanSelected = isOneTimePlan(tuitionPlanId, tuitionPlans);

        useSetDefaultTuitionPlanId(tuitionPlans, tuitionPlanId);
        useSetDefaultPaymentOption(handlePaymentOptions, handlePaymentOption);

        const paymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            tuitionPlanId,
            cohortSectionOffer?.cumulativeOfferedScholarship,
        );

        useEffect(() => {
            if (sectionOfferOptions.length === 1) {
                setFieldValue('cohortSectionId', sectionOfferOptions[0].cohortSectionId);
            }
        }, [sectionOfferOptions, setFieldValue]);

        return (
            <>
                <Select
                    name="cohortSectionId"
                    label="Section Offer"
                    fullWidth
                    disabled={isSubmitting || sectionOfferOptions.length === 0}
                    options={sectionOfferOptions}
                    optionLabel={so => `${so.sectionLabel} - ${so.scholarshipLabel}`}
                    optionValue={so => so.cohortSectionId}
                />

                <TuitionPlanSelect
                    name="tuitionPlanId"
                    tuitionPlans={tuitionPlans}
                    disabled={isSubmitting || !cohortSectionId || tuitionPlans.length === 0}
                />
                {cohortId && paymentSituation && !isFullScholarshipSelected && (
                    <ProgramPaymentCard
                        title="Payment Information"
                        paymentSituation={paymentSituation}
                        cohortId={cohortId}
                    />
                )}
                <TuitionBenefitAdministratorSelect name="tuitionBenefitAdministrator" disabled={isSubmitting} />
                <PaymentSelect
                    disabled={!paymentSituation || isFullScholarshipSelected}
                    isFullScholarship={isFullScholarshipSelected}
                    isOneTimePlan={isOneTimePlanSelected}
                    actionType={OfferActionType.RegisterStudent}
                />
            </>
        );
    };
}

export default RegisterStudent;
