import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    ApplicationActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

export default class DeleteApplication extends AbstractUserManagementAction {
    static actionType = ApplicationActionType.DeleteApplication;
    static namespace = UserManagementActionNamespace.ProgramApplication;

    static description = (
        <>
            <p>Use in rare circumstances when advised by the engineering team.</p>
            <p>Generally an application should be rejected instead of being deleted.</p>
        </>
    );
}
