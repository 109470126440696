import { useEffect } from 'react';
import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { Checkbox } from 'FrontRoyalMaterialUiForm';
import { type UseFormReset, useFormContext } from 'FrontRoyalReactHookForm';
import ChangeCohort from 'UserAdministrationTab/UserManagementActions/ProgramInclusion/ChangeCohort';
import { type ProgramInclusion } from 'ProgramInclusion';
import { type DeferralReason, OTHER_DEFERRAL_REASON_ID } from 'DeferralReason';

interface ReadmitExpelledAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
    deferralReasons: DeferralReason[];
}

type FormFields = {
    changeCohort: boolean;
    cohortId: string | null;
    deferralReasonCategory: string | null;
    deferralReasonId: string | null;
    otherDeferralReasonTitle: string | null;
};

class ReadmitExpelled extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ReadmitExpelled;
    static namespace = UserManagementActionNamespace.ProgramInclusion;
    static changeCohortActionType = InclusionActionType.ChangeCohort;
    static description = (<p>Readmit a student that was expelled or withdrawn from a program</p>);
    static formValidationSchema = Yup.object().shape({
        changeCohort: Yup.boolean().required(),
        cohortId: Yup.string().when('changeCohort', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
        deferralReasonCategory: Yup.string().when('changeCohort', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
        deferralReasonId: Yup.string().when('changeCohort', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
        otherDeferralReasonTitle: Yup.string().when('deferralReasonId', {
            is: OTHER_DEFERRAL_REASON_ID,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
    });

    availableTargetCohortIds: string[];
    deferralReasons: DeferralReason[];

    constructor(attrs: ReadmitExpelledAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds ?? [];
        this.deferralReasons = attrs.deferralReasons;
    }

    static FormFields = ({ action, record, userId }: FormFieldProps<ProgramInclusion, ReadmitExpelled>) => {
        const { reset, watch } = useFormContext<FormFields>();
        const changeCohort = watch('changeCohort');

        useEffect(() => action.resetFormValues(reset), [reset, action]); // set form with initial field values

        // Reset the form to its initial state if the changeCohort checkbox
        // becomes unchecked. This should ensure that any lingering values
        // are cleaned up when it comes time to execute the action.
        useEffect(() => {
            if (!changeCohort) {
                action.resetFormValues(reset);
            }
        }, [reset, action, changeCohort]);

        return (
            <>
                <Checkbox name="changeCohort" label="Change Cohort" />
                {changeCohort && <ChangeCohort.FormFields action={action} record={record} userId={userId} />}
            </>
        );
    };

    resetFormValues(reset: UseFormReset<FormFields>): void {
        reset({
            changeCohort: false,
            cohortId: null,
            deferralReasonCategory: null,
            deferralReasonId: null,
            otherDeferralReasonTitle: null,
        });
    }
}

export default ReadmitExpelled;
