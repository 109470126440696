import { useEffect } from 'react';
import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { Checkbox } from 'FrontRoyalMaterialUiForm';
import { type ProgramApplication } from 'ProgramApplication';
import {
    ApplicationActionType,
    type FormFieldProps,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { useFormContext } from 'FrontRoyalReactHookForm';

type ExecuteActionParams = {
    admissionsValarOptIn: boolean;
};

class ChangeSettings extends AbstractUserManagementAction {
    static actionType = ApplicationActionType.ChangeSettings;
    static namespace = UserManagementActionNamespace.ProgramApplication;

    static description = (<p>Use to change the student&apos;s application settings.</p>);

    static formValidationSchema = Yup.object().shape({
        admissionsValarOptIn: Yup.boolean().required(),
    });

    static FormFields = ({ record }: FormFieldProps<ProgramApplication<true>>) => {
        const { reset } = useFormContext<ExecuteActionParams>();
        const { admissionsValarOptIn } = record;

        useEffect(() => {
            reset({
                admissionsValarOptIn,
            });
        }, [reset, admissionsValarOptIn]);

        // NOTE: The server-side portion of this UMA supports editing eligible_three_year_degree,
        // but client-side support for editing it is currently only in admin_student_records_dir.js
        // and admin_edit_student_transcripts_dir.js.
        return <Checkbox name="admissionsValarOptIn" label="Valar Opt-In" />;
    };
}

export default ChangeSettings;
