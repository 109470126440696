import template from 'ZoomContainer/angularModule/views/zoom_container.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const zoomContainerModule = angular.module('zoomContainer', ['angular-gestures']);

const templateUrl = cacheAngularTemplate(zoomContainerModule, template);

zoomContainerModule.directive('zoomContainer', [
    '$injector',

    function factory($injector) {
        const Capabilities = $injector.get('Capabilities');
        const DialogModal = $injector.get('DialogModal');
        const $timeout = $injector.get('$timeout');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                // If we're not in offline mode, we fall back to 'zoom', which will result in the
                // fetching of the original image. In offline mode, we want to show the same image
                // that was already being displayed in the frame because it's in the store.
                scope.imageContext = offlineModeManager.inOfflineMode ? scope.context : 'zoom';

                // Require touch-enabled browser for manipulation (zoom etc.).
                // This relies on modernizr setting the appropriate CSS classes.
                scope.allowTransform = Capabilities.touchEnabled;

                /*
                        Everything below here is related to the zoomed image.

                        We listen separately to drag and pinch gestures.  The tricky thing
                        is that they can overlap.  A drag can start while a transform is still
                        going on.  So you might get events like:

                        transformstart, transform, transform, dragstart, transformend, drag, dragend

                        We handle that by dealing with the two gestures separately.  In each case,
                        we pay attention to start, move, and end events.  In the start event, we
                        store the state of the image at the start of that gesture (in the ongoingGestures hash).
                        In each move event, we transform and/or move
                        the image relative to that starting state.  In the end
                        event we clean up the ongoingGestures hash.

                        One thing to note is that in the start events we factor in the original
                        deltaX and deltaY values in the starting state.  You might expect the initial
                        deltaX and deltaY to be 0, but they are not, and can be very far from 0 when
                        moving from drag to transform and back by putting down two fingers and then
                        picking up one while leaving the other down (or vice versa).  To me, this feels
                        like a bug.  It seems like deltaX/Y should always start from 0, but what do I know.
                        Essentially, we're basing our transformations on delta-deltaX and delta-deltaY
                    */

                // Track state of scale / translate
                scope.lastImageScale = 1;
                scope.lastImageX = 0;
                scope.lastImageY = 0;
                scope.ongoingGestures = {
                    drag: undefined,
                    transform: undefined,
                };

                function placeZoomedImage(x, y) {
                    const translateValue = `translate3d(${x}px,${y}px, 0)`;
                    const imageWrapper = $('.modal .image_wrapper');
                    imageWrapper.css({
                        '-webkit-transform': translateValue,
                        '-o-transform': translateValue,
                        transform: translateValue,
                    });
                    scope.lastImageX = x;
                    scope.lastImageY = y;
                }

                function scaleZoomedImage(targetScale, initialScale) {
                    const photo = $('.modal .cf-image');

                    // The amount of the scale is a multiple of where we started from
                    // and the scale on the current gesture.  But never scale smaller than
                    // the original size
                    const scale = Math.max(1, targetScale * initialScale);
                    scope.lastImageScale = scale;

                    const scaleValue = `scale(${scale})`;

                    photo.css({
                        '-webkit-transform': scaleValue,
                        '-o-transform': scaleValue,
                        transform: scaleValue,
                    });
                }

                // start/drag/end events for drag gesture
                scope.handleZoomedImageDragStart = event => {
                    if (!scope.allowTransform) {
                        return;
                    }
                    scope.ongoingGestures.drag = {
                        startX: scope.lastImageX - event.deltaX,
                        startY: scope.lastImageY - event.deltaY,
                    };

                    // kind of a hack: since we dont seem to be getting transformEnd events anymore,
                    // clear out transform state on the next drag
                    scope.ongoingGestures.transform = undefined;
                };

                scope.handleZoomedImageDrag = event => {
                    if (!scope.allowTransform || !scope.ongoingGestures.drag || !event || !event) {
                        return;
                    }
                    event.preventDefault(); // not sure what this does
                    placeZoomedImage(
                        scope.ongoingGestures.drag.startX + event.deltaX,
                        scope.ongoingGestures.drag.startY + event.deltaY,
                    );
                };

                scope.handleZoomedImageDragEnd = () => {
                    scope.ongoingGestures.drag = undefined;
                };

                // start/drag/end events for transform (pinch) gesture
                // scope.handleZoomedImageTransformStart = function(event) {
                //     if (!scope.allowTransform || !event) {
                //         return;
                //     }
                //     scope.ongoingGestures.transform = {
                //         startX: scope.lastImageX - event.deltaX,
                //         startY: scope.lastImageY - event.deltaY,
                //         startScale: scope.lastImageScale
                //     };
                // };

                scope.handleZoomedImageTransform = event => {
                    if (!scope.allowTransform || !event) {
                        return;
                    }
                    // only getting pinch events now (no transformStart),
                    // so just-in-time initialize transform state on first pinch
                    if (!scope.ongoingGestures.transform) {
                        scope.ongoingGestures.transform = {
                            startX: scope.lastImageX - event.deltaX,
                            startY: scope.lastImageY - event.deltaY,
                            startScale: scope.lastImageScale,
                        };
                    }

                    event.preventDefault(); // hammer event, allows redispatch. not sure what that means, or if this is necessary now

                    scaleZoomedImage(event.scale, scope.ongoingGestures.transform.startScale);
                    placeZoomedImage(
                        scope.ongoingGestures.transform.startX + event.deltaX,
                        scope.ongoingGestures.transform.startY + event.deltaY,
                    );
                };

                scope.handleTap = event => {
                    event.preventDefault();
                    // On iOS, sometimes the click would "bleed through" to the underlying image, resulting in it reopening itself
                    // immediately after closing! This ensures the dialog closes after the click is done propogating through the DOM.
                    $timeout(() => {
                        DialogModal.hideAlerts();
                    }, 100);
                };

                // scope.handleZoomedImageTransformEnd = function() {
                //     scope.ongoingGestures.transform = undefined;
                // };

                scope.showSpinner = true;
                scope.showImage = false;
                scope.$watch('imageViewModel', () => {
                    scope.showSpinner = false;
                    scope.showImage = true;
                });
            },
        };
    },
]);

export default zoomContainerModule;
