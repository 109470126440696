import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type AnyObject } from '@Types';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { TuitionPlanFrequency, type TuitionPlan } from 'TuitionPlan';
import { type CohortSectionOffer } from 'CohortSectionOffer';
import { type AdmissionOffer } from 'AdmissionOffer';
import { useCohorts } from 'UserAdministrationTab/redux';
import { getAdminDisplayString, sortTuitionPlans } from 'UserAdministrationTab/utils';
import { CohortSectionType } from 'Cohorts';
import { type PaymentInformation, type PaymentSituation } from 'PaymentSituation';
import { formatMoney } from 'FormatMoney';
import 'UserAdministrationTab/UserAdminItemCard/UserAdminItemCard.styles.scss';
import './AdminInformation.scss';
import { IdTableRow } from './IdTableRow';

type Props = {
    admissionOffer: AdmissionOffer;
    country?: string;
};

type PlanAndPaymentInfo = {
    frequency: TuitionPlan['frequency'];
    numIntervals: TuitionPlan['numIntervals'];
    netRequiredPayment: PaymentInformation['netRequiredPayment'];
    netDiscountAmount: PaymentInformation['netDiscountAmount'];
};

const getTuitionPlanAndPaymentInfo = (
    paymentSituations: PaymentSituation[],
    cohortSectionOffer?: CohortSectionOffer,
) => {
    if (!cohortSectionOffer) return undefined;

    const csoId = cohortSectionOffer.id;
    const cumulativeOfferedScholarshipLevel = cohortSectionOffer.cumulativeOfferedScholarship?.level || null;

    return {
        [csoId]: paymentSituations
            .filter(
                ({ cumulativeScholarshipLevel }) => cumulativeScholarshipLevel === cumulativeOfferedScholarshipLevel,
            )
            .map<PlanAndPaymentInfo>(({ tuitionPlan, paymentInformation }) => ({
                frequency: tuitionPlan.frequency,
                numIntervals: tuitionPlan.numIntervals,
                netDiscountAmount: paymentInformation.netDiscountAmount,
                netRequiredPayment: paymentInformation.netRequiredPayment,
            }))
            .sort(sortTuitionPlans),
    };
};

export const usePaymentInfo = (paymentSituations: PaymentSituation[], cohortSectionOffers: CohortSectionOffer[]) =>
    cohortSectionOffers.reduce(
        (prev, cso) => ({ ...prev, ...getTuitionPlanAndPaymentInfo(paymentSituations, cso) }),
        {} as AnyObject<PlanAndPaymentInfo[]>,
    );

const renderPaymentInfo = ({ netDiscountAmount, numIntervals, frequency, netRequiredPayment }: PlanAndPaymentInfo) => {
    const formattedMoney = numIntervals
        ? formatMoney(netRequiredPayment / numIntervals, undefined, { maximumFractionDigits: 2 })
        : formatMoney(netRequiredPayment);

    const frequencyText =
        frequency === TuitionPlanFrequency.Once
            ? 'charged once'
            : frequency === TuitionPlanFrequency.Annual
            ? 'charged twice'
            : frequency === TuitionPlanFrequency.Monthly
            ? `per month for ${numIntervals} months`
            : frequency === TuitionPlanFrequency.FullScholarship
            ? '(full scholarship)'
            : '';

    const discountText = netDiscountAmount
        ? `, ${formatMoney(netDiscountAmount, undefined, { maximumFractionDigits: 2 })} discount`
        : '';

    const key = netDiscountAmount ? `${frequency}-discount` : frequency;

    return <li className="payment-info" key={key}>{`${formattedMoney} ${frequencyText}${discountText}`}</li>;
};

export function AdmissionOfferInformation({
    admissionOffer: { originalCohortId, cohortId, id, offeredAt, selectablePaymentSituations, cohortSectionOffers },
    country,
}: Props) {
    const { t } = useTranslation('back_royal');
    const { cohortsById } = useCohorts();
    const sortedOffers = [...cohortSectionOffers].sort((a, _b) =>
        a.cohortSection?.sectionType === CohortSectionType.Default ? -1 : 0,
    );
    const paymentInfo = usePaymentInfo(selectablePaymentSituations, sortedOffers);

    const originalCohortName = useMemo(() => {
        if (originalCohortId && cohortId !== originalCohortId) {
            return cohortsById[originalCohortId]?.name;
        }
        return '';
    }, [cohortId, cohortsById, originalCohortId]);

    return (
        <div className="information-container">
            <div className="information-table" data-testid={`AdmissionOfferInformation: ${id}`}>
                <IdTableRow id={id} />
                <div className="table-row">
                    <div className="row-key">Offered at</div>
                    <div className="row-val">{formattedUserFacingDateTime(1000 * offeredAt)}</div>
                </div>
                {originalCohortName && (
                    <div className="table-row">
                        <div className="row-key">Original Cohort</div>
                        <div className="row-val">{originalCohortName}</div>
                    </div>
                )}
                {sortedOffers.length > 0 && (
                    <div className="table-row group">
                        <div className="row-key">Section Offers</div>
                        <ul>
                            {sortedOffers.map(sectionOffer => (
                                <li key={sectionOffer.id}>
                                    {sectionOffer.cohortSection.identifier && (
                                        <>
                                            <p className="row-key">Section Name</p>
                                            <p>
                                                {t(`cohort_sections.${sectionOffer.cohortSection?.identifier}_short`)}
                                            </p>
                                        </>
                                    )}
                                    <p className="row-key">Scholarship</p>
                                    <p>{getAdminDisplayString(sectionOffer.offeredScholarships)}</p>

                                    {paymentInfo[sectionOffer.id]?.length > 0 && (
                                        <>
                                            <div className="row-key">Payment Plan Options</div>
                                            <ul>{paymentInfo[sectionOffer.id].map(renderPaymentInfo)}</ul>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {country && (
                    <div className="table-row">
                        <div className="row-key">Country</div>
                        <div className="row-val">{t(`countries.country.${country}`)}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdmissionOfferInformation;
