import { parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { type UserInAdmin, getUserProgramState, getCanEventuallyReapply } from 'Users';
import 'UserAdministrationTab/UserAdminItemCard/UserAdminItemCard.styles.scss';
import './AdminInformation.scss';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { IdTableRow } from './IdTableRow';

interface Props {
    user: UserInAdmin;
}

const getGlobalRole = (roles: UserInAdmin['roles']) =>
    roles.find(({ resourceId, name }) => resourceId === null && name !== 'cannot_create')?.name;

export function UserInformation({ user }: Props) {
    const { id, name, roles, prefLocale, phone, country, activeInstitution, groups } = user;
    const activeProgramType = getUserProgramState(user)?.programType;
    const globalRole = getGlobalRole(roles);
    const { t } = useTranslation('back_royal');
    const userProgramFieldStatesWithValidReapplicationDate = user.userProgramFieldStates.filter(upfs => {
        const userProgramState = user.userProgramStates.find(ups => ups.id === upfs.userProgramStateId);
        return getCanEventuallyReapply(user, { userProgramState });
    });

    return (
        <div className="information-container user-information">
            <div className="information-table" data-testid={`UserInformation: ${id}`}>
                <IdTableRow id={id} />
                {name && (
                    <div className="table-row">
                        <div className="row-key">Name</div>
                        <div className="row-val">{name}</div>
                    </div>
                )}
                {globalRole && (
                    <div className="table-row">
                        <div className="row-key">Role</div>
                        <div className="row-val">{globalRole}</div>
                    </div>
                )}
                {activeInstitution && (
                    <div className="table-row">
                        <div className="row-key">Active Institution</div>
                        <div className="row-val">{activeInstitution.name}</div>
                    </div>
                )}
                {activeProgramType && (
                    <div className="table-row">
                        <div className="row-key">Active Program Type</div>
                        <div className="row-val no-transform">{activeProgramType}</div>
                    </div>
                )}
                {!!userProgramFieldStatesWithValidReapplicationDate.length && (
                    <div className="table-row group">
                        <div className="row-key">Reapplication Dates</div>
                        <ul>
                            {userProgramFieldStatesWithValidReapplicationDate.map(upfs => (
                                <li key={upfs.id}>
                                    <p className="row-key">{upfs.programField}</p>
                                    <p>{formattedUserFacingDateTime(upfs.reapplicationDate * 1000)}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {prefLocale && (
                    <div className="table-row">
                        <div className="row-key">Locale</div>
                        <div className="row-val no-transform">{prefLocale}</div>
                    </div>
                )}
                {!!groups?.length && (
                    <div className="table-row">
                        <div className="row-key">Groups</div>
                        <div className="row-val">
                            <ul>
                                {groups.map(group => (
                                    <li key={group.id}>{group.name.toLowerCase()}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {phone && (
                    <div className="table-row">
                        <div className="row-key">Phone</div>
                        <div className="row-val">{parsePhoneNumber(phone).formatInternational()}</div>
                    </div>
                )}
                {country && (
                    <div className="table-row">
                        <div className="row-key">Country</div>
                        <div className="row-val">{t(`countries.country.${country}`)}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserInformation;
