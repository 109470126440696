import { Autocomplete, type AutocompleteProps } from 'FrontRoyalMaterialUiForm';
import { useFormContext } from 'FrontRoyalReactHookForm';
import moment from 'moment-timezone';
import './TimezoneAutocomplete.scss';

type TimezoneAutocompleteProps<T> = Omit<AutocompleteProps<T>, 'options' | 'name' | 'label'>;

type TimezoneAutocompleteFields = {
    timezone: string | null;
};

const TimezoneAutocomplete = (props: TimezoneAutocompleteProps<string>) => {
    const { setFieldValue } = useFormContext<TimezoneAutocompleteFields>();

    return (
        <div className="timezone-autocomplete">
            <Autocomplete
                autoHighlight
                {...props}
                name="timezone"
                label="Timezone"
                options={moment.tz.names()}
                onChange={(_event, value: string | null) => {
                    setFieldValue('timezone', value);
                }}
            />
        </div>
    );
};

export default TimezoneAutocomplete;
