import { useMemo } from 'react';
import { type SuccessStatus, type FailureStatus, type AoiRecord } from 'ProgramAoi/ProgramAoi.types';
import { aoiRecordCreationTime, SUCCESS_STATUSES, FAILURE_STATUSES } from 'ProgramAoi';
import CollapsibleContent from 'CollapsibleContent';
import UserAdminItemCard from 'UserAdministrationTab/UserAdminItemCard/UserAdminItemCard';
import AoiRecordTitle from 'UserAdministrationTab/AoiRecordTitle/AoiRecordTitle';
import { type AoiRecordsListProps } from './AoiRecordsList.types';
import './AoiRecordsList.styles.scss';

function recordStatus({ status }: AoiRecord) {
    const colorMap = {
        green: SUCCESS_STATUSES,
        red: FAILURE_STATUSES,
    };

    if (colorMap.green.includes(status as SuccessStatus)) {
        return 'green';
    }

    if (colorMap.red.includes(status as FailureStatus)) {
        return 'red';
    }

    return 'default';
}

function AoiRecordsList({ records, userId, onUserUpdate }: AoiRecordsListProps) {
    const sortedRecords = useMemo(
        () => [...records]?.sort((a, b) => aoiRecordCreationTime(b).getTime() - aoiRecordCreationTime(a).getTime()),
        [records],
    );

    return (
        <div className="aoi-records-list">
            {sortedRecords?.length ? (
                sortedRecords.map(record => (
                    <CollapsibleContent
                        title={<AoiRecordTitle cohortId={record.cohortId} status={record.status} />}
                        key={record.id}
                        color={recordStatus(record)}
                    >
                        <UserAdminItemCard record={record} onUserUpdate={onUserUpdate} userId={userId} />
                    </CollapsibleContent>
                ))
            ) : (
                <p>There are no records to show here.</p>
            )}
        </div>
    );
}

export default AoiRecordsList;
