import * as Yup from 'yup';
import EditSectionOffers from 'UserAdministrationTab/HelperComponents/EditSectionOffers';
import { type ProgramApplication } from 'ProgramApplication';
import { type SectionOfferFormValue } from 'UserAdministrationTab/HelperComponents/EditSectionOffers.types';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    type FormFieldProps,
    UserManagementActionNamespace,
    ApplicationActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

type FormFields = {
    cohortSectionOffers: SectionOfferFormValue[];
};

class OfferAdmission extends AbstractUserManagementAction {
    static namespace = UserManagementActionNamespace.ProgramApplication;
    static actionType = ApplicationActionType.OfferAdmission;

    static description = (<div>Offer a user admission for a specific program application.</div>);

    static formValidationSchema = Yup.object().shape({
        cohortSectionOffers: Yup.array().of(
            Yup.object().shape({
                cohortSectionId: Yup.string().required(),
                offeredScholarshipIds: Yup.array().of(Yup.string().required()).required(),
            }),
        ),
    });

    formatFormValues(values: FormFields): FormFields {
        return {
            cohortSectionOffers: values.cohortSectionOffers?.map(offer => ({
                cohortSectionId: offer.cohortSectionId,
                offeredScholarshipIds: offer.offeredScholarshipIds,
            })),
        };
    }

    static FormFields = ({ record }: FormFieldProps<ProgramApplication>) => (
        <EditSectionOffers cohortId={record.cohortId} />
    );
}

export default OfferAdmission;
