import {
    UserManagementActionNamespace,
    ApplicationActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import OfferAdmissionAfterDeclinedOfferAction from 'UserAdministrationTab/UserManagementActions/OfferAdmissionAfterDeclinedOfferAction';

export class OfferAdmissionAfterDeclinedOffer extends OfferAdmissionAfterDeclinedOfferAction {
    static namespace = UserManagementActionNamespace.ProgramApplication;
    static actionType = ApplicationActionType.OfferAdmissionAfterDeclinedOffer;
}

export default OfferAdmissionAfterDeclinedOffer;
