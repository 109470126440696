/*
    This file is tested in AdminActionForm.spec.tsx.  It's possible we should refactor it so this Component
    would have its own specs, but we already had specs written in there before splitting this out from
    AdminActionForm.  Keeping the old tests give us better coverage, because we are testing the
    integration between this and the ExecuteActionForm, but it also makes it a bit more complex and
    harder to maintain.
*/
import { useCallback, useMemo, useEffect } from 'react';
import { FormProvider, useForm, yupResolver } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { Select } from 'FrontRoyalMaterialUiForm';
import type AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { type UserManagementActionType } from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import instantiateAction from 'UserAdministrationTab/UserManagementActions/instantiateAction';
import { type AnyObject } from '@Types';
import { EventTypes } from 'UserAdministrationTab/utils/adminActionEventMessages';
import { type SelectActionFormProps } from './SelectActionForm.types';

type SelectActionFormValues = {
    actionType: UserManagementActionType | undefined;
} & AnyObject;

export function renderAction(action?: AbstractUserManagementAction) {
    return action ? `${action.disabled ? '(Disabled) ' : ''}${action.title}` : '';
}

const isUserManagementAction = (action: ReturnType<typeof instantiateAction>): action is AbstractUserManagementAction =>
    action !== undefined;

const validationSchema = Yup.object().shape({ actionType: Yup.string().required() });

export default function SelectActionForm({
    record,
    setSelectedAction,
    userId,
    disabled,
    availableUserManagementActions,
    eventEmitter,
}: SelectActionFormProps) {
    const { handleSubmit, watch, reset, ...formFunctions } = useForm<SelectActionFormValues>({
        defaultValues: { actionType: undefined },
        resolver: yupResolver(validationSchema),
    });

    const actions = useMemo(() => {
        const id = record?.id || userId;
        if (id) {
            return availableUserManagementActions?.[id]
                ?.map(instantiateAction)
                .filter(isUserManagementAction)
                .sort((a, b) => {
                    if (a.disabled === b.disabled) {
                        return a.title > b.title ? 1 : -1;
                    }
                    return a.disabled ? 1 : -1;
                });
        }
        return [];
    }, [record, userId, availableUserManagementActions]);

    const findAction = useCallback(
        (actionType: UserManagementActionType) => actions?.find(action => action?.actionType === actionType),
        [actions],
    );

    const submitForm = useCallback(
        async (values: SelectActionFormValues) => {
            setSelectedAction(findAction(values.actionType!) as AbstractUserManagementAction);
        },
        [setSelectedAction, findAction],
    );

    useEffect(() => {
        const callback = () => {
            reset({ actionType: undefined });
            setSelectedAction(undefined);
        };
        eventEmitter.on(EventTypes.ResetForm, callback);

        return () => {
            eventEmitter.off(EventTypes.ResetForm, callback);
        };
    }, [eventEmitter, setSelectedAction, reset]);

    useEffect(() => {
        const subscription = watch(() => handleSubmit(submitForm)());

        return () => subscription.unsubscribe();
    }, [handleSubmit, submitForm, watch]);

    return (
        <>
            {actions?.length ? (
                <FormProvider handleSubmit={handleSubmit} reset={reset} watch={watch} {...formFunctions}>
                    <form>
                        <Select
                            name="actionType"
                            className="action-select"
                            label="Choose action"
                            disabled={disabled}
                            options={actions}
                            optionLabel={renderAction}
                            optionValue={action => action.actionType}
                            renderValue={actionType => renderAction(findAction(actionType as UserManagementActionType))}
                        />
                    </form>
                </FormProvider>
            ) : (
                <h4>There are no actions to perform at the moment.</h4>
            )}
        </>
    );
}
