import {
    UserManagementActionNamespace,
    OfferActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import OfferAdmissionAfterDeclinedOfferAction from 'UserAdministrationTab/UserManagementActions/OfferAdmissionAfterDeclinedOfferAction';

export class OfferAdmissionAfterDeclinedOffer extends OfferAdmissionAfterDeclinedOfferAction {
    static namespace = UserManagementActionNamespace.AdmissionOffer;
    static actionType = OfferActionType.OfferAdmissionAfterDeclinedOffer;
}

export default OfferAdmissionAfterDeclinedOffer;
