import angularModule from 'UnloadedChangeDetector/angularModule/scripts/unloaded_change_detector_module';
/*

    ** Overview:

    This class generalizes a certain strategy for detecting when there have been
    changes made on the server that the client does not know about.  Basically,
    the server regularly pushes down the latest updated_at on the relevant records,
    the client keeps track of that value, and if it ever changes, then that indicates
    that something has changed on the server that the client does not know about.

    This class does not have anything to do with actually caching results from the
    server, only with detecting when there were changes made on the server since the last
    time the client checked.

    ** Usage:

    (For an example, see career_network_view_model.js):

    Step 1. Create a subclass that defines which properties to watch for in the interceptor:

        // my_property should match a key setup for tracking with `track_last_updated_at` in unloaded_change_detector.rb
        var MyUnloadedChangeDetector = UnloadedChangeDetector.createDetectorKlass('my_property');

    Step 2. Create an instance and define a callback.  (Don't forget to destroy it)

        var myDetector = new MyUnloadedChangeDetector().onChangeDetected(function() {
            reloadSomeStuff();
        });

        function whenIDoNotNeedToWatchThisAnymore() {
            myDetector.destroy();
        }

*/

angularModule.factory('UnloadedChangeDetector', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const guid = $injector.get('guid');

        const UnloadedChangeDetector = SuperModel.subclass(function () {
            this.instances = {};

            this.extend({
                createDetectorKlass(prop) {
                    return this.subclass(() => ({
                        prop,
                    }));
                },
            });

            return {
                initialize() {
                    this.id = guid.generate();
                    UnloadedChangeDetector.instances[this.id] = this;
                },

                onChangeDetected(callback) {
                    this.callback = callback;
                    return this;
                },

                destroy() {
                    delete UnloadedChangeDetector.instances[this.id];
                },

                getLastUpdatedAtBeforeChanges(response) {
                    try {
                        return response.data.meta.push_messages.unloaded_change_detector[`${this.prop}_before_changes`];
                    } catch (e) {
                        return undefined;
                    }
                },

                getLastUpdatedAt(response) {
                    try {
                        return response.data.meta.push_messages.unloaded_change_detector[this.prop];
                    } catch (e) {
                        return undefined;
                    }
                },

                onServerResponse(response) {
                    // This variable holds the last_updated_at that the server knew about
                    // BEFORE processing the api request that triggered this response handler.
                    // That is, before this client sent up any changes to the server, what
                    // was the latest updated_at that the server knew about
                    // In some api requests, neither of these will be defined.  In
                    // some, only lastUpdatedAt will be defined.  In api requests where the
                    // client saved a change that could have altered the last_updated_at on
                    // the server, both should be defined.
                    let lastUpdatedAtBeforeChanges = this.getLastUpdatedAtBeforeChanges(response);

                    // This variable holds the last_updated_at that the server knew about
                    // AFTER processing the api request that triggered this response handler.
                    // In cases where the api request did not make any relevant changes, this
                    // will be the same as lastUpdatedAtBeforeChanges (and lastUpdatedAtBeforeChanges
                    // might be omitted entirely from the response)
                    const lastUpdatedAt = this.getLastUpdatedAt(response);

                    // If this api request did not make any changes, and the server
                    // did not send down a value for lastUpdatedAtBeforeChanges, then
                    // we can treat both values as the same
                    if (lastUpdatedAt && !lastUpdatedAtBeforeChanges) {
                        lastUpdatedAtBeforeChanges = lastUpdatedAt;
                    }

                    // use isDefined because we want to send null values through.
                    // - `undefined` means that this particular api response did not
                    //      include any information about the last_updated_at
                    // - `null` means that the server is telling us that there is no
                    //      last_updated_at, because no relevant records exist
                    if (angular.isUndefined(lastUpdatedAt)) {
                        return response;
                    }

                    // This is the last_updated_at that the client knows about.  If a message
                    // comes down from the server that the actual last_updated_at is later,
                    // then we will trigger the callback.
                    // Grab the current value of self.lastUpdatedAt so we can compare
                    // it against the values that just came down from the api.
                    // If the current value is null, that means that there were
                    // no records before, and now there are some.  In that case, set it to 0
                    // so that the creation of the first record will trigger the callback.
                    const selfLastUpdatedAt = this.lastUpdatedAt === null ? 0 : this.lastUpdatedAt;

                    // Update the lastUpdatedAt instance variable for use the next time
                    // this method runs in the future.
                    this.lastUpdatedAt = lastUpdatedAt;

                    // If, before our request made any changes, the server knew about a later
                    // last_updated_at then the client did, then trigger the callback.
                    if (angular.isDefined(selfLastUpdatedAt) && lastUpdatedAtBeforeChanges > selfLastUpdatedAt) {
                        if (this.callback) {
                            this.callback(selfLastUpdatedAt);
                        }
                    }

                    return response;
                },
            };
        });

        return UnloadedChangeDetector;
    },
]);
