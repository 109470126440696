import angularModule from 'Users/angularModule/scripts/users_module';

angularModule.factory('S3SupplementalEnrollmentDocument', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('s3_supplemental_enrollment_documents');
            this.alias('S3SupplementalEnrollmentDocument');
            this.setIdProperty('id');
            this.embeddedIn('user');

            this.extend({
                UPLOAD_URL: `${window.ENDPOINT_ROOT}/api/s3_supplemental_enrollment_documents.json`,
            });

            Object.defineProperty(this.prototype, 'downloadUrl', {
                get() {
                    return `${window.ENDPOINT_ROOT}/api/s3_supplemental_enrollment_documents/${this.id}/file`;
                },
            });

            Object.defineProperty(this.prototype, 'fileUpdatedAt', {
                get() {
                    if (!this.$$fileUpdatedAt) {
                        this.$$fileUpdatedAt = new Date(this.file_updated_at * 1000);
                    }
                    return this.$$fileUpdatedAt;
                },
                configurable: true,
            });

            return {};
        });
    },
]);
