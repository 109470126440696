import { Select } from 'FrontRoyalMaterialUiForm';
import { type CohortSection } from 'Cohorts/CohortAttrs.types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'FrontRoyalReactHookForm';

interface ChangeSectionSelectProps {
    name: string;
    sections: CohortSection[];
}

export const ChangeSectionSelect = ({ name, sections }: ChangeSectionSelectProps) => {
    const { t } = useTranslation('back_royal');
    const { formState } = useFormContext();

    return (
        <Select
            name={name}
            label="Section"
            fullWidth
            disabled={formState.isSubmitting || sections.length === 0}
            options={sections}
            optionLabel={section => t(`cohort_sections.${section.identifier}_short`)}
            optionValue={section => section.id}
        />
    );
};
export default ChangeSectionSelect;
