/*
    Finds all of the paths from the webpack manifest for the specified targetModule
    (e.g. 'admin', 'editor', etc.) for the given extension.

    For example, given the following webpack manifest:
        {
            'admin.js': '/assets/scripts/admin.js',
            'admin~editor.js': '/assets/scripts/admin~editor.js',
            'vendors~admin.js': '/assets/scripts/vendors~admin.js',
            'vendors~admin~editor.css': '/assets/scripts/vendors~admin~editor.css',
            'vendors~admin~front-royal.js': '/assets/scripts/vendors~admin~front-royal.js',
            'reports.js': '/assets/scripts/reports.js'
        }
    If the targetModule is 'admin' and the extension is 'js', the return value would contain:
        [
            'assets/scripts/admin.js',
            'assets/scripts/admin~editor.js',
            'assets/scripts/vendors~admin.js',
            'assets/scripts/vendors~admin~editor.js'
        ]

    NOTE: The ordering of these files isn't important because webpack's runtime code
    ensures that the code is executed in the order specified by our deps.js files.
*/

export default function getFilePathsFromManifest(targetModuleName, extension, forceInclude = false) {
    const targetModuleRegex = new RegExp(`${targetModuleName}.*.${extension}$`);

    return Object.values(window.webpackManifest)
        .filter(path => {
            if (targetModuleRegex.test(path)) {
                if (forceInclude) {
                    return true;
                }
                if (extension === 'css') {
                    // common styles are always already present because we throw them in
                    // the head of the document in a rails template
                    return !/common/.test(path);
                }
                // Any chunks that share code with common or front-royal will already have
                // been loaded, so we don't include them here.
                return !/common|front-royal/.test(path);
            }
            return false;
        })
        .map(path => path.replace(/^\//, '')); // remove the prepended forward slash
}
