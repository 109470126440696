import { useMemo } from 'react';
import { type PaymentSituation, paymentSituationForScholarshipAndTuitionPlanId } from 'PaymentSituation';
import { type CumulativeScholarship } from 'Scholarship';

export default function usePaymentSituation(
    paymentSituations: PaymentSituation[],
    tuitionPlanId: string | null | undefined,
    cumulativeScholarship: CumulativeScholarship | null | undefined,
) {
    return useMemo(
        () => paymentSituationForScholarshipAndTuitionPlanId(paymentSituations, cumulativeScholarship, tuitionPlanId),
        [cumulativeScholarship, tuitionPlanId, paymentSituations],
    );
}
