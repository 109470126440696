import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    ApplicationActionType,
    type UserManagementActionAttrs,
    type FormFieldProps,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';

interface ChangeProgramAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
}

class ChangeProgram extends AbstractUserManagementAction {
    static actionType = ApplicationActionType.ChangeProgram;
    static namespace = UserManagementActionNamespace.ProgramApplication;

    static description = (
        <p>
            Change the program and institution the user is applying to. Use only after discussing with the user and
            gaining consent. To offer admission into a different program, first change the program, then use the offer
            admission action.
        </p>
    );

    availableTargetCohortIds: string[];

    constructor(attrs: ChangeProgramAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    static formValidationSchema = Yup.object().shape({
        programType: Yup.string().required(),
        cohortId: Yup.string().required(),
    });

    static FormFields = ({ action }: FormFieldProps<never, ChangeProgram>) => (
        <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
    );
}

export default ChangeProgram;
