import type AbstractUserManagementAction from './AbstractUserManagementAction';
import {
    InclusionActionType,
    ApplicationActionType,
    OfferActionType,
    UserManagementActionNamespace,
    type ActionAttrsWithServerData,
    UserActionType,
} from './UserManagementAction.types';

import ApplicationRejectApplication from './ProgramApplication/RejectApplication';
import ApplicationUnrejectApplication from './ProgramApplication/UnrejectApplication';
import ApplicationChangeProgram from './ProgramApplication/ChangeProgram';
import ApplicationChangeSettings from './ProgramApplication/ChangeSettings';
import ApplicationConsiderInDifferentCohort from './ProgramApplication/ConsiderInDifferentCohort';
import DeleteApplication from './ProgramApplication/DeleteApplication';
import OfferAdmission from './ProgramApplication/OfferAdmission';
import OfferAdmissionInNewProgram from './ProgramApplication/OfferAdmissionInNewProgram';
import ProgramApplicationOfferAdmissionAfterDeclinedOffer from './ProgramApplication/OfferAdmissionAfterDeclinedOffer';

import InclusionChangeCohort from './ProgramInclusion/ChangeCohort';
import InclusionChangeProgram from './ProgramInclusion/ChangeProgram';
import IssueRefund from './ProgramInclusion/IssueRefund';
import ExpelOrWithdraw from './ProgramInclusion/ExpelOrWithdraw';
import Graduate from './ProgramInclusion/Graduate';
import ReadmitExpelled from './ProgramInclusion/ReadmitExpelled';
import ChangeRegistration from './ProgramInclusion/ChangeRegistration';
import ChangeScholarshipAfterRegistration from './ProgramInclusion/ChangeScholarshipAfterRegistration';
import ChangeSettings from './ProgramInclusion/ChangeSettings';
import GenerateDeferralLink from './ProgramInclusion/GenerateDeferralLink';
import MarkAsAuditing from './ProgramInclusion/MarkAsAuditing';
import UpdateBillingSituation from './ProgramInclusion/UpdateBillingSituation';

import DeleteUser from './UserActions/DeleteUser';
import CancelDeletion from './UserActions/CancelDeletion';
import EditUserDetails from './UserActions/EditUserDetails';
import IncludeInProgram from './UserActions/IncludeInProgram';
import UpdateExecEdEligibilityBundle from './UserActions/UpdateExecEdEligibilityBundle';
import AllowReapplication from './UserActions/AllowReapplication';
import RegisterWithCorporateSponsor from './UserActions/RegisterWithCorporateSponsor';

import ChangeOfferDetails from './AdmissionOffer/ChangeOfferDetails';
import RegisterStudent from './AdmissionOffer/RegisterStudent';
import ExpireOffer from './AdmissionOffer/ExpireOffer';
import DeleteOffer from './AdmissionOffer/DeleteOffer';
import ReactivateOffer from './AdmissionOffer/ReactivateOffer';
import AdmissionOfferOfferAdmissionAfterDeclinedOffer from './AdmissionOffer/OfferAdmissionAfterDeclinedOffer';
import SwitchOfferedProgram from './AdmissionOffer/SwitchOfferedProgram';

type AttrsType<T extends typeof AbstractUserManagementAction = typeof AbstractUserManagementAction> =
    ConstructorParameters<T>[0];

export default function instantiateAction({ namespace, actionType, ...attrs }: ActionAttrsWithServerData) {
    if (namespace === UserManagementActionNamespace.AdmissionOffer) {
        if (actionType === OfferActionType.ChangeOfferDetails) {
            return new ChangeOfferDetails(attrs as AttrsType<typeof ChangeOfferDetails>);
        }
        if (actionType === OfferActionType.RegisterStudent) {
            return new RegisterStudent(attrs as AttrsType<typeof RegisterStudent>);
        }
        if (actionType === OfferActionType.ReactivateDeclinedOffer) {
            return new ReactivateOffer(attrs);
        }
        if (actionType === OfferActionType.ExpireOffer) {
            return new ExpireOffer(attrs);
        }
        if (actionType === OfferActionType.DeleteOffer) {
            return new DeleteOffer(attrs);
        }
        if (actionType === OfferActionType.OfferAdmissionAfterDeclinedOffer) {
            return new AdmissionOfferOfferAdmissionAfterDeclinedOffer(
                attrs as AttrsType<typeof AdmissionOfferOfferAdmissionAfterDeclinedOffer>,
            );
        }
        if (actionType === OfferActionType.SwitchOfferedProgram) {
            return new SwitchOfferedProgram(attrs as AttrsType<typeof SwitchOfferedProgram>);
        }
    }

    if (namespace === UserManagementActionNamespace.ProgramApplication) {
        if (actionType === ApplicationActionType.ChangeProgram) {
            return new ApplicationChangeProgram(attrs as AttrsType<typeof ApplicationChangeProgram>);
        }

        if (actionType === ApplicationActionType.ChangeSettings) {
            return new ApplicationChangeSettings(attrs as AttrsType<typeof ApplicationChangeSettings>);
        }

        if (actionType === ApplicationActionType.ConsiderInDifferentCohort) {
            return new ApplicationConsiderInDifferentCohort(
                attrs as AttrsType<typeof ApplicationConsiderInDifferentCohort>,
            );
        }

        if (actionType === ApplicationActionType.RejectApplication) {
            return new ApplicationRejectApplication(attrs);
        }

        if (actionType === ApplicationActionType.UnrejectApplication) {
            return new ApplicationUnrejectApplication(attrs);
        }

        if (actionType === ApplicationActionType.OfferAdmission) {
            return new OfferAdmission(attrs);
        }

        if (actionType === ApplicationActionType.OfferAdmissionInNewProgram) {
            return new OfferAdmissionInNewProgram(attrs as AttrsType<typeof OfferAdmissionInNewProgram>);
        }

        if (actionType === ApplicationActionType.DeleteApplication) {
            return new DeleteApplication(attrs);
        }

        if (actionType === ApplicationActionType.OfferAdmissionAfterDeclinedOffer) {
            return new ProgramApplicationOfferAdmissionAfterDeclinedOffer(
                attrs as AttrsType<typeof ProgramApplicationOfferAdmissionAfterDeclinedOffer>,
            );
        }
    }

    if (namespace === UserManagementActionNamespace.ProgramInclusion) {
        if (actionType === InclusionActionType.ChangeCohort) {
            return new InclusionChangeCohort(attrs as AttrsType<typeof InclusionChangeCohort>);
        }

        if (actionType === InclusionActionType.ChangeProgram) {
            return new InclusionChangeProgram(attrs as AttrsType<typeof InclusionChangeProgram>);
        }

        if (actionType === InclusionActionType.Graduate) {
            return new Graduate(attrs as AttrsType<typeof Graduate>);
        }

        if (actionType === InclusionActionType.ExpelOrWithdraw) {
            return new ExpelOrWithdraw(attrs as AttrsType<typeof ExpelOrWithdraw>);
        }

        if (actionType === InclusionActionType.ChangeSettings) {
            return new ChangeSettings(attrs as AttrsType<typeof ChangeSettings>);
        }

        if (actionType === InclusionActionType.ReadmitExpelled) {
            return new ReadmitExpelled(attrs as AttrsType<typeof ReadmitExpelled>);
        }

        if (actionType === InclusionActionType.MarkAsAuditing) {
            return new MarkAsAuditing(attrs as AttrsType<typeof MarkAsAuditing>);
        }

        if (actionType === InclusionActionType.UpdateBillingSituation) {
            return new UpdateBillingSituation(attrs as AttrsType<typeof UpdateBillingSituation>);
        }

        if (actionType === InclusionActionType.GenerateDeferralLink) {
            return new GenerateDeferralLink(attrs as AttrsType<typeof GenerateDeferralLink>);
        }

        if (actionType === InclusionActionType.IssueRefund) {
            return new IssueRefund(attrs as AttrsType<typeof IssueRefund>);
        }

        if (actionType === InclusionActionType.ChangeRegistration) {
            return new ChangeRegistration(attrs as AttrsType<typeof ChangeRegistration>);
        }

        if (actionType === InclusionActionType.ChangeScholarshipAfterRegistration) {
            return new ChangeScholarshipAfterRegistration(
                attrs as AttrsType<typeof ChangeScholarshipAfterRegistration>,
            );
        }
    }

    if (namespace === UserManagementActionNamespace.User) {
        if (actionType === UserActionType.DeleteUser) {
            return new DeleteUser(attrs as AttrsType<typeof DeleteUser>);
        }

        if (actionType === UserActionType.CancelDeletion) {
            return new CancelDeletion(attrs as AttrsType<typeof CancelDeletion>);
        }

        if (actionType === UserActionType.EditUserDetails) {
            return new EditUserDetails(attrs as AttrsType<typeof EditUserDetails>);
        }

        if (actionType === UserActionType.IncludeInProgram) {
            return new IncludeInProgram(attrs as AttrsType<typeof IncludeInProgram>);
        }

        if (actionType === UserActionType.UpdateExecEdEligibilityBundle) {
            return new UpdateExecEdEligibilityBundle(attrs as AttrsType<typeof UpdateExecEdEligibilityBundle>);
        }

        if (actionType === UserActionType.AllowReapplication) {
            return new AllowReapplication(attrs as AttrsType<typeof AllowReapplication>);
        }

        if (actionType === UserActionType.RegisterWithCorporateSponsor) {
            return new RegisterWithCorporateSponsor(attrs as AttrsType<typeof RegisterWithCorporateSponsor>);
        }
    }

    /* As we're developing new actions, we may do the server-side work before we do the client-side work.
    In those cases, we will not be able to instantiate a UserManagementAction on the client even though attributes
    are returned from the server. */
    return undefined;
}
