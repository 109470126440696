import { ProgramApplicationStatus } from 'ProgramApplication';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    ApplicationActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

export default class UnrejectApplication extends AbstractUserManagementAction {
    static actionType = ApplicationActionType.UnrejectApplication;
    static namespace = UserManagementActionNamespace.ProgramApplication;

    static description = (
        <p>
            Moves a rejected application back into the &apos;{ProgramApplicationStatus.SubmittedApplication}&apos;
            state.
        </p>
    );
}
