import { useMemo } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    UserActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import ChangeSectionSelect from 'UserAdministrationTab/HelperComponents/ChangeSectionSelect';
import { Select, TextField } from 'FrontRoyalMaterialUiForm';
import './RegisterWithCorporateSponsor.scss';
import { type Nullable } from '@Types';

const REPORTS_TAG_PREFIX = 'coporate_sponsor__';
const OTHER_CORPORATE_SPONSOR = 'Other';

type FormFields = {
    cohortId: string;
    programType: string;
    cohortSectionId: string;
    reportsTag: string;
    reportsTagSuffix: string;
};

interface RegisterWithCorporateSponsorAttrs extends UserManagementActionAttrs {
    availableTargetCohortIds: string[];
    availableCorporateSponsors: string[];
}

type ExecuteActionParams = Omit<FormFields, 'programType' | 'reportsTagSuffix'> & {
    reportsTagSuffix: Nullable<string>;
};

const reportsTagParamValue = (reportsTag: string, reportsTagSuffix: string) =>
    reportsTag === OTHER_CORPORATE_SPONSOR ? `${REPORTS_TAG_PREFIX}${reportsTagSuffix || ''}` : reportsTag;

class RegisterWithCorporateSponsor extends AbstractUserManagementAction {
    static actionType = UserActionType.RegisterWithCorporateSponsor;
    static namespace = UserManagementActionNamespace.User;

    availableTargetCohortIds: string[];
    availableCorporateSponsors: string[];

    constructor(attrs: RegisterWithCorporateSponsorAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
        this.availableCorporateSponsors = attrs.availableCorporateSponsors;
    }

    static description = (
        <p>
            Use this action to apply a user to a program, offer them admission to a cohort, and register them with a
            full base scholarship.
        </p>
    );

    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
        cohortSectionId: Yup.string().required(),
        reportsTag: Yup.string().required(),
        reportsTagSuffix: Yup.string().when('reportsTag', {
            is: OTHER_CORPORATE_SPONSOR,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
    });

    formatFormValues({ cohortId, cohortSectionId, reportsTag, reportsTagSuffix }: FormFields): ExecuteActionParams {
        return {
            cohortId,
            cohortSectionId,
            reportsTag,
            reportsTagSuffix:
                reportsTag === OTHER_CORPORATE_SPONSOR && !reportsTagSuffix ? null : reportsTagSuffix || null,
        };
    }

    static FormFields = ({ action }: FormFieldProps<never, RegisterWithCorporateSponsor>) => {
        const { cohortsById } = useCohorts();
        const { formState, watch } = useFormContext<FormFields>();
        const [cohortId, reportsTag, reportsTagSuffix] = watch(['cohortId', 'reportsTag', 'reportsTagSuffix']);
        const sections = useMemo(() => (cohortId ? cohortsById[cohortId].cohortSections : []), [cohortId, cohortsById]);

        const availableCorporateSponsors = useMemo(() => {
            const options = action.availableCorporateSponsors.concat([OTHER_CORPORATE_SPONSOR]);

            // Sort in alphabetical order by title to make finding specific options easier
            return options.sort((a, b) => {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            });
        }, [action]);

        return (
            <div className="register-with-corporate-sponsor">
                <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
                <ChangeSectionSelect name="cohortSectionId" sections={sections} />
                <Select
                    name="reportsTag"
                    label="reports_tag"
                    fullWidth
                    disabled={formState.isSubmitting}
                    options={availableCorporateSponsors}
                />
                {reportsTag === OTHER_CORPORATE_SPONSOR && (
                    <TextField
                        name="reportsTagSuffix"
                        label="reports_tag suffix"
                        disabled={formState.isSubmitting}
                        fullWidth
                    />
                )}
                {reportsTag && (
                    <p>
                        The <code>reports_tag</code> on the user&apos;s program application, admission offer, and
                        program inclusion will be set to:{' '}
                        <code>{reportsTagParamValue(reportsTag, reportsTagSuffix)}</code>
                    </p>
                )}
            </div>
        );
    };
}

export default RegisterWithCorporateSponsor;
