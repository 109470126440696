/*
    The $http api for canceling requests leaves something to be desired.

    If we need to sit a value for `timeout` in an interceptor, we can only do
    so safely if there is not already a value for `timeout` on the config.
    If you blow away an existing value for `timeout`, you will be losing
    functionality.

    This method makes it possible to append a timeout value to a config object
    without losing functionality from an existing timeout value.
*/
export default function wrapAngularHttpTimeout(config, timeout, injector) {
    const $timeout = injector.get('$timeout');
    const $q = injector.get('$q');
    const origTimeout = config.timeout;

    // If there is no timeout already on the config, then we can just
    // add one.  (NOTE: == here means we catch undefined as well)
    if (origTimeout == null) {
        config.timeout = timeout;
        return config;
    }

    // According to the $http api, `timeout` can be either a number
    // or a promise.  We're going to convert it to always being a promise
    // so that we can wrap it.
    const origPromise = ensureTimeoutIsPromise(origTimeout, $timeout);

    config.timeout = $q(_cancelRequest => {
        // When the original promise resolves, either because some
        // amount of time passed or because someone resolved the provided
        // promise, we should cancel this request.
        origPromise.then(() => {
            _cancelRequest();
        });

        // Same like we did with the original promise that was already
        // on the config, we ensure that the new timeout we're adding
        // is a promise;
        const promise = ensureTimeoutIsPromise(timeout, $timeout);

        // Same like we did with the original promise, if the new
        // promise resolves, then we cancel the request.
        promise.then(_cancelRequest);
    });

    return config;
}

function ensureTimeoutIsPromise(timeout, $timeout) {
    if (typeof timeout === 'number') {
        return $timeout(timeout);
    }

    return timeout;
}
