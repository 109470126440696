export default function buildApiResponse(config, contents, meta) {
    meta = meta || {};
    contents = contents || {};

    if (!config) {
        throw new Error('No config');
    }
    return {
        config,
        headers: () => ({}),
        data: {
            contents,
            meta,
        },
    };
}
