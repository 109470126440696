import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    OfferActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

export default class DeleteOffer extends AbstractUserManagementAction {
    static actionType = OfferActionType.DeleteOffer;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (
        <>
            Use in rare circumstances when advised by engineering.
            <br />
            Generally an offer should be declined/expired rather than deleted.
        </>
    );
}
