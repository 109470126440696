import { useCallback, useState, useEffect } from 'react';
import EventEmitter from 'events';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useSuspenseQuery } from 'ReduxHelpers';
import { LoadingBoundary } from 'LoadingBoundary';
import { errorHandlingComponentsForSubComponents } from 'FrontRoyalErrorBoundary';
import { EventTypes } from '../utils/adminActionEventMessages';
import { useExecuteAction, useGetUserInAdmin } from '../redux/hooks';
import type AbstractUserManagementAction from '../UserManagementActions/AbstractUserManagementAction';
import editAoiRecordsApi from '../redux/api';
import { type AdminActionFormProps } from './AdminActionForm.types';
import SelectActionForm from './SelectActionForm';
import ExecuteActionForm from './ExecuteActionForm';
import { type ExecuteActionFormValues } from './ExecuteActionForm.types';
import '../UserAdminItemCard/UserAdminItemCard.styles.scss';

// EventEmitter needs to be set up outside of the component, otherwise other
// instances of this component will ignore events emitted here
const eventEmitter = new EventEmitter();

const AdminActionFormBody = ({ record, onUserUpdate, userId }: AdminActionFormProps) => {
    const { refetchUser } = useGetUserInAdmin(userId);
    const {
        data: availableUserManagementActions,
        refetch: refetchAvailableUserManagementActions,
        isFetching: isFetchingAvailableUserManagementActions,
    } = useSuspenseQuery(editAoiRecordsApi, 'getAvailableUserManagementActions', {
        userId,
        aoiRecordId: record?.id,
    });
    const [selectedAction, setSelectedAction] = useState<AbstractUserManagementAction>();

    const { submitAction, executeActionError, isExecutingAction } = useExecuteAction();

    if (executeActionError) throw executeActionError;

    const submitExecuteActionForm = useCallback(
        async (values: ExecuteActionFormValues) => {
            await submitAction({
                action: selectedAction!,
                values,
                record,
                userId,
            });

            onUserUpdate();
            refetchUser();
            refetchAvailableUserManagementActions();
            setSelectedAction(undefined);
        },
        [
            selectedAction,
            submitAction,
            record,
            userId,
            onUserUpdate,
            refetchUser,
            refetchAvailableUserManagementActions,
        ],
    );

    useEffect(() => {
        if (!selectedAction) eventEmitter.emit(EventTypes.ResetForm);
    }, [selectedAction]);

    return (
        <>
            {isFetchingAvailableUserManagementActions ? (
                <FrontRoyalSpinner />
            ) : (
                <>
                    <SelectActionForm
                        record={record}
                        userId={userId}
                        availableUserManagementActions={availableUserManagementActions}
                        setSelectedAction={setSelectedAction}
                        disabled={isExecutingAction}
                        eventEmitter={eventEmitter}
                    />

                    {selectedAction && (
                        <ExecuteActionForm
                            userId={userId}
                            record={record}
                            action={selectedAction}
                            onSubmit={submitExecuteActionForm}
                            onClickCancel={() => eventEmitter.emit(EventTypes.ResetForm)}
                        />
                    )}
                </>
            )}
        </>
    );
};

export const AdminActionForm = ({ record, onUserUpdate, userId }: AdminActionFormProps) => (
    <div className="actions-container">
        <LoadingBoundary errorActions={errorHandlingComponentsForSubComponents}>
            <AdminActionFormBody record={record} onUserUpdate={onUserUpdate} userId={userId} />
        </LoadingBoundary>
    </div>
);

export default AdminActionForm;
