import { Select } from 'FrontRoyalMaterialUiForm';
import { TuitionBenefitAdministrators } from 'TuitionContract';

interface TuitionBenefitAdministratorSelectProps {
    name: string;
    disabled: boolean;
}

type TuitionBenefitAdministratorOption = {
    label: string;
    value: string;
};

export const tuitionBenefitAdministratorOptions: TuitionBenefitAdministratorOption[] = [
    {
        label: 'Guild Direct Pay Network',
        value: TuitionBenefitAdministrators.GuildDirectPayNetwork,
    },
    {
        label: 'None',
        value: '',
    },
];

const TuitionBenefitAdministratorSelect = ({ name, disabled }: TuitionBenefitAdministratorSelectProps) => (
    <Select
        name={name}
        label="Tuition Benefit Administrator"
        options={tuitionBenefitAdministratorOptions}
        disabled={disabled}
        fullWidth
    />
);

export default TuitionBenefitAdministratorSelect;
