import { type AnyFunction, type AnyObject } from '@Types';
import { camelCase, clone, snakeCase } from 'lodash/fp';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { UUID_EXACT_REGEX } from 'guid';

type StringCase = 'snakeCase' | 'camelCase';
type Opts = {
    to: StringCase;
    keys?: string[];
    destructive?: boolean;
    transformUUIDs?: boolean;
    exclude?: (string | RegExp)[];
};

/**
 * Deep-transform the case of select (or default all) top-level keys of an object and their nested keys.
 * (snakecaseKeys and camelcaseKeys alone can cause circular JSON stringify errors due to
 *  embedded models and no whitelisting option)
 */
export const transformKeyCase = <ReturnType>(
    obj: AnyObject | null,
    { to, keys, destructive = false, transformUUIDs = false, exclude = [] }: Opts,
): ReturnType => {
    const objectToUpdate = destructive ? obj : clone(obj);
    if (!objectToUpdate) return objectToUpdate as ReturnType;
    const keysToTransform = keys || Object.keys(objectToUpdate);
    const transformFn = (to === 'snakeCase' ? snakecaseKeys : camelcaseKeys) as AnyFunction;
    const transformOpts = { deep: true, exclude };

    if (!transformUUIDs) {
        exclude.push(UUID_EXACT_REGEX);
    }

    keysToTransform.forEach(key => {
        const snakeCaseKey = snakeCase(key);
        const camelCaseKey = camelCase(key);
        const fromCaseKey = to === 'snakeCase' ? camelCaseKey : snakeCaseKey;
        const toCaseKey = to === 'snakeCase' ? snakeCaseKey : camelCaseKey;

        // The next line of code will skip any keys that get changed by both snakeCase and camelCase.
        // I'm not totally sure why we're doing this. It means that setting transformUUIDs=true will
        // not work for UUIDs at the top level (see commented out spec), but we never set transformUUID to
        // true anyway.
        if (![fromCaseKey, toCaseKey].some(k => k in objectToUpdate)) return;

        if (exclude.some(e => (e instanceof RegExp ? e.test(fromCaseKey) : e === fromCaseKey))) return;

        // key may already be present in the desired case
        const currentVal =
            typeof objectToUpdate[fromCaseKey] !== 'undefined'
                ? objectToUpdate[fromCaseKey]
                : objectToUpdate[toCaseKey];

        objectToUpdate[toCaseKey] =
            typeof currentVal === 'object' && currentVal !== null // condition includes arrays
                ? transformFn(currentVal, transformOpts)
                : currentVal;

        if (fromCaseKey !== toCaseKey) {
            delete objectToUpdate[fromCaseKey];
        }
    });

    return objectToUpdate as ReturnType;
};

export default transformKeyCase;
