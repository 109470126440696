/*
    Use this function when

    * you have a class that is instantiated with json
    * there is a property in the json that is stored as a unix timestamp generated by ruby's Time#to_i, i.e. 1642705252
    * in javascript, you want to get/set a Date object that corresponds to that property


    See specs for examples of usage
*/

import { underscore } from 'String';

function toTimestamp(date: Date): number {
    return date.getTime() / 1000;
}

export default function hasDateAsTimestampInJson(
    klass: { prototype: unknown },
    dateAttrName: string,
    _timestampAttrName?: string,
): void {
    const timestampAttrName: string = _timestampAttrName || underscore(dateAttrName);
    const privateVar = `$$${dateAttrName}`;

    Object.defineProperty(klass.prototype, dateAttrName, {
        get() {
            let currentVal = this[privateVar];
            if (currentVal && toTimestamp(currentVal) !== this[timestampAttrName]) {
                currentVal = null;
            }

            if (!currentVal) {
                const timestamp = this[timestampAttrName];
                this[privateVar] = typeof timestamp === 'number' ? new Date(timestamp * 1000) : null;
            }
            return this[privateVar];
        },
        set(val: Date) {
            this[timestampAttrName] = val && val.getTime() / 1000;
            this[privateVar] = val;
        },
        configurable: true, // let this be mocked out in specs
    });
}
