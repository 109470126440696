import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    UserManagementActionNamespace,
    UserActionType,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';

class CancelDeletion extends AbstractUserManagementAction {
    static actionType = UserActionType.CancelDeletion;
    static namespace = UserManagementActionNamespace.User;

    static description = (<>This will cancel the user&apos;s deletion request.</>);
}

export default CancelDeletion;
