import { type AoiStatus } from 'ProgramAoi/ProgramAoi.types';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import 'UserAdministrationTab/AoiRecordsList/AoiRecordsList.styles.scss';

interface AoiRecordTitleProps {
    cohortId: string;
    status: AoiStatus;
}

export default function AoiRecordTitle({ cohortId, status }: AoiRecordTitleProps) {
    const { cohortsById } = useCohorts();
    const cohortName = cohortsById[cohortId]?.name;

    return (
        <h4 className="record-title">
            {cohortName}: {status}
        </h4>
    );
}
