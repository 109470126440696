import { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import { useGetUserInAdmin } from 'UserAdministrationTab/redux';
import { IssueRefundSelect } from 'UserAdministrationTab/HelperComponents/IssueRefundSelect';
import { type RefundEntitlement } from 'RefundEntitlement';
import './IssueRefund.scss';

interface IssueRefundAttrs extends UserManagementActionAttrs {
    refundEntitlement: RefundEntitlement | null;
    hasEnoughTransactionsToRefund: boolean;
    paidOutsideOfStripe: boolean;
}

type FormValues = {
    paidOutsideOfStripe: boolean;
};

class IssueRefund extends AbstractUserManagementAction {
    static actionType = InclusionActionType.IssueRefund;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    refundEntitlement: RefundEntitlement | null;
    hasEnoughTransactionsToRefund: boolean;
    paidOutsideOfStripe: boolean;

    constructor(attrs: IssueRefundAttrs) {
        super(attrs);
        this.refundEntitlement = attrs.refundEntitlement;
        this.hasEnoughTransactionsToRefund = attrs.hasEnoughTransactionsToRefund;
        this.paidOutsideOfStripe = attrs.paidOutsideOfStripe;
    }

    get description() {
        return (
            <>
                <p>Use to calculate or issue refunds for students that have withdrawn or been expelled.</p>
                {this.paidOutsideOfStripe && (
                    <p>This user paid outside of Stripe. An automatic refund is not possible.</p>
                )}
            </>
        );
    }

    get formValidationSchema() {
        return Yup.object().shape({
            paidOutsideOfStripe: Yup.boolean().oneOf([false]).required(),
        });
    }

    static FormFields = ({ action, userId }: FormFieldProps<never, IssueRefund>): JSX.Element => {
        const { refundEntitlement, hasEnoughTransactionsToRefund, paidOutsideOfStripe } = action;
        const { reset } = useFormContext<FormValues>();
        const { user } = useGetUserInAdmin(userId);

        useEffect(() => {
            reset({ paidOutsideOfStripe });
        }, [paidOutsideOfStripe, reset]);

        return (
            <div className="issue-refund-form">
                <IssueRefundSelect
                    user={user}
                    hasEnoughTransactions={hasEnoughTransactionsToRefund}
                    refundEntitlement={refundEntitlement}
                    needInput={false}
                />
            </div>
        );
    };
}

export default IssueRefund;
