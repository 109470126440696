import angularModule from 'Users/angularModule/scripts/users_module';
// iguana service wrapper class
// currently only opening up /api/users.json which returns info about the current user
angularModule.factory('S3EnglishLanguageProficiencyDocument', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('s3_english_language_proficiency_documents');
            this.alias('S3EnglishLanguageProficiencyDocument');
            this.setIdProperty('id');
            this.embeddedIn('user');

            this.extend({
                UPLOAD_URL: `${window.ENDPOINT_ROOT}/api/s3_english_language_proficiency_documents.json`,
            });

            Object.defineProperty(this.prototype, 'downloadUrl', {
                get() {
                    return `${window.ENDPOINT_ROOT}/api/s3_english_language_proficiency_documents/${this.id}/file`;
                },
            });

            return {};
        });
    },
]);
