import angularModule from 'Users/angularModule/scripts/users_module';

angularModule.factory('PrivateUserDocumentsHelper', [
    '$injector',

    $injector => {
        const $window = $injector.get('$window');
        const $http = $injector.get('$http');
        const HttpQueue = $injector.get('HttpQueue');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        // FIXME: Is this true about being unstable though?
        // Note: URL.createObjectURL is not yet considered stable but I used it anyway because it is
        // super convenient, works in all the major browsers (if you include the prefixed form), and this
        // is for admins only anyway.
        const createObjectURL = ($window.URL || $window.webkitURL || {}).createObjectURL || (() => {});
        const revokeObjectURL = ($window.URL || $window.webkitURL || {}).revokeObjectURL || (() => {});

        return {
            createObjectURL,
            revokeObjectURL,

            // The odd argument structure is to support both iguana objects and vanilla objects
            downloadDocument(
                {
                    downloadUrl,
                    file_content_type,
                    file_file_name,
                    fileContentType = file_content_type,
                    fileFileName = file_file_name,
                },
                config = {},
            ) {
                $http
                    .get(downloadUrl, {
                        responseType: 'arraybuffer',
                        ...config,
                    })
                    .then(response => {
                        const file = new Blob([response.data], {
                            type: fileContentType,
                        });
                        const new_url = createObjectURL(file);
                        const a = $window.document.createElement('a');
                        a.href = new_url;
                        a.download = fileFileName;
                        a.click();
                        revokeObjectURL(file);
                    })
                    .catch(response => {
                        HttpQueue.unfreezeAfterError(response.config);

                        if (response.status === 404) {
                            $window.alert('This document has been deleted.');
                        } else {
                            throw new Error(response);
                        }
                    });
            },

            // The odd argument structure is to support both iguana objects and vanilla objects
            viewDocument({ downloadUrl, file_content_type, fileContentType = file_content_type }) {
                $http
                    .get(downloadUrl, {
                        responseType: 'arraybuffer',
                    })
                    .then(response => {
                        const file = new Blob([response.data], {
                            type: fileContentType,
                        });
                        NavigationHelperMixin.loadUrl(this.createObjectURL(file), '_blank');
                    });
            },

            // Expose methods on the scope when we know we are dealing with both an original
            // and a proxy user object.
            // Note: I'd love to move the DialogModel logic to the pure function API, but it's
            // tough because it uses callbacks instead of promises.
            onLinkWhenUsingProxy(scope) {
                if (!scope.user || !scope.userProxy) {
                    throw new Error('Must have a user and userProxy object');
                }

                scope.downloadDocument = document => this.downloadDocument(document);
                scope.viewDocument = document => this.viewDocument(document);
            },
        };
    },
]);
