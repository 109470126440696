const states = [
    {
        value: 'Alabama',
        label: 'Alabama',
        abbreviation: 'AL',
    },
    {
        value: 'Alaska',
        label: 'Alaska',
        abbreviation: 'AK',
    },
    {
        value: 'American Samoa',
        label: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        value: 'Arizona',
        label: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        value: 'Arkansas',
        label: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        value: 'Armed Forces America (AA)',
        label: 'Armed Forces America (AA)',
        abbreviation: 'AA',
    },
    {
        value: 'Armed Forces Europe (AE)',
        label: 'Armed Forces Europe (AE)',
        abbreviation: 'AE',
    },
    {
        value: 'Armed Forces Pacific (AP)',
        label: 'Armed Forces Pacific (AP)',
        abbreviation: 'AP',
    },
    {
        value: 'California',
        label: 'California',
        abbreviation: 'CA',
    },
    {
        value: 'Colorado',
        label: 'Colorado',
        abbreviation: 'CO',
    },
    {
        value: 'Connecticut',
        label: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        value: 'Delaware',
        label: 'Delaware',
        abbreviation: 'DE',
    },
    {
        value: 'District of Columbia',
        label: 'District of Columbia',
        abbreviation: 'DC',
    },
    {
        value: 'Federated States of Micronesia',
        label: 'Federated States of Micronesia',
        abbreviation: 'FM',
    },
    {
        value: 'Florida',
        label: 'Florida',
        abbreviation: 'FL',
    },
    {
        value: 'Georgia',
        label: 'Georgia',
        abbreviation: 'GA',
    },
    {
        value: 'Guam',
        label: 'Guam',
        abbreviation: 'GU',
    },
    {
        value: 'Hawaii',
        label: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        value: 'Idaho',
        label: 'Idaho',
        abbreviation: 'ID',
    },
    {
        value: 'Illinois',
        label: 'Illinois',
        abbreviation: 'IL',
    },
    {
        value: 'Indiana',
        label: 'Indiana',
        abbreviation: 'IN',
    },
    {
        value: 'Iowa',
        label: 'Iowa',
        abbreviation: 'IA',
    },
    {
        value: 'Kansas',
        label: 'Kansas',
        abbreviation: 'KS',
    },
    {
        value: 'Kentucky',
        label: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        value: 'Louisiana',
        label: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        value: 'Maine',
        label: 'Maine',
        abbreviation: 'ME',
    },
    {
        value: 'Marshall Islands',
        label: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        value: 'Maryland',
        label: 'Maryland',
        abbreviation: 'MD',
    },
    {
        value: 'Massachusetts',
        label: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        value: 'Michigan',
        label: 'Michigan',
        abbreviation: 'MI',
    },
    {
        value: 'Minnesota',
        label: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        value: 'Mississippi',
        label: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        value: 'Missouri',
        label: 'Missouri',
        abbreviation: 'MO',
    },
    {
        value: 'Montana',
        label: 'Montana',
        abbreviation: 'MT',
    },
    {
        value: 'Nebraska',
        label: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        value: 'Nevada',
        label: 'Nevada',
        abbreviation: 'NV',
    },
    {
        value: 'New Hampshire',
        label: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        value: 'New Jersey',
        label: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        value: 'New Mexico',
        label: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        value: 'New York',
        label: 'New York',
        abbreviation: 'NY',
    },
    {
        value: 'North Carolina',
        label: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        value: 'North Dakota',
        label: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        value: 'Northern Mariana Islands',
        label: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        value: 'Ohio',
        label: 'Ohio',
        abbreviation: 'OH',
    },
    {
        value: 'Oklahoma',
        label: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        value: 'Oregon',
        label: 'Oregon',
        abbreviation: 'OR',
    },
    {
        value: 'Palau',
        label: 'Palau',
        abbreviation: 'PW',
    },
    {
        value: 'Pennsylvania',
        label: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        value: 'Puerto Rico',
        label: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        value: 'Rhode Island',
        label: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        value: 'South Carolina',
        label: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        value: 'South Dakota',
        label: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        value: 'Tennessee',
        label: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        value: 'Texas',
        label: 'Texas',
        abbreviation: 'TX',
    },
    {
        value: 'Utah',
        label: 'Utah',
        abbreviation: 'UT',
    },
    {
        value: 'Vermont',
        label: 'Vermont',
        abbreviation: 'VT',
    },
    {
        value: 'Virgin Islands',
        label: 'Virgin Islands',
        abbreviation: 'VI',
    },
    {
        value: 'Virginia',
        label: 'Virginia',
        abbreviation: 'VA',
    },
    {
        value: 'Washington',
        label: 'Washington',
        abbreviation: 'WA',
    },
    {
        value: 'West Virginia',
        label: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        value: 'Wisconsin',
        label: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        value: 'Wyoming',
        label: 'Wyoming',
        abbreviation: 'WY',
    },
].sort((a, b) => (a.label > b.label ? 1 : -1));

export default states;
