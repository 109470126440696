import { useMemo } from 'react';
import { useCohorts } from 'UserAdministrationTab/redux';
import { type PaymentSituation } from 'PaymentSituation';
import './ProgramPaymentCard.scss';

type ProgramPaymentCardProps = {
    title: string;
    cohortId: string;
    paymentSituation: PaymentSituation;
    showDiffFromPaymentSituation?: PaymentSituation;
    cohortLabel?: string;
};

const renderMoney = (val: string | number) => (typeof val === 'number' ? `$${val}` : val);

const getNewVal = (newVal: string | number, oldVal: string | number | undefined) =>
    newVal !== oldVal ? newVal : 'NO CHANGE';

export const ProgramPaymentCard = ({
    title,
    cohortId,
    paymentSituation: { paymentInformation, tuitionPlan },
    showDiffFromPaymentSituation,
    cohortLabel = 'Cohort',
}: ProgramPaymentCardProps) => {
    const { cohortsById } = useCohorts();
    const cohort = useMemo(() => cohortsById[cohortId], [cohortsById, cohortId]);
    const programType = useMemo(() => cohort?.programType, [cohort]);
    const cohortName = useMemo(() => cohort?.name, [cohort]);
    const oldPaymentInformation = showDiffFromPaymentSituation?.paymentInformation;
    const oldTuitionPlan = showDiffFromPaymentSituation?.tuitionPlan;

    return (
        <div className="program-payment-card">
            <div className="title">{title}</div>
            <ul>
                {programType && (
                    <li>
                        <span className="label">Program:</span>
                        <span className="value">{programType}</span>
                    </li>
                )}
                {cohortName && (
                    <li>
                        <span className="label">{cohortLabel}:</span>
                        <span className="value">{cohortName}</span>
                    </li>
                )}
                <li>
                    <span className="label">Payment Plan:</span>
                    <span className="value">{getNewVal(tuitionPlan.frequency, oldTuitionPlan?.frequency)}</span>
                </li>
                <li>
                    <span className="label">Base Tuition:</span>
                    <span className="value">
                        {renderMoney(getNewVal(paymentInformation.baseTuition, oldPaymentInformation?.baseTuition))}
                    </span>
                </li>
                <li>
                    <span className="label">Scholarships:</span>
                    <span className="value">
                        {renderMoney(
                            getNewVal(
                                paymentInformation.netScholarshipAmount,
                                oldPaymentInformation?.netScholarshipAmount,
                            ),
                        )}
                    </span>
                </li>
                <li>
                    <span className="label">Discount:</span>
                    <span className="value">
                        {renderMoney(
                            getNewVal(paymentInformation.netDiscountAmount, oldPaymentInformation?.netDiscountAmount),
                        )}
                    </span>
                </li>
                <li>
                    <span className="label">Expected Tuition:</span>
                    <span className="value">
                        {renderMoney(
                            getNewVal(paymentInformation.netRequiredPayment, oldPaymentInformation?.netRequiredPayment),
                        )}
                    </span>
                </li>
            </ul>
        </div>
    );
};

export default ProgramPaymentCard;
