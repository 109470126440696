import { pick } from 'lodash/fp';
import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { type BaseUser, type UserInAdmin, USER_IN_ADMIN_FIELDS } from 'Users';
import { type Scholarship } from 'Scholarship';
import { type AoiRecord } from 'ProgramAoi/ProgramAoi.types';
import { type AnyObject } from '@Types';
import { createApi } from 'ReduxHelpers';
import { type ActionsByAoiRecord, type ListAvailableActionsResponse } from '../UserAdministrationTab.types';
import type AbstractUserManagementAction from '../UserManagementActions/AbstractUserManagementAction';

interface ExecuteUserManagementActionResponse {
    contents: { user: UserInAdmin };
}

interface UserAoiRecordsResponse {
    contents: {
        users: UserInAdmin[];
    };
}

interface ScholarshipResponse {
    contents: {
        scholarships: Scholarship[];
    };
}

export const editAoiRecordsApi = createApi('editAoiRecordsApi', {
    ...getBackRoyalApiBaseConfig('editAoiRecordsApi'),
    endpoints: builder => ({
        getAvailableUserManagementActions: builder.query<ActionsByAoiRecord, AnyObject>({
            query: ({ userId, aoiRecordId }) => ({
                url: 'admin_actions/list_available_actions.json',
                params: { userId, aoiRecordId },
            }),
            transformResponse: (response: ListAvailableActionsResponse) => response.contents.actions,
        }),

        executeCreateUserAction: builder.mutation<BaseUser, AnyObject>({
            query: params => ({ method: 'post', url: 'admin_actions/user/create_user', body: params }),
        }),

        getUserInAdmin: builder.query<UserInAdmin, string>({
            query: userId => ({
                url: `users/${userId}`,
                params: { 'fields[]': USER_IN_ADMIN_FIELDS },
            }),
            transformResponse: (response: UserAoiRecordsResponse) => response.contents.users?.[0],
        }),

        getOfferableScholarships: builder.query<Scholarship[], void>({
            query: () => ({
                url: 'scholarships/all_offerable_scholarships.json',
                params: { camelizeJsonResponse: true },
            }),
            transformResponse: (response: ScholarshipResponse) => response.contents.scholarships,
        }),

        executeAction: builder.mutation<
            BaseUser,
            { action: AbstractUserManagementAction; record?: AoiRecord; values: AnyObject; userId: string }
        >({
            query: ({ values, record, action, userId }) => ({
                method: 'post',
                url: action.postEndpoint,
                body: { ...values, recordId: record?.id, userId },
            }),
            transformResponse: (response: ExecuteUserManagementActionResponse) =>
                pick(USER_IN_ADMIN_FIELDS, response.contents.user),
        }),
    }),
});

export default editAoiRecordsApi;
