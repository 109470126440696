import { useEffect, useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Select, TextField } from 'FrontRoyalMaterialUiForm';
import { titleize } from 'String';
import { type DeferralReason, DeferralReasonCategory, OTHER_DEFERRAL_REASON_ID } from 'DeferralReason';
import './EditDeferralReason.scss';

interface EditDeferralReasonProps {
    deferralReason?: DeferralReason;
    deferralReasons: DeferralReason[];
}

type EditDeferralReasonFields = {
    deferralReasonCategory: DeferralReasonCategory;
    deferralReasonId: string;
    otherDeferralReasonTitle: string | null;
};

const EditDeferralReason = ({ deferralReason, deferralReasons }: EditDeferralReasonProps) => {
    const { formState, resetField, setFieldValue, watch } = useFormContext<EditDeferralReasonFields>();
    const [deferralReasonCategory, deferralReasonId, otherDeferralReasonTitle] = watch([
        'deferralReasonCategory',
        'deferralReasonId',
        'otherDeferralReasonTitle',
    ]);
    const deferralCategories = useMemo(
        () => [...new Set(deferralReasons?.map(({ category }) => category))],
        [deferralReasons],
    );

    const deferralReasonOptions = useMemo(() => {
        const relevantCategory: DeferralReasonCategory = deferralReasonCategory || deferralReason?.category;

        // The unknown deferral reason is for server-side backfilling purposes only.
        // We don't allow admins to select it as a deferral reason in the client.
        if (deferralReasons && relevantCategory && relevantCategory !== DeferralReasonCategory.unknown) {
            const options = deferralReasons.filter(({ category }) => category === relevantCategory);

            // The "Other" option is a client-side only option that's available in all categories
            // and requires the admin to enter their own reason. See `generate_deferral_link.rb`
            // to see how the "Other" option is handled.
            options.push({
                id: OTHER_DEFERRAL_REASON_ID,
                category: deferralReasonCategory,
                title: 'Other (add new reason)',
            });

            // Sort in alphabetical order by title to make finding specific options easier
            return options.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
        }
        return [];
    }, [deferralReasonCategory, deferralReasons, deferralReason]);

    useEffect(() => {
        if (!!otherDeferralReasonTitle && deferralReasonId !== OTHER_DEFERRAL_REASON_ID) {
            resetField('otherDeferralReasonTitle');
        }
    }, [resetField, otherDeferralReasonTitle, deferralReasonId]);

    useEffect(() => {
        // The unknown deferral reason is for server-side backfilling purposes only.
        // We don't allow admins to select it as a deferral reason in the client.
        if (deferralReason && deferralReason.category !== DeferralReasonCategory.unknown) {
            setFieldValue('deferralReasonCategory', deferralReason.category);
            setFieldValue('deferralReasonId', deferralReason.id);
        }
    }, [setFieldValue, deferralReason]);

    return (
        <div className="edit-deferral-reason-form">
            <Select
                name="deferralReasonCategory"
                label="Deferral Reason Category"
                fullWidth
                disabled={formState.isSubmitting}
                options={deferralCategories}
                optionLabel={option => titleize(option)}
                optionValue={option => option}
            />
            <Select
                name="deferralReasonId"
                label="Deferral Reason"
                fullWidth
                disabled={formState.isSubmitting || deferralReasonOptions.length <= 1}
                options={deferralReasonOptions}
                optionLabel={option => option.title}
                optionValue={option => option.id}
            />
            {deferralReasonId === OTHER_DEFERRAL_REASON_ID && (
                <TextField
                    name="otherDeferralReasonTitle"
                    label="Provide a deferral reason"
                    disabled={formState.isSubmitting}
                    fullWidth
                />
            )}
        </div>
    );
};

export default EditDeferralReason;
