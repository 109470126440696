import angularModule from 'Users/angularModule/scripts/users_module';
// iguana service wrapper class
// currently only opening up /api/users.json which returns info about the current user
angularModule.factory('S3TranscriptAsset', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('s3_transcript_assets');
            this.alias('S3TranscriptAsset');
            this.setIdProperty('id');
            this.embeddedIn('user');
            this.embeddedIn('educationExperience');

            this.extend({
                UPLOAD_URL: `${window.ENDPOINT_ROOT}/api/s3_transcript_assets.json`,
            });

            Object.defineProperty(this.prototype, 'downloadUrl', {
                get() {
                    return `${window.ENDPOINT_ROOT}/api/s3_transcript_assets/${this.id}/file`;
                },
            });

            Object.defineProperty(this.prototype, 'fileUpdatedAt', {
                get() {
                    if (!this.$$fileUpdatedAt) {
                        this.$$fileUpdatedAt = new Date(this.file_updated_at * 1000);
                    }
                    return this.$$fileUpdatedAt;
                },
                set(val) {
                    this.file_updated_at = val.getTime() / 1000;
                    this.$$fileUpdatedAt = undefined;
                },
                configurable: true,
            });

            return {};
        });
    },
]);
