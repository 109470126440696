import angularModule from 'UnloadedChangeDetector/angularModule/scripts/unloaded_change_detector_module';

angularModule.factory('UnloadedChangeDetectorInterceptor', [
    '$injector',

    $injector => {
        const UnloadedChangeDetector = $injector.get('UnloadedChangeDetector');

        return {
            response(response) {
                _.chain(UnloadedChangeDetector.instances).values().invokeMap('onServerResponse', response).value();

                return response;
            },
        };
    },
]);

angularModule.config([
    '$injector',
    $injector => {
        // The response interceptor needs to run after all of the push messages have been  processed.
        // (-999 is a little more that -1000, which is what the front-royal-api-error-handler is set to)
        const PrioritizedInterceptorsProvider = $injector.get('PrioritizedInterceptorsProvider');
        PrioritizedInterceptorsProvider.addInterceptor(-999, 'UnloadedChangeDetectorInterceptor');
    },
]);
