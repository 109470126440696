/* eslint-disable lodash-fp/prefer-identity */
import { type ReactElement } from 'react';
import { snakeCase, titleize, humanize } from 'String';
import { type AnyObject } from '@Types';
import * as Yup from 'yup';
import { type BaseUser } from 'Users';
import { type AoiRecord } from 'ProgramAoi';
import {
    type UserManagementActionType,
    type UserManagementActionAttrs,
    type FormFieldProps,
    type UserManagementActionNamespace,
} from './UserManagementAction.types';

/*
    Individual UserManagementAction classes, like ProgramApplication/RejectApplication, extend AbstractUserManagementAction.

    UserManagementAction classes set up configuration and include components that allow the action
    to be displayed in the UserAdministrationTab.  All of the logic
    for executing actions is on the server.  The code in the client allows a user of the UserAdministrationTab

        * see which actions are available to execute
        * select an action to execute
        * provide any parameters that are necessary for executing the action

    There are some UserManagementActions on the server that do not appear in the admin interface. For now, we have
    not given those actions client-side implementations. Eventually, as we implement the student interfaces that allow
    for execution of those actions, some of this code and documentation may need to be split to account for the two
    different usages of UserManagementAction. (For an example, see the UseDeferralLink action)

    To create a new action:

    1. Create server-side support for your action. See instructions at `lib/user_management_action.rb`
    2. Create a file for it in `UserAdministrationTab/UserManagementActions` under the appropriate
        directory (ProgramApplication, AdmissionOffer, ProgramInclusion, UserActions)
    3. set the static `actionType` property
    4. set the `description` property to a ReactElement that describes the action
    5. The server may need to pass information down to the client that is related to a particular
        action in the user's current state.  For example, in a ChangeCohort action, the user sends
        down the list of available cohorts.  This information will be passed down from the server
        in the json of the action object.

        If the server is sending down any extra information about this action, then define a
        type that extends UserManagementActionAttrs and includes the properties that will come down
        from the server.  The `attrs` argument passed to the constructor of your action will be of
        this type.

    6. If there are any params that need to be selected by the user and passed up the the
        server when executing the action, then do the following:

        * Define an ExecuteActionParams type that defines the parameters
            the action will send up to the server on execute.

        * set a `FormFields` in your action to a ReactElement that includes any UI elements that
            the user needs to fill out in order to provide the params that will be passed to the server
            The type for the props of FormFields is the generic FormFieldProps. It takes up to two type arguments
            Record and Action. When passed, this will assert the type of the AoiRecord and the action (which are passed as props)
            so they are correctly typed inside of the component.

            You should define a FormFields type as well, this type will be the shape of the values stored inside of
            the form used in the FormFields component. You can then pass this type as a generic to the useFormContext hook
            from react-hook-form like `useFormContext<FormFields>()` See UserManagementAction.types.ts

        * In some cases, the values sent to the server need to be different than the FormFields type. In that case,
            you will need to define a separate ExecuteActionParams type which will be the shape of the formatted
            values that will be sent to the server upon executing the action.

            To do this, we also need to create a `formatFormValues` function. This function takes 1 argument, which is an object
            of the type FormFields and returns an object of the type ExecuteActionParams.
            You should do whatever manipulation of the form data you need in this function and ensure it returns the data the server
            is expecting (i.e. type ExecuteActionParams)

            For examples of ExecuteActionParams, search for actions that define formatFormValues.

        * To allow validation of your form, you will need to set a formValidationSchema on your action.
            The validations are defined using [Yup](https://github.com/jquense/yup).
            The schema should match your FormFields type or, if you have a formatFormFields function
            it should then match your ExecuteActionParams type
            * For a simple example, see ProgramInclusion/ChangeCohort.tsx.
            * For some more complex examples, see AdmissionOffer/RegisterStudent.tsx

        * If you need to set any initial values for the fields in the form, use the reset function via useFormContext
            This should only be done inside of the parent component, i.e the FormFields component defined in the action.
            * For an example, see ProgramInclusion/ChangeSettings.tsx

    7. Add the ActionType in UserManagementAction.types.ts
    8. Add the action to the list in instantiateAction.ts
*/

const emptyValidationSchema = Yup.object();

export default abstract class AbstractUserManagementAction {
    static description: ReactElement = (<div />);
    static namespace: UserManagementActionNamespace;
    static FormFields?: (props: FormFieldProps) => ReactElement | null;
    static formValidationSchema = emptyValidationSchema;
    static actionType: UserManagementActionType;

    validationErrors: string[];

    constructor(attrs: UserManagementActionAttrs) {
        this.validationErrors = attrs.validationErrors;
    }

    // by default we want to just return the form values
    formatFormValues(values: AnyObject) {
        return values;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initialFormValues(record?: AoiRecord, user?: BaseUser) {
        return {};
    }

    get description(): ReactElement {
        return (this.constructor as typeof AbstractUserManagementAction).description;
    }

    get FormFields(): ((props: FormFieldProps) => ReactElement | null) | undefined {
        return (this.constructor as typeof AbstractUserManagementAction).FormFields;
    }

    get postEndpoint() {
        const namespace = snakeCase(this.namespace, '_');
        const actionType = snakeCase(this.actionType, '_');
        return `${window.ENDPOINT_ROOT}/api/admin_actions/${namespace}/${actionType}.json`;
    }

    get title() {
        return titleize(humanize(snakeCase(this.actionType, '_')));
    }

    get actionType() {
        return (this.constructor as typeof AbstractUserManagementAction).actionType;
    }

    get namespace() {
        return (this.constructor as typeof AbstractUserManagementAction).namespace;
    }

    get formValidationSchema() {
        return (this.constructor as typeof AbstractUserManagementAction).formValidationSchema;
    }

    get hasValidations() {
        return this.formValidationSchema !== emptyValidationSchema;
    }

    get disabled() {
        return this.validationErrors.length > 0;
    }

    get disabledReason() {
        if (!this.disabled) {
            return null;
        }
        return (
            <>
                {this.validationErrors.map(err => (
                    <p key={err}>{err}</p>
                ))}
            </>
        );
    }
}
