import { type EventLogger } from 'EventLogger';

// We ran into a bug where the window was reloading and we did not know why. This function logs an event if
// we ever reload the window so that we can track it. https://trello.com/c/cDvkB022
export default function reloadWindow({
    label,
    path,
    EventLogger,
}: {
    label?: string | undefined;
    path?: string;
    EventLogger: EventLogger;
}) {
    try {
        EventLogger.log('window:reload', { label, path });
    } catch (err) {
        // no-op
    }
    if (path) {
        window.location.href = path;
    } else {
        window.location.reload();
    }
}
