import { ThemeProvider } from '@mui/material';
import CollapsibleContent from 'CollapsibleContent';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { theme } from 'FrontRoyalMaterialUiForm';
import AoiRecordsList from './AoiRecordsList/AoiRecordsList';
import UserAdminItemCard from './UserAdminItemCard/UserAdminItemCard';
import { useGetUserInAdmin } from './redux/hooks';
import './UserAdministrationTab.styles.scss';

interface UserAdministrationTabProps {
    userId: string;
    onUserUpdate: () => void;
}

function UserAdministrationTab({ userId, onUserUpdate }: UserAdministrationTabProps) {
    const { user, userLoading } = useGetUserInAdmin(userId);

    return (
        <ThemeProvider theme={theme}>
            <div className="admin-edit-aoi-records">
                {userLoading ? (
                    <FrontRoyalSpinner />
                ) : user ? (
                    <>
                        <h3>User Details</h3>
                        <CollapsibleContent title={`${user.name} (${user.email})`}>
                            <UserAdminItemCard userId={userId} showUserInfo onUserUpdate={onUserUpdate} />
                        </CollapsibleContent>

                        <h3>Inclusions</h3>
                        <AoiRecordsList records={user.programInclusions} userId={userId} onUserUpdate={onUserUpdate} />

                        <h3>Offers</h3>
                        <AoiRecordsList records={user.admissionOffers} userId={userId} onUserUpdate={onUserUpdate} />

                        <h3>Applications</h3>
                        <AoiRecordsList
                            records={user.programApplications}
                            userId={userId}
                            onUserUpdate={onUserUpdate}
                        />
                    </>
                ) : null}
            </div>
        </ThemeProvider>
    );
}

export default UserAdministrationTab;
