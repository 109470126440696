import { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import ChangeCohortSelect from 'UserAdministrationTab/HelperComponents/ChangeCohortSelect';
import {
    UserManagementActionNamespace,
    ApplicationActionType,
    type UserManagementActionAttrs,
    type FormFieldProps,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import EditSectionOffers from 'UserAdministrationTab/HelperComponents/EditSectionOffers';
import { type SectionOfferFormValue } from 'UserAdministrationTab/HelperComponents/EditSectionOffers.types';

type FormFields = {
    cohortSectionOffers: SectionOfferFormValue[];
    programType: string | null;
    cohortId: string;
};

type ExecuteActionParams = Omit<FormFields, 'programType'>;

type OfferAdmissionInNewProgramAttrs = UserManagementActionAttrs & {
    availableTargetCohortIds: string[];
    availablePrograms: string[];
};

class OfferAdmissionInNewProgram extends AbstractUserManagementAction {
    static namespace = UserManagementActionNamespace.ProgramApplication;
    static actionType = ApplicationActionType.OfferAdmissionInNewProgram;

    static description = (
        <p>Offer user admission for a different program than their initial program application specified.</p>
    );

    availableTargetCohortIds: string[];

    constructor(attrs: OfferAdmissionInNewProgramAttrs) {
        super(attrs);
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
    }

    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
        cohortSectionOffers: Yup.array().of(
            Yup.object().shape({
                cohortSectionId: Yup.string().required(),
                offeredScholarshipIds: Yup.array().of(Yup.string().required()).required(),
            }),
        ),
    });

    formatFormValues({ cohortId, cohortSectionOffers }: FormFields): ExecuteActionParams {
        return {
            cohortId,
            cohortSectionOffers: cohortSectionOffers?.map(offer => ({
                cohortSectionId: offer.cohortSectionId,
                offeredScholarshipIds: offer.offeredScholarshipIds,
            })),
        };
    }

    static FormFields = ({ action }: FormFieldProps<never, OfferAdmissionInNewProgram>) => {
        const { watch, setFieldValue } = useFormContext<FormFields>();
        const [cohortId, programType] = watch(['cohortId', 'programType']);

        useEffect(() => {
            setFieldValue('cohortSectionOffers', []);
        }, [programType, setFieldValue]);

        return (
            <>
                <ChangeCohortSelect availableTargetCohortIds={action.availableTargetCohortIds} />
                {cohortId && <EditSectionOffers cohortId={cohortId} />}
            </>
        );
    };
}

export default OfferAdmissionInNewProgram;
