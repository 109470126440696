import { useEffect, useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { Select, Checkbox, TelephoneInput } from 'FrontRoyalMaterialUiForm';
import { editorLocales } from 'Locale';
import {
    getUserProgramState as getPrefUserProgramState,
    getAoiRecordFromUserProgramState as _aoiRecordFromUserProgramState,
    type BaseUser,
} from 'Users';
import { type UsersAdminFilterData } from 'Admin/Admin.types';
import { type UserProgramState } from 'ProgramAoi';
import TimezoneAutocomplete from '../../HelperComponents/TimezoneAutocomplete';
import { useGetUserInAdmin } from '../../redux';
import { UserActionType, UserManagementActionNamespace, type FormFieldProps } from '../UserManagementAction.types';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import { type EditUserDetailsAttrs, type FormFields } from './EditUserDetails.types';
import './EditUserDetails.scss';

type ExecuteActionParams = Omit<FormFields, 'studentDashboardVersionOverride'> & {
    studentDashboardVersionOverride: BaseUser['studentDashboardVersionOverride'];
};

class EditUserDetails extends AbstractUserManagementAction {
    static namespace = UserManagementActionNamespace.User;
    static actionType = UserActionType.EditUserDetails;
    static description = (<p>Change administrative details for the user.</p>);

    usersAdminFilterData: UsersAdminFilterData;

    constructor({ validationErrors, ...rest }: EditUserDetailsAttrs) {
        super({ validationErrors });
        this.usersAdminFilterData = { ...rest };
    }

    static formValidationSchema = Yup.object({
        globalRoleId: Yup.string().required(),
        institutionIds: Yup.array().of(Yup.string()).required(),
        activeInstitutionId: Yup.string().test({
            name: 'activeInstitutionIdIsOneOfInstitutionIds',
            message: 'Active institution must be one of the selected Institutions',
            test(value) {
                return value && this.parent.institutionIds.includes(value);
            },
        }),
        prefLocale: Yup.string().required(),
        accessGroupIds: Yup.array().of(Yup.string()).optional(),
        phone: Yup.string().nullable().optional(),
        activeProgramType: Yup.string().optional(),
        fallbackProgramType: Yup.string().required(),
        timezone: Yup.string().nullable().optional(),
        deactivated: Yup.boolean().required(),
        mbaContentLockable: Yup.boolean().required(),
        ineligibleForExecEdBundle: Yup.boolean().required(),
        studentDashboardVersionOverride: Yup.number().nullable().optional(),
    });

    formatFormValues(values: FormFields): ExecuteActionParams {
        let studentDashboardVersionOverride;
        if (values.studentDashboardVersionOverride === '1') {
            studentDashboardVersionOverride = 1 as const;
        } else if (values.studentDashboardVersionOverride === '2') {
            studentDashboardVersionOverride = 2 as const;
        } else {
            studentDashboardVersionOverride = null;
        }
        return {
            ...values,
            studentDashboardVersionOverride,
        };
    }

    static FormFields = ({ userId, action: { usersAdminFilterData } }: FormFieldProps<never, EditUserDetails>) => {
        const { user } = useGetUserInAdmin(userId);
        const prefUserProgramState = getPrefUserProgramState(user!) as UserProgramState;
        const aoiRecordFromUserProgramState = _aoiRecordFromUserProgramState(user!, prefUserProgramState);

        const {
            formState: { isSubmitting },
            reset,
            watch,
        } = useFormContext<FormFields>();

        useEffect(() => {
            if (user) {
                reset({
                    activeProgramType: prefUserProgramState?.programType,
                    fallbackProgramType: user.fallbackProgramType,
                    globalRoleId: user.roles.find(
                        ({ resourceId, name }) => resourceId === null && name !== 'cannot_create',
                    )?.id,
                    institutionIds: user.institutions.map(({ id }) => id),
                    activeInstitutionId: user.activeInstitution?.id,
                    prefLocale: user.prefLocale,
                    accessGroupIds: user.groups.map(({ id }) => id),
                    phone: user.phone,
                    deactivated: user.deactivated,
                    mbaContentLockable: user.mbaContentLockable,
                    timezone: user.timezone,
                    ineligibleForExecEdBundle: user.ineligibleForExecEdBundle,
                    studentDashboardVersionOverride: user.studentDashboardVersionOverride
                        ? (user.studentDashboardVersionOverride.toString() as '1' | '2')
                        : 'default',
                });
            }
        }, [reset, user, prefUserProgramState]);

        const studentDashboardVersionOverrideOptions = useMemo(
            () => [
                { value: 'default', label: 'Default' },
                { value: '1', label: 'V1' },
                { value: '2', label: 'V2' },
            ],
            [],
        );

        const institutionIds = watch('institutionIds');
        const activeInstitutionOptions = usersAdminFilterData.availableInstitutions.filter(institution =>
            institutionIds?.includes(institution.id),
        );

        return (
            <div className="edit-user-details-form">
                <Select
                    name="globalRoleId"
                    label="Role"
                    aria-label="Role"
                    fullWidth
                    disabled={isSubmitting}
                    options={usersAdminFilterData.availableRoles}
                    optionLabel={({ name }) => name}
                    optionValue={({ id }) => id}
                />
                <Select
                    name="institutionIds"
                    label="Institutions"
                    aria-label="Institutions"
                    fullWidth
                    multiple
                    disabled={isSubmitting}
                    options={usersAdminFilterData.availableInstitutions}
                    optionLabel={({ name }) => name}
                    optionValue={({ id }) => id}
                />
                <Select
                    name="activeInstitutionId"
                    label="Active Institution"
                    aria-label="Active Institution"
                    fullWidth
                    disabled={!activeInstitutionOptions?.length || isSubmitting}
                    options={activeInstitutionOptions}
                    optionLabel={({ name }) => name}
                    optionValue={({ id }) => id}
                />
                <Select
                    name="prefLocale"
                    label="Locale"
                    aria-label="Locale"
                    fullWidth
                    disabled={isSubmitting}
                    options={editorLocales}
                    optionLabel={({ title }) => title}
                    optionValue={({ value }) => value}
                />
                <Select
                    name="accessGroupIds"
                    label="Available Access Groups"
                    aria-label="Available Access Groups"
                    fullWidth
                    multiple
                    disabled={isSubmitting}
                    options={usersAdminFilterData.availableAccessGroups}
                    optionLabel={({ name }) => name}
                    optionValue={({ id }) => id}
                />
                <TelephoneInput
                    name="phone"
                    label="Phone Number"
                    aria-label="Phone Number"
                    disabled={isSubmitting}
                    telephoneNumber={user?.phone}
                    defaultCountryCode={user?.country}
                />
                <Select
                    name="activeProgramType"
                    label="Active Program Type"
                    aria-label="Active Program Type"
                    fullWidth
                    disabled={isSubmitting || prefUserProgramState === null || aoiRecordFromUserProgramState !== null}
                    options={usersAdminFilterData.availableActiveProgramTypes}
                />
                <Select
                    name="fallbackProgramType"
                    label="Fallback Program Type"
                    aria-label="Fallback Program Type"
                    fullWidth
                    disabled={isSubmitting}
                    options={usersAdminFilterData.availableFallbackProgramTypes}
                />
                <TimezoneAutocomplete disabled={isSubmitting} fullWidth />

                <Select
                    name="studentDashboardVersionOverride"
                    label="Student Dashboard Version"
                    aria-label="Student Dashboard Version"
                    disabled={isSubmitting}
                    options={studentDashboardVersionOverrideOptions}
                />
                <Checkbox disabled={isSubmitting} name="deactivated" label="Deactivate User" />
                <Checkbox disabled={isSubmitting} name="mbaContentLockable" label="Lock MBA Content" />
                <Checkbox
                    disabled={isSubmitting}
                    name="ineligibleForExecEdBundle"
                    label="Ineligible for Exec Ed Bundle"
                />
            </div>
        );
    };
}

export default EditUserDetails;
