import * as Yup from 'yup';
import AbstractUserManagementAction from 'UserAdministrationTab/UserManagementActions/AbstractUserManagementAction';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from 'UserAdministrationTab/UserManagementActions/UserManagementAction.types';
import EditDeferralReason from 'UserAdministrationTab/HelperComponents/EditDeferralReason';
import { type DeferralReason, OTHER_DEFERRAL_REASON_ID } from 'DeferralReason';

interface GenerateDeferralLinkAttrs extends UserManagementActionAttrs {
    deferralReasons: DeferralReason[];
}

class GenerateDeferralLink extends AbstractUserManagementAction {
    static actionType = InclusionActionType.GenerateDeferralLink;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    deferralReasons: DeferralReason[];

    constructor(attrs: GenerateDeferralLinkAttrs) {
        super(attrs);
        this.deferralReasons = attrs.deferralReasons;
    }

    get formValidationSchema() {
        return Yup.object().shape({
            deferralReasonCategory: Yup.string().required(),
            deferralReasonId: Yup.string().required(),
            otherDeferralReasonTitle: Yup.string().when('deferralReasonId', {
                is: OTHER_DEFERRAL_REASON_ID,
                then: Yup.string().required(),
                otherwise: Yup.string().nullable(),
            }),
        });
    }

    static description = (<p>Generate a link that the student can use to defer themself into a later cohort.</p>);

    static FormFields = ({ action }: FormFieldProps<never, GenerateDeferralLink>): JSX.Element => (
        <EditDeferralReason deferralReasons={action.deferralReasons} />
    );
}

export default GenerateDeferralLink;
